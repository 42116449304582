import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonValidator, HibaService, UtilityService } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService, PortalValidator } from '@granit/portal-library';

@Component({
  selector: 'profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private felhasznaloService: FelhasznaloService,
    public hibaService: HibaService,
    public dialog: MatDialog,    
    private utilityService: UtilityService) { }

  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;
  ugyfelAzonositok: any[] = [];
  menuLista: any[] = [];
  kivalasztottMenu: any;
  toltoKepernyo: boolean = true;
  jelszoJelenlegiElrejtese: boolean = true;
  jelszoUjElrejtese: boolean = true;
  jelszoUjIsmetElrejtese: boolean = true;
  emailModositasFormGroup: FormGroup;
  szerzodesAdatokFormGroup: FormGroup;
  jelszoModositasFormGroup: FormGroup;
  valtozasBejelentesTipusok: any[] = [
    // Email
    {
      f_id: 'email_modositas',
      f_nev: 'E-mail cím módosítása'
    }
  ]
  valtozasBejelentesTipus: any;

  ngOnInit(): void {
    this.ugyfelAzonositokLekerese();
  }

  // Felhasználóhoz tartozó ügyfél azonosítók lekérdezése
  ugyfelAzonositokLekerese() {
    this.felhasznaloService.felhasznaloUgyfelAzonositok().subscribe(
      data => {
        this.ugyfelAzonositok = data;
        this.menuListaFeltoltese();
        this.emailModositasFormGroupLetrehozasa();
        this.szerzodesAdatokFormGroupLetrehozasa();
        this.jelszoModositasFormGroupLetrehozasa();
        this.toltoKepernyo = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a lekérdezés közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  // E-mail cím módosítás form group létrehozása
  emailModositasFormGroupLetrehozasa() {
    this.emailModositasFormGroup = this.formBuilder.group({
      f_email_uj: new FormControl(null, [Validators.required, Validators.email])
    });
  }

  // Szerződés adatok fprm group létrehozása
  szerzodesAdatokFormGroupLetrehozasa() {
    this.szerzodesAdatokFormGroup = this.formBuilder.group({
      f_azonosito_tipus: new FormControl('Ü'),
      f_azonosito: new FormControl(null, [Validators.required]),
      f_szerzodo_neme: new FormControl(null, [Validators.required]),
      f_szerzodo_szuletesi_datuma: new FormControl(null),
      f_szerzodo_adoszama: new FormControl(null)
    });
  }

  // Jelszó módosítás form group létrehozása
  jelszoModositasFormGroupLetrehozasa() {
    this.jelszoModositasFormGroup = this.formBuilder.group({
      f_jelszo_jelenlegi: new FormControl(null, [Validators.required]),
      f_jelszo_uj: new FormControl(null, { updateOn: 'blur', validators: [Validators.required, PortalValidator.jelszoEllenorzes()] }),
      f_jelszo_uj_ismet: new FormControl(null, [Validators.required])
    },
      {
        validators: [CommonValidator.osszehasonlitas('f_jelszo_uj', 'f_jelszo_uj_ismet')]
      }
    );
  }

  // Menü lista feltöltése
  menuListaFeltoltese() {

    this.menuLista.push({ f_id: 'profil_adatok', f_cim: 'Profil adatok' });
    this.menuLista.push({ f_id: 'ugyfelazonositok_kezelese', f_cim: 'Ügyfélazonosítók kezelése' });
    this.menuLista.push({ f_id: 'jelszo_modositas', f_cim: 'Jelszó módosítás' });

    // A kiválasztott menüpont mindig az első
    this.kivalasztottMenu = this.menuLista[0];
  }

  // Menüpont gomb esemény
  onMenuClick(menu: any) {
    this.kivalasztottMenu = menu;
    this.valtozasBejelentesTipus = null;
    this.emailModositasFormGroupLetrehozasa();
    this.szerzodesAdatokFormGroupLetrehozasa();
    this.jelszoModositasFormGroupLetrehozasa();
  }

  // Változás bejelentés gomb esemény
  onValtozasBejelentesClick(id: string) {
    this.valtozasBejelentesTipus = this.valtozasBejelentesTipusok.filter(cs => cs.f_id == id)[0];

  }

  // Változás bejelentésnél mégse gomb esemény
  onValtozasBejelentesMegseClick() {
    this.valtozasBejelentesTipus = null;
    this.emailModositasFormGroupLetrehozasa();
    this.szerzodesAdatokFormGroupLetrehozasa();
    this.jelszoModositasFormGroupLetrehozasa();
  }

  // Email módosítás gomb esemény
  onEmailModositasClick() {
    let model = {
      formGroupAdatok: this.emailModositasFormGroup.value
    }
    this.felhasznaloService.emailModositasLinkKuldes(model).subscribe(
      () => {
        this.utilityService.dialogMegnyitasa({
          info: true,
          title: 'E-mail cím módosítása',
          message: 'Az e-mail cím módosítását az Ön által megadott új címre küldött üzenetünkben tudja véglegesíteni.',
          confirmButtonText: 'Rendben'
        });
        this.emailModositasFormGroupLetrehozasa();
        this.valtozasBejelentesTipus = null;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba az e-mail cím módosítása közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  // Ügyfélazonosító hozzáadása gomb esemény
  onUgyfelazonositoHozzaadasClick() {
    let model = {
      formGroupAdatok: this.szerzodesAdatokFormGroup.value
    }
    this.felhasznaloService.ugyfelazonositoHozzaadasaIgenyKuldes(model).subscribe(
      () => {
        this.utilityService.dialogMegnyitasa({
          info: true,
          title: 'Új ügyfélazonosító hozzáadása',
          message: 'Igényét ügyfélszolgálatunk fogadta.',
          confirmButtonText: 'Rendben'
        });
        this.szerzodesAdatokFormGroupLetrehozasa();
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba az ügyfélazonosító hozzáadása igény küldése közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  // Ügyfélazonosító eltávolítása gomb esemény
  onUgyfelazonositoEltavolitasClick(item: any) {

    // Megerősítő dialog ablak
    const dialogRef = this.utilityService.dialogMegnyitasa({
      confirm: true,
      title: 'Ügyfélazonosító törlése',
      message: 'Biztos benne, hogy törölni szeretné az ügyfélazonosítóját? Törlés után az ügyfélazonosítóhoz tartozó szerződések már nem fognak megjelenni.',
      cancelButtonText: 'Mégsem',
      confirmButtonText: 'Igen, törölni szeretném'
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      // Ha igen, mehet az ügyfélazonosító törlés
      if (result) {
        let model = {
          ugyfelazonosito: item.f_ugyfelazonosito
        };
        this.felhasznaloService.felhasznaloUgyfelazonositoTorles(model).subscribe(
          data => {
            // Ha nincs hiba
            if (data == 0) {
              this.ugyfelAzonositok.splice(this.ugyfelAzonositok.indexOf(item), 1);
              this.utilityService.dialogMegnyitasa({
                success: true,
                title: 'Sikeres törlés',
                message: 'Az ügyfélazonosító törlése sikeresen megtörtént.',
                confirmButtonText: 'Rendben'
              });
            }
          }
        );
      }
    });
  }

  // Jelszó módosítás gomb esemény
  onJelszoModositasClick() {
    let model = {
      alkalmazasEnum: 0,
      aktualisFelhasznalo: this.aktualisFelhasznalo,
      formGroupAdatok: this.jelszoModositasFormGroup.value
    }
    this.felhasznaloService.jelszoModositas(model).subscribe(
      data => {
        if (data == 0) {
          this.utilityService.dialogMegnyitasa({
            success: true,
            title: 'Siker',
            message: "A jelszó módosítás sikeresen megtörtént",
            confirmButtonText: 'Rendben'
          });
          this.jelszoModositasFormGroupLetrehozasa();
        }
        else {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba történt a jelszó módosítás közben",
            confirmButtonText: 'Rendben'
          });
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba történt a jelszó módosítás közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  // Regisztráció törlés gomb esemény
  onRegisztracioTorleseClick() {
    // Megerősítő dialog ablak
    const dialogRef = this.utilityService.dialogMegnyitasa({
      confirm: true,
      title: 'Regisztráció törlése',
      message: 'Biztos benne, hogy törölni szeretné a regisztrációját?',
      cancelButtonText: 'Mégsem',
      confirmButtonText: 'Igen, törölni szeretném'
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      // Ha igen, mehet a törlés email
      if (result) {
        this.felhasznaloService.felhasznaloTorlesLinkKuldes().subscribe(
          () => {
            this.utilityService.dialogMegnyitasa({
              info: true,
              title: 'Regisztráció törlése',
              message: 'A regisztráció törlését az e-mail címére küldött üzenetünkben tudja véglegesíteni.',
              confirmButtonText: 'Rendben'
            });
          },
          errorResult => {
            this.utilityService.dialogMegnyitasa({
              error: true,
              title: 'Hiba',
              message: "Hiba a regisztráció törlése közben",
              confirmButtonText: 'Rendben'
            });
          }
        );
      }
    });
  }

  // Szerződő neme select change esemény
  onSzerzodoNemeChange(event) {
    if (event.value == 'N' || event.value == 'F') {
      this.szerzodesAdatokFormGroup.controls['f_szerzodo_szuletesi_datuma'].setValidators(Validators.required);
      this.szerzodesAdatokFormGroup.controls['f_szerzodo_adoszama'].setValidators(null);
      this.szerzodesAdatokFormGroup.controls['f_szerzodo_adoszama'].patchValue(null);
    }
    else if (event.value == 'J') {
      this.szerzodesAdatokFormGroup.controls['f_szerzodo_adoszama'].setValidators(Validators.required);
      this.szerzodesAdatokFormGroup.controls['f_szerzodo_szuletesi_datuma'].setValidators(null);
      this.szerzodesAdatokFormGroup.controls['f_szerzodo_szuletesi_datuma'].patchValue(null);
    }
  }
}
