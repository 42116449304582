import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SzerzodesService {

  constructor(private httpClient: HttpClient) { }

  private url: string = '/api/szerzodes';

  // Ügyfélazonosító lekérése szertődés adatok alapján
  ugyfelazonositoSzerzodesrol(model: any) {
    return this.httpClient.post<any>(this.url + '/UgyfelazonositoSzerzodesrol', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Aktuális bejelentkezett felhasználóhoz tartozó szerződés alapadatok lekérése, főoldalra
  szerzodesAlapadatok() {
    return this.httpClient.post<any>(this.url + '/SzerzodesAlapadatok', null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Szerződés részletes adatai szerződés aloldalra, szerződés azonosító alapján
  szerzodesAdatok(model: any) {
    return this.httpClient.post<any[]>(this.url + '/SzerzodesAdatok', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Szerződés adat változás bejelentés elküldése
  valtozasBejelentesElkuldese(model: any) {
    return this.httpClient.post<any>(this.url + '/ValtozasBejelentesElkuldese', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Kárbejelentő inditása szerzodes kiválasztással
  karbejelentoInditasaSzerzodesrol(model: any) {
    return this.httpClient.post<any>(this.url + '/KarbejelentoInditasaSzerzodesrol', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
}
