import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DokumentumService {

  constructor(private httpClient: HttpClient) { }

  private url: string = '/api/dokumentum';

  // Aktuális bejelentkezett felhasználóhoz tartozó dokumentum alapadatok lekérése, főoldalra
  dokumentumAlapadatok() {
    return this.httpClient.post<any>(this.url + '/DokumentumAlapadatok', null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Aktuális bejelentkezett felhasználóhoz tartozó dokumentum adatok lekérése, aloldalra
  dokumentumAdatok(model: any) {
    return this.httpClient.post<any>(this.url + '/DokumentumAdatok', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // dokumentum igény mentése
  wsDokumentumIgeny(model: any) {
    return this.httpClient.post<any>(this.url + '/WsDokumentumIgeny', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Levelező azonosító alapján levél letöltés
  levelLetoltes(model: any) {
    return this.httpClient.post(this.url + '/LevelLetoltes', model, { responseType: 'blob', observe: 'response' }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Kiadható dokumentumok lekérése
  kiadhatoDokumentumok(model: any) {
    return this.httpClient.post<any>(this.url + '/KiadhatoDokumentumok', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
}
