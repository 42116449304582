import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KarService {

  constructor(private httpClient: HttpClient) { }

  private url: string = '/api/kar';

  // Aktuális bejelentkezett felhasználóhoz tartozó kár alapadatok lekérése, főoldalra
  karAlapadatok() {
    return this.httpClient.post<any>(this.url + '/KarAlapadatok', null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Kárszámhoz tartozó adatok lekérése
  karAdatok(model: any) {
    return this.httpClient.post<any>(this.url + '/KarAdatok', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
}
