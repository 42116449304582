import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommonValidator, HibaService, UtilityService } from '@granit/common-library';
import { FelhasznaloService, PortalValidator } from '@granit/portal-library';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'regisztracio-megerosites',
  templateUrl: './regisztracio-megerosites.component.html',
  styleUrls: ['./regisztracio-megerosites.component.scss']
})
export class RegisztracioMegerositesComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private utilityService: UtilityService,
    private hibaService: HibaService,    
    private formBuilder: FormBuilder,
    private felhasznaloService: FelhasznaloService,
    private recaptchaV3Service: ReCaptchaV3Service) {

    // token querystring kiolvasása
    this.route.queryParams.subscribe(params => {
      // Ha a token querystringnek van értéke
      if (params['token']) {
        this.token = (params['token'] as string);
      }
    });
  }

  toltoKepernyo: boolean = true;
  token: string;
  megerositesFromGroup: FormGroup;
  jelszoUjElrejtese: boolean = true;
  jelszoUjIsmetElrejtese: boolean = true;

  ngOnInit(): void {
    // Ha nincs token querystring, akkor hiba és vissza navigáljuk a bejelentkezés oldalra
    if (!this.token) {
      const dialogRef = this.utilityService.dialogMegnyitasa({
        error: true,
        title: 'Hiba az oldal megnyitásakor',
        message: 'Az oldal hiányzó paraméterrel lett megnyitva.',
        confirmButtonText: 'Vissza a főoldalra'
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.router.navigate(['/bejelentkezes']);
        }
      });
    }
    else {
      // Ha van token querystring, ellenőrozzük, hogy valid-e
      this.utilityService.jwtTokenValidalas({ token: this.token }).subscribe(
        data => {
          this.megerositesFromGroupLetrehozasa();
          this.megerositesFromGroup.controls["f_felhasznalo_id"].setValue(data.f_azon);
          this.felhasznaloAzonositoEllenorzes();          
        },
        errorResult => {
          const dialogRef = this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba az oldal megnyitásakor',
            message: errorResult,
            confirmButtonText: 'Vissza a főoldalra'
          });
          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              this.router.navigate(['/bejelentkezes']);
            }
          });
        }
      );
    }
  }

  // Megerősítés formgroup létrehozása
  megerositesFromGroupLetrehozasa() {
    this.megerositesFromGroup = this.formBuilder.group({
      f_felhasznalo_id: new FormControl(null),
      f_felhasznalo_nev: new FormControl(null, { updateOn: 'blur', validators: [Validators.required, Validators.minLength(6), PortalValidator.felhasznaloNevFoglalt(0, this.felhasznaloService)] }),
      f_jelszo: new FormControl(null, { updateOn: 'blur', validators: [Validators.required, PortalValidator.jelszoEllenorzes()] }),
      f_jelszo_ismet: new FormControl(null, [Validators.required])
    },
      {
        validators: [CommonValidator.osszehasonlitas('f_jelszo', 'f_jelszo_ismet')]
      }
    );
  }

  felhasznaloAzonositoEllenorzes() {
    this.felhasznaloService.felhasznaloAzonositoEllenorzes().subscribe(
      adat => {

        if (adat != 0 && adat != 2) {
          let hibaSzoveg = '';
          switch (adat) {
            case 1:
              hibaSzoveg = "A felhasználó nem létezik az ügyfélportál adatbázisban";
              break;            
            case 3:
              hibaSzoveg = "A felhasználó már aktív az ügyfélportálon";
              break;
          }
          const dialogRef = this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba az oldal megnyitásakor',
            message: hibaSzoveg,
            confirmButtonText: 'Vissza a főoldalra'
          });
          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              this.router.navigate(['/bejelentkezes']);
            }
          });
        }
        else {
          this.toltoKepernyo = false;
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a lekérdezés közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  onMentesClick() {
    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('ugyfelportal_regisztracio_megerosites_onMentesClick').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {
          this.felhasznaloService.regisztracioMegerosites(this.megerositesFromGroup.value).subscribe(
            adat => {
              if (adat == 0) {
                const dialogRef = this.utilityService.dialogMegnyitasa({
                  success: true,
                  title: 'Sikeres mentés',
                  message: 'A regisztráció megerősítése sikeresen megtörtént.',
                  confirmButtonText: 'Bejelentkezés'
                });
                dialogRef.afterClosed().subscribe((result: boolean) => {
                  if (result) {
                    this.router.navigate(['/bejelentkezes']);
                  }
                });
              }
              else {
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Sikertelen mentés',
                  message: 'A regisztráció megerősítése sikertelen volt.',
                  confirmButtonText: 'Rendben'
                });
              }
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a regisztráció megerősítése közben",
                confirmButtonText: 'Rendben'
              });
            }
          );
        });
      }
    );
  }
}
