<div class="row mt-3 pl-5 pr-5">
  <mat-card *ngFor="let modozat of modozatokLista" class="col-lg-2 col-md-2 col-sm-2 modozat-wrapper" [class.modozat-wrapper-selected]="modozat.f_id == kivalasztottModozat?.f_id" (click)="onModozatClick(modozat)">
    <ng-container *ngIf="toltoKepernyo">
      <div class="d-flex justify-content-center">
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{width: '100px', height: '82px','border-radius': '10px'}">
        </ngx-skeleton-loader>
      </div>
    </ng-container>
    <ng-container *ngIf="!toltoKepernyo">
      <mat-card-header>
        <mat-card-title class="d-flex justify-content-center">
          <mat-icon matBadge="{{modozat.f_szerzodesek_szama}}" matBadgeHidden="{{modozat.f_szerzodesek_szama == 0}}" matBadgeColor="accent">{{modozat.f_ikon}}</mat-icon>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="d-flex flex-column align-items-center">
        <div>
          <span class="font-weight-bold color-primary">{{modozat.f_nev}}</span>
        </div>
      </mat-card-content>
    </ng-container>
  </mat-card>
</div>
<mat-card class="szerzodesek-wrapper">
  <mat-card-content>
    <ng-container *ngIf="toltoKepernyo">
      <div class="col-lg-10 ml-auto mr-auto bg-white pt-3 pr-3 pl-3">
        <ngx-skeleton-loader count="3" [theme]="{'border-radius': '0', height: '50px' }">
        </ngx-skeleton-loader>
      </div>
    </ng-container>
    <ng-container *ngIf="!toltoKepernyo">
      <mat-icon>{{kivalasztottModozat.f_ikon}}</mat-icon>
      <div class="col-lg-10 ml-auto mr-auto pb-4">
        <button mat-raised-button color="accent" class="mr-4" (click)="onTarifalasEsKotesClick()">{{kivalasztottModozat.f_id == 'EGYÉB' ? 'Egészségbiztosítás tarifálás és szerződéskötés' : 'Tarifálás és szerződéskötés'}}</button>
        <mat-checkbox (change)="onToroltSzerzodeskMutatasChange($event)">Mutassa a törölt szerződéseimet is</mat-checkbox>
        <div *ngIf="kivalasztottModozat.f_szerzodesek_szama == 0" class="pt-4">
          A(z) <span style="font-weight:500;">{{kivalasztottModozat.f_nev}}</span> módozathoz nem található élő szerződés.
        </div>
      </div>
      <div *ngFor="let szerzodes of modozatSzerzodesekLista" class="col-lg-10 ml-auto mr-auto szerzodes-wrapper" [class.szerzodes-elo]="szerzodes.f_allapot_tipus.toLowerCase()  != 'törölt'" [class.szerzodes-torolt]="szerzodes.f_allapot_tipus.toLowerCase()  == 'törölt'">
        <div class="row">
          <div class="col-11">
            <div class="row">
              <div class="col-xxl-6 col-xl-6 col-md-6 col-sm-6">
                <div [ngSwitch]="kivalasztottModozat.f_id" style="font-weight:500;">
                  <div *ngSwitchCase="'KGFB'">
                    {{ szerzodes.f_rendszam}}
                  </div>
                  <div *ngSwitchCase="'OTTHON'">
                    {{ szerzodes.f_kockhely}}
                  </div>
                  <div *ngSwitchCase="'CASCO'">
                    {{ szerzodes.f_rendszam}}
                  </div>
                  <div *ngSwitchCase="'MFO'">
                    {{ szerzodes.f_kockhely}}
                  </div>
                  <div *ngSwitchCase="'UTAS'">
                    {{ szerzodes.f_utas_regio}}
                  </div>
                </div>
                <div>
                  Szerződésszám: {{szerzodes.f_szerz_azon}}
                </div>
                <div>
                  Kockázatviselés: {{szerzodes.f_kockezd | dateFormat: 'YYYY. MM. DD.'}} - {{szerzodes.f_kockvege | dateFormat: 'YYYY. MM. DD.'}}
                </div>
                <div *ngIf="szerzodes.f_modozat=='EGYÉB'">
                  {{szerzodes.f_modozatnev}}
                </div>
              </div>
              <div class="col-xxl-6 col-xl-6 col-md-6 col-sm-6">
                <div>
                  <span>
                    {{szerzodes.f_allapot_tipus}} szerződés
                  </span>
                </div>
                <div>
                  Díjrendezettség: {{szerzodes.f_dijrend | dateFormat: 'YYYY. MM. DD.'}} -ig
                </div>
                <div>
                  Elvi kockázatvég: {{szerzodes.f_elvi_kockvege | dateFormat: 'YYYY. MM. DD.'}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-1">
            <div class="h-100 d-flex justify-content-end align-items-center">
              <button mat-icon-button [matMenuTriggerFor]="muveletekMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #muveletekMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="onSzerzodasAdatokClick(szerzodes.f_szerz_azon)">
                  <mat-icon>content_paste</mat-icon>
                  Szerződés adatlap
                </button>
                <button *ngIf="szerzodes.f_allapot_tipus.toLowerCase() == 'élő'" mat-menu-item (click)="onBefizetesClick(szerzodes.f_szerz_azon)">
                  <mat-icon>credit_card</mat-icon>
                  Díjbefizetés
                </button>
                <button *ngIf="szerzodes.f_allapot_tipus.toLowerCase() == 'élő' && (szerzodes.f_modozat == 'KGFB' || szerzodes.f_modozat == 'CASCO' || szerzodes.f_modozat == 'OTTHON' || szerzodes.f_modozat == 'CMR' || szerzodes.f_modozat == 'MFO')" mat-menu-item (click)="onKarbejelentesClick(szerzodes.f_szerz_azon)">
                  <mat-icon>flash_on</mat-icon>
                  Kárbejelentés
                </button>
                <button *ngIf="szerzodes.f_allapot_tipus.toLowerCase() == 'élő'" mat-menu-item (click)="onDokumentumIgenylesClick(szerzodes.f_szerz_azon)">
                  <mat-icon>receipt_long</mat-icon>
                  Dokumentum igénylés
                </button>
              </mat-menu>

              <!--<button mat-icon-button matTooltip="Díjbefizetés" matTooltipPosition="above" color="primary" (click)="onBefizetesClick(szerzodes.f_szerz_azon)">
                <mat-icon>credit_card</mat-icon>
              </button>
              <button *ngIf="szerzodes.f_allapot_tipus.toLowerCase() == 'élő' && (szerzodes.f_modozat == 'KGFB' || szerzodes.f_modozat == 'CASCO' || szerzodes.f_modozat == 'OTTHON' || szerzodes.f_modozat == 'CMR')" mat-icon-button matTooltip="Kárbejelentés" matTooltipPosition="above" color="primary" (click)="onKarbejelentesClick(szerzodes.f_szerz_azon)">
                <mat-icon>flash_on</mat-icon>
              </button>
              <button mat-icon-button matTooltip="Szerződés adatlap" matTooltipPosition="above" color="primary" (click)="onSzerzodasAdatokClick(szerzodes.f_szerz_azon)">
                <mat-icon>content_paste</mat-icon>
              </button>
              <button mat-icon-button matTooltip="Dokumentum igénylés" matTooltipPosition="above" color="primary" (click)="onDokumentumIgenylesClick(szerzodes.f_szerz_azon)">
                <mat-icon>receipt_long</mat-icon>
              </button>-->
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>


