import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, Event, NavigationEnd, } from '@angular/router';
import { HibaService, UtilityService } from '@granit/common-library';
import { FelhasznaloService, Felhasznalo } from '@granit/portal-library';
import { CountdownEvent, CountdownStatus, CountdownComponent } from 'ngx-countdown';

@Component({
  selector: 'portal-fejlec',
  templateUrl: './portal-fejlec.component.html',
  styleUrls: ['./portal-fejlec.component.scss']
})
export class PortalFejlecComponent implements OnInit {

  constructor(
    private felhasznaloService: FelhasznaloService,
    private utilityService: UtilityService,
    public dialog: MatDialog,    
    public hibaService: HibaService,
    private router: Router) {    
  }


  teszt: boolean = false;
  routerUrl: string;
  fooldalGombLathatosag: boolean;
  homeRouterLink: string = "";
  aktualisFelhasznalo: Felhasznalo;
  @ViewChild('countdownComponent', { static: false }) private countdownComponent: CountdownComponent;

  ngOnInit(): void {

    this.environmentName();

    // A főoldali gomb csak akkor látszik, ha nem a bejelentkezés vagy a főoldal url-en vagyunk
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/bejelentkezes' || event.url == '/fooldal') {
          this.fooldalGombLathatosag = false;
        }
        else {
          this.fooldalGombLathatosag = true;
        }

        if (event.url == '/bejelentkezes') {
          this.homeRouterLink = "/bejelentkezes";
        }
        else {
          this.homeRouterLink = "/fooldal";
        }
      }
    });    

    // Felhasználó subscribe
    this.felhasznaloService.aktualisFelhasznalo.subscribe(
      felhasznalo => {
        this.aktualisFelhasznalo = felhasznalo;
      }
    );
  }

  environmentName() {
    this.utilityService.environmentName().subscribe(
      data => {
        this.teszt = data != "Production";
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a lekérdezés közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  handleEvent(event: CountdownEvent) {
    if (event.action == 'notify') {
      this.utilityService.dialogMegnyitasa({
        info: true,
        title: 'Munkamenete 5 perc múlva lejár...',
        message: 'Inaktivitás miatt automatikusan kilépteti a rendszer. Kattintson a \'Rendben\' gombra, hogy a munkamenet újrakezdődjön.',
        confirmButtonText: 'Rendben'
      });
    }
    if (event.status == CountdownStatus.done) {
      this.felhasznaloService.kijelentkezes();
      this.dialog.closeAll();
      this.utilityService.dialogMegnyitasa({
        info: true,
        title: 'Automatikus kiléptetés',
        message: 'Tétlenség miatt a rendszer biztonsági okokból kiléptette.',
        confirmButtonText: 'Rendben'
      });
    }
  }

  onKijelentkezesClick() {
    this.felhasznaloService.kijelentkezes();
  }

  @HostListener('document:click', ['$event'])
  click(event) {
    if (this.countdownComponent) {
      this.countdownComponent.restart();
    }
  }
}
