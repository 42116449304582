import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'aloldal',
  templateUrl: './aloldal.component.html',
  styleUrls: ['./aloldal.component.scss']
})
export class AloldalComponent implements OnInit {

  routerUrl: string;
  title: string = '';
  subTitle: string = '';  
  azonosito = new BehaviorSubject<string>('');

  constructor(private router: Router) { }

  ngOnInit(): void {
    // Leszedjük az aktuális router url-t
    this.routerUrl = this.router.url.substring(1);
    this.oldalCimBeallitas();

    this.azonosito.next(localStorage.getItem('azonosito'));
  }

  // Oldal címének beállítása
  oldalCimBeallitas() {
    switch (this.routerUrl) {
      case 'szerzodes-adatok':
        this.title = 'Szerződés adatok';
        this.subTitle = 'Megtekintheti a szerződésének részletes adatait és bejelentheti az adatokban történt változásokat.';
        break;
      case 'kapcsolattartas':
        this.title = 'Kapcsolat';
        this.subTitle = '';
        break;
      case 'profil-kezeles':
        this.title = 'Profil kezelés';
        this.subTitle = 'Amennyiben módosítani szeretné a regisztráció során megadott adatokat, itt megteheti!';
        break;
      case 'kar-adatok':
        this.title = 'Kár adatok';
        this.subTitle = '';
        break;
      case 'dokumentumok-adatok':
        this.title = 'Dokumentumok';
        this.subTitle = '';
        break;
      default:
    }
  }

  ngOnDestroy(): void {
    localStorage.removeItem('azonosito');
  }
}
