
<div class="row">
  <div class="col-lg-12">
    <mat-card class="p-0">
      <mat-card-content>
        <div class="row no-gutters">
          <div class="col-lg-3 menu-wrapper">
            <ng-container *ngIf="toltoKepernyo">
              <div class="col-lg-10 ml-auto mr-auto bg-white pt-3 pr-3 pl-3">
                <ngx-skeleton-loader count="3" [theme]="{'border-radius': '0', height: '50px' }">
                </ngx-skeleton-loader>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <mat-nav-list>
                <mat-list-item [class.kivalasztott-menu]="kivalasztottMenu && kivalasztottMenu == 'Káradatok'">
                  <button mat-button (click)="onMenuClick('Káradatok')">
                    <span>Káradatok</span>
                  </button>
                  <mat-divider></mat-divider>
                  <div class="balra-nyil">
                  </div>
                </mat-list-item>

                <mat-list-item [class.kivalasztott-menu]="kivalasztottMenu && kivalasztottMenu == 'Kiküldött levelek'">
                  <button mat-button (click)="onMenuClick('Kiküldött levelek')">
                    <span>Kiküldött levelek</span>
                  </button>
                  <mat-divider></mat-divider>
                  <div class="balra-nyil">
                  </div>
                </mat-list-item>

                <mat-list-item [class.kivalasztott-menu]="kivalasztottMenu && kivalasztottMenu == 'Kifizetések'">
                  <button mat-button (click)="onMenuClick('Kifizetések')">
                    <span>Kifizetések</span>
                  </button>
                  <mat-divider></mat-divider>
                  <div class="balra-nyil">
                  </div>
                </mat-list-item>

              </mat-nav-list>
            </ng-container>
          </div>
          <div class="col-lg-9">
            <ng-container *ngIf="toltoKepernyo">
              <div class="col-lg-10 ml-auto mr-auto bg-white pt-3 pr-3 pl-3">
                <ngx-skeleton-loader count="3" [theme]="{'border-radius': '0', height: '50px' }">
                </ngx-skeleton-loader>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <mat-card class="form-content pt-2 pr-4 pb-2 pl-4 h-100">
                <mat-card-header>
                  <mat-card-title *ngIf="kivalasztottMenu" class="content-title pb-3">{{kivalasztottMenu}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="row">
                    <!-- Káradatok -->
                    <ng-container *ngIf="kivalasztottMenu && kivalasztottMenu == 'Káradatok'">
                      <div class="col-lg-3">
                        <div class="control-group">
                          <label class="control-header-label">Kárszám</label>
                          <label class="control-data-label">{{adatok.karAdatok.f_karszam}}/{{adatok.karAdatok.f_tszam}}</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="control-group">
                          <label class="control-header-label">Szerződésazonosító</label>
                          <label class="control-data-label">{{adatok.karAdatok.f_szerz_azon}}</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="control-group">
                          <label class="control-header-label">Káresemény dátuma</label>
                          <label class="control-data-label">{{adatok.karAdatok.f_karesemeny_datuma | date: 'yyyy.MM.dd'}}</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="control-group">
                          <label class="control-header-label">Kárbejelentés dátuma</label>
                          <label class="control-data-label">{{adatok.karAdatok.f_karbejelentes_datuma | date: 'yyyy.MM.dd'}}</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="control-group">
                          <label class="control-header-label">Módozat</label>
                          <label class="control-data-label">{{adatok.karAdatok.f_modnev}}</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="control-group">
                          <label class="control-header-label">Státusz</label>
                          <label class="control-data-label">{{adatok.karAdatok.f_kar_statusz_megnevezes}}</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="control-group">
                          <label class="control-header-label">Baleset helye</label>
                          <label class="control-data-label">{{adatok.karAdatok.f_baleset_helye ? adatok.karAdatok.f_baleset_helye : '-'}}</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="control-group">
                          <label class="control-header-label">Szemle helye</label>
                          <label class="control-data-label">{{adatok.karAdatok.f_szemle_helye ? adatok.karAdatok.f_szemle_helye : '-'}}</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="control-group">
                          <label class="control-header-label">Kockázatviselés helye</label>
                          <label class="control-data-label">{{adatok.karAdatok.f_kockvis_helye ? adatok.karAdatok.f_kockvis_helye : '-'}}</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="control-group">
                          <label class="control-header-label">Kárszakértő</label>
                          <label class="control-data-label">{{adatok.karAdatok.f_karszakerto ? adatok.karAdatok.f_karszakerto : '-'}}</label>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="control-group">
                          <label class="control-header-label">Kárszakértő elérhetősége</label>
                          <label class="control-data-label">{{adatok.karAdatok.f_karszakerto_elerhetoseg ? adatok.karAdatok.f_karszakerto_elerhetoseg : '-'}}</label>
                        </div>
                      </div>
                      <div class="col-lg-3" *ngIf="adatok.karAdatok.f_elvart_regressz_akt">
                        <div class="control-group">
                          <label class="control-header-label">Aktuális elvárt regressz</label>
                          <label class="control-data-label">{{adatok.karAdatok.f_elvart_regressz_akt ? adatok.karAdatok.f_elvart_regressz_akt : '-'}}</label>
                        </div>
                      </div>
                      <div class="col-lg-3" *ngIf="adatok.karAdatok.f_elvart_regressz_eredeti">
                        <div class="control-group">
                          <label class="control-header-label">Eredeti elvárt regressz</label>
                          <label class="control-data-label">{{adatok.karAdatok.f_elvart_regressz_eredeti ? adatok.karAdatok.f_elvart_regressz_eredeti : '-'}}</label>
                        </div>
                      </div>

                      <div class="col-lg-12" *ngIf="onreszTabla">
                        <label class="control-header-label">Önrész</label>
                        <granit-table #onreszTabla
                                       [columns]="onreszOszlopok"
                                       [formGroup]="onreszFormGroup">
                        </granit-table>
                      </div>

                    </ng-container>
                    <!-- Kiküldött levelek -->
                    <ng-container *ngIf="kivalasztottMenu && kivalasztottMenu == 'Kiküldött levelek'">
                      <div class="col-lg-12">
                        <granit-table #kikuldottLevelekTabla
                                       [columns]="kikuldottLevelekOszlopok"
                                       [formGroup]="kikuldottLevelekFormGroup"
                                       [isEditable]="true"
                                       [addNewMode]="''"
                                       [operationButtonText]="'Letöltés'"
                                       [operationButtonIcon]="'file_download'"
                                       (rowOperationClick)="onRowOperationClick($event)">
                        </granit-table>
                      </div>
                    </ng-container>
                    <!-- Kifizetések -->
                    <ng-container *ngIf="kivalasztottMenu && kivalasztottMenu == 'Kifizetések'">
                      <div class="col-lg-12">
                        <granit-table #kifizetesekTabla
                                       [columns]="kifizetesekOszlopok"
                                       [formGroup]="kifizetesekFormGroup">
                        </granit-table>
                      </div>
                    </ng-container>
                  </div>
                </mat-card-content>
              </mat-card>
            </ng-container>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
