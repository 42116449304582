import { Component, HostListener } from '@angular/core';
import { UtilityService } from '@granit/common-library';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private utilityService: UtilityService) { }

  title = 'ClientApp';


  @HostListener('document:click', ['$event'])
  click(event) {
    
  }

  ngOnInit(): void {
    this.utilityService.webAppsUrlLekerese().subscribe();
  }

}
