import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { HibaService, UtilityService } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import { KarService } from '../../../services/_index';

@Component({
  selector: 'karstatuszok',
  templateUrl: './karstatuszok.component.html',
  styleUrls: ['./karstatuszok.component.scss']
})
export class KarstatuszokComponent implements OnInit {

  constructor(
    private felhasznaloService: FelhasznaloService,
    private karService: KarService,
    private hibaService: HibaService,
    private utilityService: UtilityService,
    private router: Router) { }

  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;
  karugyekLista: any[] = [];
  toltoKepernyo: boolean = true;
  lezartKarugyekMutatasa: boolean = false;
  karugyekSzurtLista: any[] = [];
  webAppsUrl: string = this.utilityService.webAppsUrlValue;

  ngOnInit(): void {
    this.karService.karAlapadatok().subscribe(
      data => {
        this.karugyekLista = data;
        this.karugyekSzurese();
        this.toltoKepernyo = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a lekérdezés közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  // Kárügyek szűrése a lezártak mutatása checkbox alapján 
  karugyekSzurese() {
    this.karugyekSzurtLista = this.karugyekLista.filter(k => this.lezartKarugyekMutatasa || (!this.lezartKarugyekMutatasa && k.f_szazalek_osszesitve != 100))
  }

  // Új kár bejelentése gomb esemény
  onUjKarbejelentesClick() {
    window.open(this.webAppsUrl + '/karbejelento', '_blank');
  }

  // Lezártak mutatása checkbox esemény
  onLezartKarugyekkMutatasChange(event: MatCheckboxChange) {
    this.lezartKarugyekMutatasa = event.checked;
    this.karugyekSzurese();
  }

  // Kár adatlap gomb esemény
  onKarAdatlapClick(azonosito: any) {
    localStorage.setItem('azonosito', azonosito);
    this.router.navigateByUrl('/kar-adatok');
  }
}
