import { NgModule } from '@angular/core';

import { CommonLibraryModule } from '@granit/common-library';
import { PortalLibraryModule } from '@granit/portal-library';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxMaskModule } from 'ngx-mask'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PortalFejlecComponent } from './components/portal-fejlec/portal-fejlec.component';
import { PortalLablecComponent } from './components/portal-lablec/portal-lablec.component';
import { FooldalComponent } from './components/fooldal/fooldal.component';
import { RegisztracioComponent } from './components/regisztracio/regisztracio.component';
import { BejelentkezesComponent } from './components/bejelentkezes/bejelentkezes.component';
import { SzerzodesekComponent } from './components/fooldal/szerzodesek/szerzodesek.component';
import { KarstatuszokComponent } from './components/fooldal/karstatuszok/karstatuszok.component';
import { DokumentumokComponent } from './components/fooldal/dokumentumok/dokumentumok.component';
import { SzerzodesAdatokComponent } from './components/aloldal/szerzodes-adatok/szerzodes-adatok.component';
import { AloldalComponent } from './components/aloldal/aloldal.component';
import { KapcsolatTartasComponent } from './components/aloldal/kapcsolattartas/kapcsolattartas.component';
import { ProfilComponent } from './components/aloldal/profil/profil.component';
import { KarAdatokComponent } from './components/aloldal/kar-adatok/kar-adatok.component';
import { DokumentumokAdatokComponent } from './components/aloldal/dokumentumok-adatok/dokumentumok-adatok.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { MuveletVeglegesitesComponent } from './components/muvelet-veglegesites/muvelet-veglegesites.component';
import { ElfelejtettJelszoComponent } from './components/elfelejtett-jelszo/elfelejtett-jelszo.component';
import { RegisztracioMegerositesComponent } from './components/regisztracio-megerosites/regisztracio-megerosites.component';


@NgModule({
  declarations: [
    AppComponent,
    PortalFejlecComponent,
    PortalLablecComponent,
    FooldalComponent,
    RegisztracioComponent,
    BejelentkezesComponent,
    SzerzodesekComponent,
    KarstatuszokComponent,
    DokumentumokComponent,
    SzerzodesAdatokComponent,
    AloldalComponent,
    KapcsolatTartasComponent,
    ProfilComponent,
    KarAdatokComponent,
    DokumentumokAdatokComponent,
    MuveletVeglegesitesComponent,
    ElfelejtettJelszoComponent,
    RegisztracioMegerositesComponent
  ],
  imports: [
    AppRoutingModule,
    TooltipModule,
    CommonLibraryModule.forRoot(),
    PortalLibraryModule.forRoot(),    
    NgxSkeletonLoaderModule,
    NgxMaskModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
