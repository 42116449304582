import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService, HibaService } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import * as moment from 'moment';
import { SzerzodesService } from '../../../services/_index';


@Component({
  selector: 'szerzodes-adatok',
  templateUrl: './szerzodes-adatok.component.html',
  styleUrls: ['./szerzodes-adatok.component.scss']
})
export class SzerzodesAdatokComponent implements OnInit {

  constructor(
    private felhasznaloService: FelhasznaloService,
    private formBuilder: FormBuilder,
    private szerzodesService: SzerzodesService,
    public hibaService: HibaService,    
    private utilityService: UtilityService,
    public dialog: MatDialog) { }

  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;
  azonosito: string;
  menuLista: any[] = [];
  toltoKepernyo: boolean = true;
  adatok: any;
  biztositottTargyTipus: string;
  kivalasztottMenu: any;
  biztositottTargyGumiOszlopok: any[] = [
    { columnDef: 'f_fmodnev', header: 'Tipus' },
    { columnDef: 'f_biztossz', header: 'Biztosítási összeg' },
    { columnDef: 'f_megjegyzes', header: 'Megjegyzés' }
  ];
  biztositottTargyGumiFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });
  biztositottTargyOtthonEpuletekOszlopok: any[] = [
    { columnDef: 'f_epulet_tipus', header: 'Épület típusa' },
    { columnDef: 'f_falazat', header: 'Falazat' },
    { columnDef: 'f_tetozet', header: 'Tetőzet' },
    { columnDef: 'f_alapterulet', header: 'Alapterület' },
    { columnDef: 'f_biztositasi_osszeg', header: 'Biztosítási összeg' }
  ];
  biztositottTargyOtthonEpuletekFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });
  biztositottTargyMfoEpuletekOszlopok: any[] = [
    { columnDef: 'f_epulet_tipus', header: 'Épület típusa' },
    { columnDef: 'f_falazat', header: 'Falazat' },
    { columnDef: 'f_tetozet', header: 'Tetőzet' },
    { columnDef: 'f_alapterulet', header: 'Alapterület' },
    { columnDef: 'f_biztositasi_osszeg', header: 'Biztosítási összeg' }
  ];
  biztositottTargyMfoEpuletekFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });
  biztositottTargyMfoIngosagokOszlopok: any[] = [
    { columnDef: 'f_epulet', header: 'Épület' },
    { columnDef: 'f_kategoria', header: 'Kategória' },
    { columnDef: 'f_biztositasi_osszeg', header: 'Biztosítási összeg' }
  ];
  biztositottTargyMfoIngosagokFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });
  biztositottTargyMfoErtektargyakOszlopok: any[] = [
    { columnDef: 'f_kategoria', header: 'Kategória' },
    { columnDef: 'f_biztositasi_osszeg', header: 'Biztosítási összeg' }
  ];
  biztositottTargyMfoErtektargyakFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });
  onreszGumiOszlopok: any[] = [
    { columnDef: 'f_fmodnev', header: 'Fedezet' },
    { columnDef: 'f_onresz', header: 'Önrész mértéke' }
  ];
  onreszGumiFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });
  kockazatviselesHelyGumiOszlopok: any[] = [
    { columnDef: 'kockhely', header: 'Cím' }
  ];
  kockazatviselesHelyGumiFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });
  zaradekGumiOszlopok: any[] = [
    { columnDef: 'f_szoveg', header: 'Záradék szövege' }
  ];
  zaradekGumiFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });
  zaradekMfoOszlopok: any[] = [
    { columnDef: 'f_megnevezes', header: 'Záradék megnevezése' },
    { columnDef: 'f_szoveg', header: 'Záradék szövege' }
  ];
  zaradekMfoFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });
  zaradekOtthonOszlopok: any[] = [
    { columnDef: 'f_megnevezes', header: 'Záradék megnevezése' },
    { columnDef: 'f_szoveg', header: 'Záradék szövege' }
  ];
  zaradekOtthonFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });
  karAdatokOszlopok: any[] = [
    { columnDef: 'f_karszam', header: 'Kárszám' },
    { columnDef: 'F_KARIDO', header: 'Káresemény dátuma' },
    { columnDef: 'utso_kifiz', header: 'Utolsó kifizetés dátum' }
  ];
  karAdatokFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });
  engedmenyezesiAdatokOszlopok: any[] = [
    { columnDef: 'f_hitelintezet_neve', header: 'Hitelintézet neve' },
    { columnDef: 'f_hitel_szam', header: 'Hitelszám' },
    { columnDef: 'f_hitelosszeg', header: 'Hitelösszeg' },
    { columnDef: 'f_hitelszaz', header: 'Hitel százalék' },
    { columnDef: 'f_hitel_hatalytol', header: 'Hitel kezdete', adattipus: 'date' },
    { columnDef: 'f_hitel_hatalyig', header: 'Hitel vége', adattipus: 'date' }
  ];
  engedmenyezesiAdatokFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });


  valtozasBejelentesTipusok: any[] = [
    //Személy adatok
    {
      f_id: 'szemely_adatok',
      f_nev: 'Személy adatok változásának bejelentése',
      f_erintett_object: 'szerzodoAdatok',
      f_erintett_mezok: [
        { f_id: 'f_ugyfelnev', f_nev: 'Szerződő neve' },
        { f_id: 'f_szuletesi_hely', f_nev: 'Születési hely' },
        { f_id: 'f_szuletesi_datum', f_nev: 'Születési dátum', f_adatTipus: 'datum', f_formatum: 'yyyy. MM. dd.' },
        { f_id: 'f_anyja_neve', f_nev: 'Anyja neve' },
        { f_id: 'f_szuleteskori_nev', f_nev: 'Születéskori név' },
        { f_id: 'f_jogositvany_kelte', f_nev: 'Jogosítvány kelte', f_adatTipus: 'datum', f_formatum: 'yyyy. MM. dd.' },
        { f_id: 'f_vallalkozo', f_nev: 'Egyéni vállalkozó', f_adatTipus: 'bool' },
        { f_id: 'f_adoszam', f_nev: 'Adószám' },
        { f_id: 'f_teaor', f_nev: 'Teaor szám' },
        { f_id: 'f_cegjegyzekszam', f_nev: 'Cégjegyzék szám' }
      ],
      f_tipus: 'modositas'
    },
    // Állandó lakcím
    {
      f_id: 'allando_lakcim',
      f_nev: 'Állandó lakcím változásának bejelentése',
      f_erintett_object: 'szerzodoAdatok',
      f_erintett_mezok: [
        { f_id: 'f_iranyitoszam', f_nev: 'Irányítószám' },
        { f_id: 'f_telepules', f_nev: 'Település' },
        { f_id: 'f_utcanev', f_nev: 'Utcanév' },
        { f_id: 'f_uttipus', f_nev: 'Úttípus' },
        { f_id: 'f_hazszam', f_nev: 'Házszám' },
        { f_id: 'f_emelet', f_nev: 'Emelet' },
        { f_id: 'f_ajto', f_nev: 'Ajtó' }
      ],
      f_tipus: 'modositas'
    },
    // Levelezési cím
    {
      f_id: 'levelezesi_cim',
      f_nev: 'Levelezési cím változásának bejelentése',
      f_erintett_object: 'szerzodoAdatok',
      f_erintett_mezok: [
        { f_id: 'f_levelezesi_iranyitoszam', f_nev: 'Irányítószám' },
        { f_id: 'f_levelezesi_telepules', f_nev: 'Hitelszám' },
        { f_id: 'f_levelezesi_utcanev', f_nev: 'Utcanév' },
        { f_id: 'f_levelezesi_uttipus', f_nev: 'Úttípus' },
        { f_id: 'f_levelezesi_hazszam', f_nev: 'Házszám' },
        { f_id: 'f_levelezesi_emelet', f_nev: 'Emelet' },
        { f_id: 'f_levelezesi_ajto', f_nev: 'Ajtó' }
      ],
      f_tipus: 'modositas'
    },
    // kommunikáció adatok - telefonszám
    {
      f_id: 'kommunikacios_adatok_telefonszam',
      f_nev: 'Kommunikációs adatok - telefonszám változásának bejelentése',
      f_erintett_object: 'szerzodoAdatok',
      f_erintett_mezok: [
        { f_id: 'f_telefon_mobil', f_nev: 'Mobil' },
        { f_id: 'f_telefon_otthon', f_nev: 'Otthoni telefonszám' },
        { f_id: 'f_telefon_munkahely', f_nev: 'Munkahelyi telefonszám' }
      ],
      f_tipus: 'modositas'
    },
    // kommunikáció adatok - email
    {
      f_id: 'kommunikacios_adatok_email',
      f_nev: 'Kommunikációs adatok - email cím változásának bejelentése',
      f_erintett_object: 'szerzodoAdatok',
      f_erintett_mezok: [
        { f_id: 'f_email_cim', f_nev: 'E-mail cím' }
      ],
      f_tipus: 'modositas'
    },
    // Bónusz előzmény új
    {
      f_id: 'bonusz_elozmeny_uj',
      f_nev: 'Új bónusz előzmény megadása',
      f_erintett_object: 'elozmenyAdatok',
      f_erintett_mezok: [
        { f_id: 'f_biztosito', f_nev: 'Biztosító' },
        { f_id: 'f_kotvenyszam', f_nev: 'Kötvényszám' },
        { f_id: 'f_rendszam', f_nev: 'Rendszám' }
      ],
      f_tipus: 'uj'
    },
    // Bónusz előzmény módosítása
    {
      f_id: 'bonusz_elozmeny_modositas',
      f_nev: 'Bónusz előzmény változásának bejelentése',
      f_erintett_object: 'elozmenyAdatok',
      f_erintett_mezok: [
        { f_id: 'f_biztosito', f_nev: 'Biztosító' },
        { f_id: 'f_kotvenyszam', f_nev: 'Kötvényszám' },
        { f_id: 'f_rendszam', f_nev: 'Rendszám' }
      ],
      f_tipus: 'modositas'
    },
    // Rendszám
    {
      f_id: 'rendszam',
      f_nev: 'Rendszám változásának bejelentése',
      f_erintett_object: 'biztositottTargyGfbCasco',
      f_erintett_mezok: [
        { f_id: 'f_rendszam', f_nev: 'Rendszám' }
      ],
      f_tipus: 'modositas'
    },
    // Alvázszám
    {
      f_id: 'alvazszam',
      f_nev: 'Alvázszám változásának bejelentése',
      f_erintett_object: 'biztositottTargyGfbCasco',
      f_erintett_mezok: [
        { f_id: 'f_alvazszam', f_nev: 'Alvázszám' }
      ],
      f_tipus: 'modositas'
    },
    // Forgalmi engedély szám
    {
      f_id: 'forgalmi_engedely_szam',
      f_nev: 'Forgalmi engedély szám változásának bejelentése',
      f_erintett_object: 'biztositottTargyGfbCasco',
      f_erintett_mezok: [
        { f_id: 'f_forgalmi_engedely_szam', f_nev: 'Forgalmi engedély szám' }
      ],
      f_tipus: 'modositas'
    },
    // Egyéb autóadat
    {
      f_id: 'egyeb_autoadat',
      f_nev: 'Egyéb autóadat változásának bejelentése',
      f_erintett_object: 'biztositottTargyGfbCasco',
      f_erintett_mezok: [
        { f_id: 'f_henger', f_nev: 'Cm3' },
        { f_id: 'f_gyartmany', f_nev: 'Gyártmány' },
        { f_id: 'f_tipus', f_nev: 'Típus' },
        { f_id: 'f_motorszam', f_nev: 'Motorszám' },
        { f_id: 'f_kw', f_nev: 'Kw' },
        { f_id: 'f_gyartasi_ev', f_nev: 'Gyártási év', f_adatTipus: 'datum', f_formatum: 'yyyy. MM. dd.' },
        { f_id: 'f_sajat_tomeg', f_nev: 'Saját tömeg' },
        { f_id: 'f_megengedett_tomeg', f_nev: 'Megengedett tömeg' },
        { f_id: 'f_uzemjelleg', f_nev: 'Üzemeltetés jellege' },
        { f_id: 'f_uzemmod', f_nev: 'Motor üzemmódja' },
        { f_id: 'f_utasszam', f_nev: 'Utas szám' },
        { f_id: 'f_jellegnev', f_nev: 'Típus' }
      ],
      f_tipus: 'modositas'
    },
    // Engedmményezési adatok
    {
      f_id: 'engedmenyezesi_adatok_modositas',
      f_nev: 'Engedményezési adat változásának bejelentése',
      f_erintett_mezok: [
        { f_id: 'f_hitelintezet_neve', f_nev: 'Hitelintézet neve' },
        { f_id: 'f_hitel_szam', f_nev: 'Település' },
        { f_id: 'f_hitelosszeg', f_nev: 'Hitelösszeg' },
        { f_id: 'f_hitelszaz', f_nev: 'Hitel százalék' },
        { f_id: 'f_hitel_hatalytol', f_nev: 'Hitel kezdete' },
        { f_id: 'f_hitel_hatalyig', f_nev: 'Hitel vége' }
      ],
      f_tipus: 'modositas'
    },
    // Szerződés megszüntetése
    {
      f_id: 'szerzodes_megszuntetese',
      f_nev: 'Szerződés megszüntetésének bejelentése',
      f_erintett_mezok: [
        { f_id: 'f_szerzodes_megszuntetes_oka', f_nev: 'Megszüntetés oka' }
      ],
      f_tipus: 'szerzodes_megszuntetese'
    },
    // Hibas adatok javítása
    {
      f_id: 'hibas_adatok_javitasa',
      f_nev: 'Hibás adatok javításának bejelentése',
      f_erintett_mezok: [
        { f_id: 'f_hibas_adatok_javitasa', f_nev: 'Felületen megadott szöveg' }
      ],
      f_tipus: 'hibas_adatok_javitasa'
    },
    // Indexálás
    {
      f_id: 'indexalas',
      f_nev: 'Index elutasításának bejelentése',
      f_erintett_mezok: [
        { f_id: 'f_indexalas_elutasitasa', f_nev: 'Felületen megadott szöveg' }
      ],
      f_tipus: 'indexalas'
    },
    // Reaktiválási kérelem
    {
      f_id: 'reaktivalasi_kerelem',
      f_nev: 'Reaktiválási kérelem bejelentése',
      f_erintett_mezok: [
        { f_id: 'f_reaktivalasi_kerelem', f_nev: 'Felületen megadott szöveg' }
      ],
      f_tipus: 'reaktivalasi_kerelem'
    }
  ];
  valtozasBejelentesTipus: any;
  valtozasBejelentesFormGroup: FormGroup;
  valtozasBejelentheto: boolean;
  telepulesLista: any[];
  utcanevLista: any[];
  uttipusLista: any[];
  biztositokLista: any[];
  hatalydatum: any;
  hatalydatumMin: any;
  hatalydatumModosithato: boolean;
  kivalaszottTablazatSor: FormGroup;
  acceptedFileExtensions: string[] = ['.jpg', '.jpeg', '.bmp', '.tif', '.png', '.pdf', '.doc', '.docx', '.odt'];
  maxFileSize: number = 10485760; // 10 MB

  ngOnInit(): void {
    this.azonosito = localStorage.getItem('azonosito')
    this.szerzodesAdatokLekerese();
    this.utilityService.biztositok().subscribe(
      data => {
        this.biztositokLista = data
      }
    );
  }

  ngOnDestroy(): void {
    localStorage.removeItem('azonosito');
  }

  // Szerződés adatok lekérése
  szerzodesAdatokLekerese() {
    let model = {
      aktualisFelhasznalo: this.aktualisFelhasznalo,
      szerzodesAzonosito: this.azonosito
    };
    this.szerzodesService.szerzodesAdatok(model).subscribe(
      data => {
        this.adatok = data;
        this.biztositottTargyTipusMeghatarozasa();
        this.menuListaFeltoltese();
        this.tablazatokFeltoltese();
        // Ha az állapot kód első karaktere kisebb mint 6, akkor nem törölt szerződés, így bejelenthető változás
        this.valtozasBejelentheto = (this.adatok.szerzodesAdatok.f_allapot_kod.charAt(0) as number) < 6;
        //this.valtozasBejelentheto = this.alkalmazasEnum == 0 && (this.adatok.szerzodesAdatok.f_allapot_kod.charAt(0) as number) < 6;
        this.toltoKepernyo = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a szerződés adatok lekérdezése közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  // Biztosítótt tárgy típusának meghatározása módozat kódnév alapján
  biztositottTargyTipusMeghatarozasa() {
    switch (this.adatok.szerzodesAdatok.f_modozat_rovid_nev) {
      case 'KGFB':
      case 'KGFB/K':
      case 'CASCO':
      case 'F/CASCO':
        this.biztositottTargyTipus = 'gepjarmu';
        break;
      case 'OTTHON1':
      case 'OTTHON2':
      case 'OTTHON3':
        this.biztositottTargyTipus = 'lakas';
        break;
      case 'MFO':
        this.biztositottTargyTipus = 'mfo';
        break;
      case 'CMR':
        this.biztositottTargyTipus = 'cmr';
        break;
      case 'KÁF':
        this.biztositottTargyTipus = 'kaf';
        break;
      case 'KSZF':
        this.biztositottTargyTipus = 'kszf';
        break;
      case 'HOGOLYO':
      case 'LAVINA':
      case 'ALKPRE':
      case 'ALKBAZ':
      case 'NRSBAZ':
      case 'NRSPRE':
        this.biztositottTargyTipus = 'utas';
        break;
      case 'G.VAGYON':
      case 'G.VAGY/K':
      case 'G.FELEL':
      case 'G.FEL/K':
        this.biztositottTargyTipus = 'gumi';
        break;
      case 'SZÁLL.FR':
      case 'SZÁLL.UT':
        this.biztositottTargyTipus = 'szallitmany';
        break;
    }
  }

  // Menü lista feltöltése
  menuListaFeltoltese() {
    this.menuLista.push({ f_id: 'szerzodo_adatai', f_cim: 'Szerződő adatai' });
    if (this.adatok.biztositottAdatok) {
      this.menuLista.push({ f_id: 'biztositott_adatai', f_cim: 'Biztositott adatai' });
    }    
    this.menuLista.push({ f_id: 'szerzodes_adatok', f_cim: 'Szerződés adatok' });

    // Csak KGFB és CASCO módozat esetén van előzmény adatok menüpont
    if ((this.adatok.szerzodesAdatok.f_modozat_rovid_nev as string).substring(0, 4) == 'KGFB' || this.adatok.szerzodesAdatok.f_modozat_rovid_nev.indexOf('CASCO') > -1) {
      this.menuLista.push({ f_id: 'bonusz_elozmeny_adatok', f_cim: 'Bónusz előzmény adatok' });
    }

    // Csak nem szállítmány biztosított tárgy típus esetén van biztosított tárgy adatai menüpont
    if (this.biztositottTargyTipus != 'szallitmany') {
      this.menuLista.push({ f_id: 'biztositott_targy_adatai', f_cim: 'Biztosított tárgy adatai' });
    }

    // csak MFO, lakás és gumi biztosított tárgy esetén van kockázat viselési hely és kapcsolódó záradékok menüpont
    if (this.biztositottTargyTipus == 'lakas' || this.biztositottTargyTipus == 'mfo' || this.biztositottTargyTipus == 'gumi') {
      this.menuLista.push({ f_id: 'kockazatviselesi_hely', f_cim: 'Kockázatviselési hely' });
      this.menuLista.push({ f_id: 'kapcsolodo_zaradekok', f_cim: 'Kapcsolódó záradékok' });
    }

    // Csak gumi biztosított tárgy esetén van kapcsolódó önrészek
    if (this.biztositottTargyTipus == 'gumi') {
      this.menuLista.push({ f_id: 'kapcsolodo_onreszek', f_cim: 'Kapcsolódó önrészek' });      
    }

    this.menuLista.push({ f_id: 'karkifizetesek', f_cim: 'Kárkifizetések' });
    // Engedményezési adatok csak lakás, mfo és casco esetén vannak
    if (this.biztositottTargyTipus == 'lakas' || this.biztositottTargyTipus == 'mfo' || (this.adatok.szerzodesAdatok.f_modozat_rovid_nev as string).indexOf('CASCO') !== -1) {
      this.menuLista.push({ f_id: 'engedmenyezesi_adatok', f_cim: 'Engedményezési adatok' });
    }

    this.menuLista.push({ f_id: 'szerzodes_megszuntetese', f_cim: 'Szerződés megszűntetése' });
    this.menuLista.push({ f_id: 'hibas_adatok_javitasa', f_cim: 'Hibás adatok javítása' });

    // Csak lakás esetén van indexálás
    if ((this.biztositottTargyTipus == 'lakas' || this.biztositottTargyTipus == 'mfo') && this.adatok.dijAdatok.indexdij > 0) {
      this.menuLista.push({ f_id: 'indexalas', f_cim: 'Indexálás' });
    }

    // Reaktiválási kérelem csak akkor jelenik meg, ha DNF állapotú és nem KGFB a szerződés
    if (this.adatok.szerzodesAdatok.f_allapot_kod == '63' && (this.adatok.szerzodesAdatok.f_modozat_rovid_nev as string).substring(0, 4) == 'KGFB') {
      this.menuLista.push({ f_id: 'reaktivalasi_kerelem', f_cim: 'Reaktiválási kérelem' });
    }

    // A kiválasztott menüpont mindig az első
    this.kivalasztottMenu = this.menuLista[0];
  }

  // Táblázatok feltöltése
  tablazatokFeltoltese() {
    // Biztosított tárgy adatai - Gumi táblázat
    if (this.adatok.biztositottTargyGumi && this.adatok.biztositottTargyGumi.length > 0) {
      const fgs = this.adatok.biztositottTargyGumi.map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.biztositottTargyGumiFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
    // Biztosított tárgy adatai - Otthon épületek táblázat
    if (this.adatok.biztositottTargyOtthonEpuletek && this.adatok.biztositottTargyOtthonEpuletek.length > 0) {
      const fgs = this.adatok.biztositottTargyOtthonEpuletek.map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.biztositottTargyOtthonEpuletekFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
    // Biztosított tárgy adatai - MFO épületek táblázat
    if (this.adatok.biztositottTargyMfoEpuletek && this.adatok.biztositottTargyMfoEpuletek.length > 0) {
      const fgs = this.adatok.biztositottTargyMfoEpuletek.map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.biztositottTargyMfoEpuletekFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
    // Biztosított tárgy adatai - MFO általános háztartási ingóságok táblázat
    if (this.adatok.biztositottTargyMfoIngosagok && this.adatok.biztositottTargyMfoIngosagok.length > 0 && this.adatok.biztositottTargyMfoIngosagok.filter(i => i.f_tipus == 'Általános háztartási ingóságok').length > 0) {
      const fgs = this.adatok.biztositottTargyMfoIngosagok.filter(i => i.f_tipus == 'Általános háztartási ingóságok').map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.biztositottTargyMfoIngosagokFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
    // Biztosított tárgy adatai - MFO értéktárgyak táblázat
    if (this.adatok.biztositottTargyMfoIngosagok && this.adatok.biztositottTargyMfoIngosagok.length > 0 && this.adatok.biztositottTargyMfoIngosagok.filter(i => i.f_tipus == 'Értéktárgyak').length > 0) {
      const fgs = this.adatok.biztositottTargyMfoIngosagok.filter(i => i.f_tipus == 'Értéktárgyak').map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.biztositottTargyMfoErtektargyakFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
    // Kapcsolódó önrészek - Gumi táblázat
    if (this.adatok.onreszGumi && this.adatok.onreszGumi.length > 0) {
      const fgs = this.adatok.onreszGumi.map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.onreszGumiFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
    // Kockázatviselési helye - Gumi táblázat
    if (this.adatok.kockazatviselesHelyGumi && this.adatok.kockazatviselesHelyGumi.length > 0) {
      const fgs = this.adatok.kockazatviselesHelyGumi.map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.kockazatviselesHelyGumiFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
    // Kapcsolódó záradékok - Gumi táblázat
    if (this.adatok.zaradekGumi && this.adatok.zaradekGumi.length > 0) {
      const fgs = this.adatok.zaradekGumi.map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.zaradekGumiFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
    
    // Kapcsolódó záradékok - Mfo táblázat
    if (this.adatok.zaradekMfo && this.adatok.zaradekMfo.length > 0) {
      const fgs = this.adatok.zaradekMfo.map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.zaradekMfoFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
    // Kapcsolódó záradékok - Otthon táblázat
    if (this.adatok.zaradekOtthon && this.adatok.zaradekOtthon.length > 0) {
      const fgs = this.adatok.zaradekOtthon.map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.zaradekOtthonFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
    
    // Káradatok táblázat
    if (this.adatok.karAdatok && this.adatok.karAdatok.length > 0) {
      const fgs = this.adatok.karAdatok.map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.karAdatokFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
    // Engedményezési adatok
    if (this.adatok.engedmenyezesiAdatok && this.adatok.engedmenyezesiAdatok.length > 0) {
      const fgs = this.adatok.engedmenyezesiAdatok.map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.engedmenyezesiAdatokFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
  }

  // Menüpont gomb esemény
  onMenuClick(menu: any) {
    this.kivalasztottMenu = menu;
    if (menu.f_id == 'szerzodes_megszuntetese') {
      this.onValtozasBejelentesClick('szerzodes_megszuntetese');
    }
    else if (menu.f_id == 'hibas_adatok_javitasa') {
      this.onValtozasBejelentesClick('hibas_adatok_javitasa');
    }
    else if (menu.f_id == 'indexalas') {
      this.onValtozasBejelentesClick('indexalas');
    }
    else if (menu.f_id == 'reaktivalasi_kerelem') {
      this.onValtozasBejelentesClick('reaktivalasi_kerelem');
    }
    else {
      this.valtozasBejelentesTipus = null;
      this.kivalaszottTablazatSor = null;
    }
  }

  // Változás bejelentés gomb esemény
  onValtozasBejelentesClick(id: string) {
    this.valtozasBejelentesTipus = this.valtozasBejelentesTipusok.filter(cs => cs.f_id == id)[0];
    this.hatalydatumBeallitasa();
    this.valtozasBejelentesFormGroupLetrehozasa();
    this.valtozasBejelentesformControlValueChangesEsemenyek();
    this.valtozasBejelentesFormGroupBeallitasok();
  }

  // Változás bejelentés form group létrehozása
  valtozasBejelentesFormGroupLetrehozasa() {
    this.valtozasBejelentesFormGroup = this.formBuilder.group({
      f_ugyfelnev: new FormControl(null),
      f_szuletesi_hely: new FormControl(null),
      f_szuletesi_datum: new FormControl(null),
      f_anyja_neve: new FormControl(null),
      f_szuleteskori_nev: new FormControl(null),
      f_jogositvany_kelte: new FormControl(null),
      f_vallalkozo: new FormControl(null),
      f_adoszam: new FormControl(null),
      f_teaor: new FormControl(null),
      f_cegjegyzekszam: new FormControl(null),

      f_iranyitoszam: new FormControl(null),
      f_telepules: new FormControl(null),
      f_utcanev: new FormControl(null),
      f_uttipus: new FormControl(null),
      f_hazszam: new FormControl(null),
      f_emelet: new FormControl(null),
      f_ajto: new FormControl(null),

      f_levelezesi_iranyitoszam: new FormControl(null),
      f_levelezesi_telepules: new FormControl(null),
      f_levelezesi_utcanev: new FormControl(null),
      f_levelezesi_uttipus: new FormControl(null),
      f_levelezesi_hazszam: new FormControl(null),
      f_levelezesi_emelet: new FormControl(null),
      f_levelezesi_ajto: new FormControl(null),

      f_telefon_mobil: new FormControl(null),
      f_telefon_otthon: new FormControl(null),
      f_telefon_munkahely: new FormControl(null),
      f_email_cim: new FormControl(null),

      f_biztosito: new FormControl(null),
      f_kotvenyszam: new FormControl(null),
      f_rendszam: new FormControl(null),

      f_alvazszam: new FormControl(null),
      f_forgalmi_engedely_szam: new FormControl(null),
      f_henger: new FormControl(null),
      f_gyartmany: new FormControl(null),
      f_tipus: new FormControl(null),
      f_motorszam: new FormControl(null),
      f_kw: new FormControl(null),
      f_gyartasi_ev: new FormControl(null),
      f_sajat_tomeg: new FormControl(null),
      f_megengedett_tomeg: new FormControl(null),
      f_uzemjelleg: new FormControl(null),
      f_uzemmod: new FormControl(null),
      f_utasszam: new FormControl(null),
      f_jellegnev: new FormControl(null),

      f_hitelintezet_neve: new FormControl(null),
      f_hitel_hatalytol: new FormControl(null),
      f_hitel_hatalyig: new FormControl(null),
      f_hitel_szam: new FormControl(null),
      f_hitelosszeg: new FormControl(null),
      f_hitelszaz: new FormControl(null),

      f_szerzodes_megszuntetes_oka: new FormControl(null),

      f_hibas_adatok_javitasa: new FormControl(null),
      f_indexalas_elutasitasa: new FormControl(null),
      f_reaktivalasi_kerelem: new FormControl(null),

      f_csatolt_fajlok: new FormControl(null),
      f_hatalydatum: new FormControl({ value: this.hatalydatum, disabled: !this.hatalydatumModosithato }, [Validators.required])
    });
  }

  // valtozasBejelentes valueChanges események
  valtozasBejelentesformControlValueChangesEsemenyek() {
    if (this.valtozasBejelentesTipus.f_id == 'szemely_adatok') {
      // Vállalkozó csak természetes személy lehet, és ott csak adószám és cégjegyzék szám van
      if (this.biztositottTargyTipus != 'mfo') {
        this.valtozasBejelentesFormGroup.controls['f_vallalkozo'].valueChanges.subscribe(value => {
          if (value == 'I') {
            this.valtozasBejelentesFormGroup.controls['f_adoszam'].setValidators([Validators.required]);
            this.valtozasBejelentesFormGroup.controls['f_cegjegyzekszam'].setValidators([Validators.required]);
          }
          else {
            this.valtozasBejelentesFormGroup.controls['f_adoszam'].setValidators(null);
            this.valtozasBejelentesFormGroup.controls['f_cegjegyzekszam'].setValidators(null);
          }
        });
      }
    }
    if (this.valtozasBejelentesTipus.f_id == 'allando_lakcim') {
      // Irányítószám módosulás esetén lekérdezzük a települést
      this.valtozasBejelentesFormGroup.controls['f_iranyitoszam'].valueChanges.subscribe(value => {
        if (value && (value as string).length == 4) {
          this.telepulesLekerdezese();
        }
        else {
          this.valtozasBejelentesFormGroup.controls['f_telepules'].reset();
          this.valtozasBejelentesFormGroup.controls['f_utcanev'].reset();
          this.valtozasBejelentesFormGroup.controls['f_uttipus'].reset();
          this.valtozasBejelentesFormGroup.controls['f_hazszam'].reset();
          this.valtozasBejelentesFormGroup.controls['f_emelet'].reset();
          this.valtozasBejelentesFormGroup.controls['f_ajto'].reset();
          this.telepulesLista = [];
          this.utcanevLista = [];
          this.uttipusLista = [];
        }
      });
      // Település módosulás esetén lekérdezzük az utcaneveket
      this.valtozasBejelentesFormGroup.controls['f_telepules'].valueChanges.subscribe(value => {
        if (value) {
          this.utcanevekLekerdezese();
        }
      });
      // Utcanév módosulás esetén lekérdezzük az úttípusokat
      this.valtozasBejelentesFormGroup.controls['f_utcanev'].valueChanges.subscribe(value => {
        if (value) {
          this.uttipusokLekerdezese();
        }
      });
    }
    if (this.valtozasBejelentesTipus.f_id == 'levelezesi_cim') {
      // Irányítószám módosulás esetén lekérdezzük a települést
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_iranyitoszam'].valueChanges.subscribe(value => {
        if (value && (value as string).length == 4) {
          this.telepulesLekerdezese();
        }
        else {
          this.valtozasBejelentesFormGroup.controls['f_levelezesi_telepules'].reset();
          this.valtozasBejelentesFormGroup.controls['f_levelezesi_utcanev'].reset();
          this.valtozasBejelentesFormGroup.controls['f_levelezesi_uttipus'].reset();
          this.valtozasBejelentesFormGroup.controls['f_levelezesi_hazszam'].reset();
          this.valtozasBejelentesFormGroup.controls['f_levelezesi_emelet'].reset();
          this.valtozasBejelentesFormGroup.controls['f_levelezesi_ajto'].reset();
          this.telepulesLista = [];
          this.utcanevLista = [];
          this.uttipusLista = [];
        }
      });
      // Település módosulás esetén lekérdezzük az utcaneveket
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_telepules'].valueChanges.subscribe(value => {
        if (value) {
          this.utcanevekLekerdezese();
        }
      });
      // Utcanév módosulás esetén lekérdezzük az úttípusokat
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_utcanev'].valueChanges.subscribe(value => {
        if (value) {
          this.uttipusokLekerdezese();
        }
      });
    }
    if (this.valtozasBejelentesTipus.f_id == 'szerzodes_megszuntetese') {
      // Ha a szerződés megszünésének oka érdekmúlás miatti törlés, akkor kötelező fájlt csatolni
      this.valtozasBejelentesFormGroup.controls['f_utcanev'].valueChanges.subscribe(value => {
        if (value == 'Érdekmúlás miatti törlés') {
          this.valtozasBejelentesFormGroup.controls['f_szerzodes_megszuntetes_fajlok'].setValidators([Validators.required]);
        }
        else {
          this.valtozasBejelentesFormGroup.controls['f_szerzodes_megszuntetes_fajlok'].setValidators(null);
        }
      });
    }
  }

  // Változás bejelentés form feltöltése a megfelelő adatokkal és validatorok beállítása
  valtozasBejelentesFormGroupBeallitasok() {

    // Személy adatok módosítása esetén beállítandó adatok
    if (this.valtozasBejelentesTipus.f_id == 'szemely_adatok') {
      this.valtozasBejelentesFormGroup.controls['f_ugyfelnev'].setValue(this.adatok.szerzodoAdatok.f_ugyfelnev);
      this.valtozasBejelentesFormGroup.controls['f_szuletesi_hely'].setValue(this.adatok.szerzodoAdatok.f_szuletesi_hely);
      this.valtozasBejelentesFormGroup.controls['f_szuletesi_datum'].setValue(this.adatok.szerzodoAdatok.f_szuletesi_datum);
      this.valtozasBejelentesFormGroup.controls['f_anyja_neve'].setValue(this.adatok.szerzodoAdatok.f_anyja_neve);
      this.valtozasBejelentesFormGroup.controls['f_szuleteskori_nev'].setValue(this.adatok.szerzodoAdatok.f_szuleteskori_nev);
      this.valtozasBejelentesFormGroup.controls['f_jogositvany_kelte'].setValue(this.adatok.szerzodoAdatok.f_jogositvany_kelte);
      this.valtozasBejelentesFormGroup.controls['f_vallalkozo'].setValue(this.adatok.szerzodoAdatok.f_vallalkozo);
      this.valtozasBejelentesFormGroup.controls['f_adoszam'].setValue(this.adatok.szerzodoAdatok.f_adoszam);
      this.valtozasBejelentesFormGroup.controls['f_teaor'].setValue(this.adatok.szerzodoAdatok.f_teaor);
      this.valtozasBejelentesFormGroup.controls['f_cegjegyzekszam'].setValue(this.adatok.szerzodoAdatok.f_cegjegyzekszam);
    }

    // Állandó lakcím módosítása esetén beállítandó adatok és validátorok
    if (this.valtozasBejelentesTipus.f_id == 'allando_lakcim') {
      this.valtozasBejelentesFormGroup.controls['f_iranyitoszam'].setValue(this.adatok.szerzodoAdatok.f_iranyitoszam);
      this.valtozasBejelentesFormGroup.controls['f_iranyitoszam'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_telepules'].setValue(this.adatok.szerzodoAdatok.f_telepules);
      this.valtozasBejelentesFormGroup.controls['f_telepules'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_utcanev'].setValue(this.adatok.szerzodoAdatok.f_utcanev);
      this.valtozasBejelentesFormGroup.controls['f_utcanev'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_uttipus'].setValue(this.adatok.szerzodoAdatok.f_uttipus);
      this.valtozasBejelentesFormGroup.controls['f_uttipus'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_hazszam'].setValue(this.adatok.szerzodoAdatok.f_hazszam);
      this.valtozasBejelentesFormGroup.controls['f_emelet'].setValue(this.adatok.szerzodoAdatok.f_emelet);
      this.valtozasBejelentesFormGroup.controls['f_ajto'].setValue(this.adatok.szerzodoAdatok.f_ajto);
    }

    // Levelezeési cím módosítása esetén beállítandó adatok és validátorok
    if (this.valtozasBejelentesTipus.f_id == 'levelezesi_cim') {
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_iranyitoszam'].setValue(this.adatok.szerzodoAdatok.f_levelezesi_iranyitoszam);
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_iranyitoszam'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_telepules'].setValue(this.adatok.szerzodoAdatok.f_levelezesi_telepules);
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_telepules'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_utcanev'].setValue(this.adatok.szerzodoAdatok.f_levelezesi_utcanev);
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_utcanev'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_uttipus'].setValue(this.adatok.szerzodoAdatok.f_levelezesi_uttipus);
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_uttipus'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_hazszam'].setValue(this.adatok.szerzodoAdatok.f_levelezesi_hazszam);
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_emelet'].setValue(this.adatok.szerzodoAdatok.f_levelezesi_emelet);
      this.valtozasBejelentesFormGroup.controls['f_levelezesi_ajto'].setValue(this.adatok.szerzodoAdatok.f_levelezesi_ajto);
    }

    // Telefonszámok módosítása esetén beállítandó adatok és validátorok
    if (this.valtozasBejelentesTipus.f_id == 'kommunikacios_adatok_telefonszam') {
      this.valtozasBejelentesFormGroup.controls['f_telefon_mobil'].setValue(this.adatok.szerzodoAdatok.f_telefon_mobil);
      this.valtozasBejelentesFormGroup.controls['f_telefon_otthon'].setValue(this.adatok.szerzodoAdatok.f_telefon_otthon);
    }

    // E-mail cím módosítása esetén beállítandó adatok és validátorok
    if (this.valtozasBejelentesTipus.f_id == 'kommunikacios_adatok_email') {
      this.valtozasBejelentesFormGroup.controls['f_email_cim'].setValue(this.adatok.szerzodoAdatok.f_email_cim);
      this.valtozasBejelentesFormGroup.controls['f_email_cim'].setValidators([Validators.email]);
    }

    // Bónusz előzmény új/módosítás esetén beállítandó adatok és validátorok
    if (this.valtozasBejelentesTipus.f_id == 'bonusz_elozmeny_modositas' || this.valtozasBejelentesTipus.f_id == 'bonusz_elozmeny_uj') {
      // Csak módosítás esetén töltjük fel a régi adatokkal
      if (this.valtozasBejelentesTipus.f_id == 'bonusz_elozmeny_modositas') {
        this.valtozasBejelentesFormGroup.controls['f_biztosito'].setValue(this.adatok.elozmenyAdatok.f_biztosito);
        this.valtozasBejelentesFormGroup.controls['f_kotvenyszam'].setValue(this.adatok.elozmenyAdatok.f_kotvenyszam);
        this.valtozasBejelentesFormGroup.controls['f_rendszam'].setValue(this.adatok.elozmenyAdatok.f_rendszam);
      }
      this.valtozasBejelentesFormGroup.controls['f_biztosito'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_kotvenyszam'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_rendszam'].setValidators([Validators.required]);
    }

    // Rendszám módosítása esetén beállítandó adatok és validátorok
    if (this.valtozasBejelentesTipus.f_id == 'rendszam') {
      this.valtozasBejelentesFormGroup.controls['f_rendszam'].setValue(this.adatok.biztositottTargyGfbCasco.f_rendszam);
      this.valtozasBejelentesFormGroup.controls['f_rendszam'].setValidators([Validators.required]);
    }

    // Alvázszám módosítása esetén beállítandó adatok és validátorok
    if (this.valtozasBejelentesTipus.f_id == 'alvazszam') {
      this.valtozasBejelentesFormGroup.controls['f_alvazszam'].setValue(this.adatok.biztositottTargyGfbCasco.f_alvazszam);
      this.valtozasBejelentesFormGroup.controls['f_alvazszam'].setValidators([Validators.required]);
    }

    // Forgalmi engedély szám módosítása esetén beállítandó adatok és validátorok
    if (this.valtozasBejelentesTipus.f_id == 'forgalmi_engedely_szam') {
      this.valtozasBejelentesFormGroup.controls['f_forgalmi_engedely_szam'].setValue(this.adatok.biztositottTargyGfbCasco.f_forgalmi_engedely_szam);
      this.valtozasBejelentesFormGroup.controls['f_forgalmi_engedely_szam'].setValidators([Validators.required]);
    }

    // Egyéb autóadat módosítása esetén beállítandó adatok és validátorok
    if (this.valtozasBejelentesTipus.f_id == 'egyeb_autoadat') {
      this.valtozasBejelentesFormGroup.controls['f_henger'].setValue(this.adatok.biztositottTargyGfbCasco.f_henger);
      this.valtozasBejelentesFormGroup.controls['f_gyartmany'].setValue(this.adatok.biztositottTargyGfbCasco.f_gyartmany);
      this.valtozasBejelentesFormGroup.controls['f_tipus'].setValue(this.adatok.biztositottTargyGfbCasco.f_tipus);
      this.valtozasBejelentesFormGroup.controls['f_motorszam'].setValue(this.adatok.biztositottTargyGfbCasco.f_motorszam);
      this.valtozasBejelentesFormGroup.controls['f_kw'].setValue(this.adatok.biztositottTargyGfbCasco.f_kw);
      this.valtozasBejelentesFormGroup.controls['f_gyartasi_ev'].setValue(this.adatok.biztositottTargyGfbCasco.f_gyartasi_ev);
      this.valtozasBejelentesFormGroup.controls['f_sajat_tomeg'].setValue(this.adatok.biztositottTargyGfbCasco.f_sajat_tomeg);
      this.valtozasBejelentesFormGroup.controls['f_megengedett_tomeg'].setValue(this.adatok.biztositottTargyGfbCasco.f_megengedett_tomeg);
      this.valtozasBejelentesFormGroup.controls['f_uzemjelleg'].setValue(this.adatok.biztositottTargyGfbCasco.f_uzemjelleg);
      this.valtozasBejelentesFormGroup.controls['f_uzemmod'].setValue(this.adatok.biztositottTargyGfbCasco.f_uzemmod);
      this.valtozasBejelentesFormGroup.controls['f_utasszam'].setValue(this.adatok.biztositottTargyGfbCasco.f_utasszam);
      this.valtozasBejelentesFormGroup.controls['f_jellegnev'].setValue(this.adatok.biztositottTargyGfbCasco.f_jellegnev);
    }

    // Engedményezési adat módosítása
    if (this.valtozasBejelentesTipus.f_id == 'engedmenyezesi_adatok_modositas') {
      this.valtozasBejelentesFormGroup.controls['f_hitelintezet_neve'].setValue(this.kivalaszottTablazatSor.controls['f_hitelintezet_neve'].value);
      this.valtozasBejelentesFormGroup.controls['f_hitelintezet_neve'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_hitel_hatalytol'].setValue(this.kivalaszottTablazatSor.controls['f_hitel_hatalytol'].value);
      this.valtozasBejelentesFormGroup.controls['f_hitel_hatalytol'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_hitel_hatalyig'].setValue(this.kivalaszottTablazatSor.controls['f_hitel_hatalyig'].value);
      this.valtozasBejelentesFormGroup.controls['f_hitel_hatalyig'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_hitel_szam'].setValue(this.kivalaszottTablazatSor.controls['f_hitel_szam'].value);
      this.valtozasBejelentesFormGroup.controls['f_hitel_szam'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_hitelosszeg'].setValue(this.kivalaszottTablazatSor.controls['f_hitelosszeg'].value);
      this.valtozasBejelentesFormGroup.controls['f_hitelosszeg'].setValidators([Validators.required]);
      this.valtozasBejelentesFormGroup.controls['f_hitelszaz'].setValue(this.kivalaszottTablazatSor.controls['f_hitelszaz'].value);
      this.valtozasBejelentesFormGroup.controls['f_hitelszaz'].setValidators([Validators.required]);
    }

    // Szerződés megszüntetése
    if (this.valtozasBejelentesTipus.f_id == 'szerzodes_megszuntetese') {
      this.valtozasBejelentesFormGroup.controls['f_szerzodes_megszuntetes_oka'].setValidators([Validators.required]);
    }

    // Hibás adatok javítása
    if (this.valtozasBejelentesTipus.f_id == 'hibas_adatok_javitasa') {
      this.valtozasBejelentesFormGroup.controls['f_hibas_adatok_javitasa'].setValidators([Validators.required]);
    }

    // Indexálás
    if (this.valtozasBejelentesTipus.f_id == 'indexalas') {
      this.valtozasBejelentesFormGroup.controls['f_indexalas_elutasitasa'].setValidators([Validators.required]);
    }

    // Reaktiválási kérelem
    if (this.valtozasBejelentesTipus.f_id == 'reaktivalasi_kerelem') {
      this.valtozasBejelentesFormGroup.controls['f_reaktivalasi_kerelem'].setValidators([Validators.required]);
    }
  }

  // Hatálydátum beállítása
  hatalydatumBeallitasa() {

    this.hatalydatumModosithato = true;

    // Személy adatok, állandó lakcím, telefonszám, email cím, engedményezési adatok esetén aktuális napi dátum felajánlása
    if (
      this.valtozasBejelentesTipus.f_id == 'szemely_adatok' ||
      this.valtozasBejelentesTipus.f_id == 'allando_lakcim' ||
      this.valtozasBejelentesTipus.f_id == 'levelezesi_cim' ||
      this.valtozasBejelentesTipus.f_id == 'kommunikacios_adatok_telefonszam' ||
      this.valtozasBejelentesTipus.f_id == 'kommunikacios_adatok_email' ||
      this.valtozasBejelentesTipus.f_id == 'bonusz_elozmeny_uj' ||
      this.valtozasBejelentesTipus.f_id == 'bonusz_elozmeny_modositas' ||
      this.valtozasBejelentesTipus.f_id == 'rendszam' ||
      this.valtozasBejelentesTipus.f_id == 'alvazszam' ||
      this.valtozasBejelentesTipus.f_id == 'forgalmi_engedely_szam' ||
      this.valtozasBejelentesTipus.f_id == 'egyeb_autoadat' ||
      this.valtozasBejelentesTipus.f_id == 'engedmenyezesi_adatok_modositas' ||
      this.valtozasBejelentesTipus.f_id == 'hibas_adatok_javitasa' ||
      this.valtozasBejelentesTipus.f_id == 'reaktivalasi_kerelem' ||
      this.valtozasBejelentesTipus.f_id == 'szerzodes_megszuntetese') {

      this.hatalydatum = moment();
      this.hatalydatumMin = moment();
    }

    // Indexálás elutasítása esetén a hatálydátum a következő évforduló
    if (this.valtozasBejelentesTipus.f_id == 'indexalas') {

      if (this.adatok.szerzodesAdatok.f_kovetkezo_evfordulo) {
        this.hatalydatum = moment(this.adatok.szerzodesAdatok.f_kovetkezo_evfordulo);
      }
      else {
        this.hatalydatum = moment();
      }
    }

    if (
      this.valtozasBejelentesTipus.f_id == 'hibas_adatok_javitasa' ||
      this.valtozasBejelentesTipus.f_id == 'indexalas') {
      this.hatalydatumModosithato = false;
    }
  }

  // Változás bejelentésnél mégse gomb esemény
  onValtozasBejelentesMegseClick() {

    // Megerősítő dialog ablak
    //const dialogRef = this.utilityService.dialogMegnyitasa({
    //  confirm: true,
    //  title: 'Változás bejelentés megszakítása',
    //  message: 'Biztos benne, hogy megszakítja a változás bejelentés kitöltését? A módosított adatokat nem menti a rendszer.',
    //  cancelButtonText: 'Mégsem',
    //  confirmButtonText: 'Igen, szeretném megszakítani'
    //});
    //dialogRef.afterClosed().subscribe((result: boolean) => {
    //  if (result) {
    //    this.valtozasBejelentesTipus = null;
    //    this.kivalaszottTablazatSor = null;
    //    this.valtozasBejelentesFormGroupLetrehozasa();
    //  }
    //});


    this.valtozasBejelentesTipus = null;
    this.kivalaszottTablazatSor = null;
    this.valtozasBejelentesFormGroupLetrehozasa();

  }

  // Változás bejelentés elküldése gomb esemény
  onValtozasBejelentesElkuldeseClick() {
    // Megerősítő dialog ablak
    const dialogRef = this.utilityService.dialogMegnyitasa({
      confirm: true,
      title: 'Változás bejelentés elküldése',
      message: 'Biztos benne, hogy elküldi a módosításokat?',
      cancelButtonText: 'Mégsem',
      confirmButtonText: 'Igen, elküldöm'
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        // Model, amit a controllerbe küldünk
        let model = {
          aktualisFelhasznalo: this.aktualisFelhasznalo,
          szerzodesAzonosito: this.azonosito,
          valtozasBejelentesTipus: this.valtozasBejelentesTipus,
          regiAdatok: this.kivalaszottTablazatSor != null ? this.kivalaszottTablazatSor.value : this.adatok,
          ujAdatok: this.valtozasBejelentesFormGroup.value
        };
        this.szerzodesService.valtozasBejelentesElkuldese(model).subscribe(
          () => {
            const dialogRef = this.utilityService.dialogMegnyitasa({
              info: true,
              title: 'Változás bejelentés sikeres',
              message: 'A váltotás bejelentése sikeresen megtörtént, az Ön által megadott módosításokat ügyfélszolgálatunk megkapta.',
              confirmButtonText: 'Rendben'
            });
            dialogRef.afterClosed().subscribe((result: boolean) => {
              if (result) {
                // Csak akkor nullázuk, ha módosítás volt
                if (this.valtozasBejelentesTipus.f_tipus == 'modositas') {
                  this.valtozasBejelentesTipus = null;
                }
                this.valtozasBejelentesFormGroupLetrehozasa();
              }
            });
          },
          errorResult => {
            // Hiba
          }
        );
      }
    });
  }

  // Település lekérdezése irányítószám alapján
  telepulesLekerdezese() {
    this.utilityService.telepulesek(this.valtozasBejelentesFormGroup.controls['f' + (this.valtozasBejelentesTipus.f_id == 'levelezesi_cim' ? '_levelezesi' : '') + '_iranyitoszam'].value).subscribe(
      data => {
        this.telepulesLista = data;
        // Ha egy darab település van, akkor automatikusan kiválasztjuk
        if (this.telepulesLista.length == 1) {
          this.valtozasBejelentesFormGroup.controls['f' + (this.valtozasBejelentesTipus.f_id == 'levelezesi_cim' ? '_levelezesi' : '') + '_telepules'].setValue(this.telepulesLista[0].f_helynev);
        }
      }
    );
  }

  // Utca nevek lekérdezése irányítószám és település alapján
  utcanevekLekerdezese() {
    this.utilityService.utcanevek(this.valtozasBejelentesFormGroup.controls['f' + (this.valtozasBejelentesTipus.f_id == 'levelezesi_cim' ? '_levelezesi' : '') + '_iranyitoszam'].value, this.valtozasBejelentesFormGroup.controls['f' + (this.valtozasBejelentesTipus.f_id == 'levelezesi_cim' ? '_levelezesi' : '') + '_telepules'].value).subscribe(
      data => {
        this.utcanevLista = data;
      }
    );
  }

  // Utca nevek lekérdezése irányítószám, település és utcanév alapján
  uttipusokLekerdezese() {
    this.utilityService.uttipusok(this.valtozasBejelentesFormGroup.controls['f' + (this.valtozasBejelentesTipus.f_id == 'levelezesi_cim' ? '_levelezesi' : '') + '_iranyitoszam'].value, this.valtozasBejelentesFormGroup.controls['f' + (this.valtozasBejelentesTipus.f_id == 'levelezesi_cim' ? '_levelezesi' : '') + '_telepules'].value, this.valtozasBejelentesFormGroup.controls['f' + (this.valtozasBejelentesTipus.f_id == 'levelezesi_cim' ? '_levelezesi' : '') + '_utcanev'].value).subscribe(
      data => {
        this.uttipusLista = data;
        // Ha egy darab úttípus van, akkor automatikusan kiválasztjuk
        if (this.uttipusLista.length == 1) {
          this.valtozasBejelentesFormGroup.controls['f' + (this.valtozasBejelentesTipus.f_id == 'levelezesi_cim' ? '_levelezesi' : '') + '_uttipus'].setValue(this.uttipusLista[0].f_uttipus);
        }
      }
    );
  }

  onEngedmenyezesiAdatRowEditClick(event: FormGroup) {
    this.kivalaszottTablazatSor = event;
    this.onValtozasBejelentesClick('engedmenyezesi_adatok_modositas');
  }
}
