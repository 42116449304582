
<div class="container-fluid section-shadow">
  <div class="row pt-2">
    <div class="section d-flex justify-content-between">
      <p>
        <span class="footer-link"><a href="../../../assets/documents/ugyfelportal_regisztracio_hasznalati_feltetelek.pdf" alt="Ügyfélportál regisztráció" target="_blank">Ügyfélportál regisztráció</a></span><span class="footer-link-separator">|</span><span class="footer-link"><a href="../../../assets/documents/ugyfelportal_adatkezelesi_tajekoztato.pdf" alt="Ügyfélportál adatkezelési tájékoztató" target="_blank">Ügyfélportál adatkezelési tájékoztató</a></span><br />
        <span>Gránit Biztosító Zrt. ©{{ev}}. Minden jog fenntartva.</span></p>
    </div>
  </div>
</div>

