<div class="row">
  <div class="col-lg-12">
    <mat-card class="p-0">
      <mat-card-content>
        <div class="row no-gutters">
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-6  menu-wrapper">
            <ng-container *ngIf="toltoKepernyo">
              <div class="col-lg-10 ml-auto mr-auto bg-white pt-3 pr-3 pl-3">
                <ngx-skeleton-loader count="3" [theme]="{'border-radius': '0', height: '50px' }">
                </ngx-skeleton-loader>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <div class="p-3">
                <span style="font-size:15px;font-weight:500;">Szerződésszám: {{azonosito}}</span>
              </div>
              <mat-divider></mat-divider>
              <mat-nav-list>
                <mat-list-item *ngFor="let menu of menuLista; let last = last" [class.kivalasztott-menu]="menu.f_id == kivalasztottMenu.f_id">
                  <button mat-button (click)="onMenuClick(menu)">
                    <span>{{menu.f_cim}}</span>
                  </button>
                  <div class="balra-nyil">
                  </div>
                  <mat-divider *ngIf="!last"></mat-divider>
                </mat-list-item>
              </mat-nav-list>
            </ng-container>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-8 col-sm-7 col-6">
            <ng-container *ngIf="toltoKepernyo">
              <div class="col-lg-10 ml-auto mr-auto bg-white pt-3 pr-3 pl-3">
                <ngx-skeleton-loader count="3" [theme]="{'border-radius': '0', height: '50px' }">
                </ngx-skeleton-loader>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <mat-card class="form-content pt-3 pr-4 pb-3 pl-4 h-100">
                <mat-card-content>
                  <div class="row">

                    <!--<div class="col-lg-12 group-divider">
                      <span>
                        {{kivalasztottMenu.f_cim}}
                      </span>
                    </div>-->
                    <!-- Szerződő adatai -->
                    <ng-container *ngIf="!valtozasBejelentesTipus && kivalasztottMenu.f_id == 'szerzodo_adatai'">
                      <!-- Személy adatok -->
                      <div class="col-12">
                        <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                          <mat-card-header>
                            <mat-card-title class="content-title">
                              <div class="row align-items-center">
                                <div class="col-lg-12">
                                  <label class="mb-0">Személyes adatok</label>
                                  <button *ngIf="valtozasBejelentheto" mat-icon-button class="edit-button" matTooltip="Személy adatok változásának bejelentése" matTooltipPosition="above" (click)="onValtozasBejelentesClick('szemely_adatok')">
                                    <mat-icon>edit</mat-icon>
                                  </button>
                                </div>
                              </div>
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <div class="row">
                              <!-- Név minden esetben van -->
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Szerződő neve</label>
                                  <label class="control-data-label">{{adatok.szerzodoAdatok.f_ugyfelnev ? adatok.szerzodoAdatok.f_ugyfelnev : '-'}}</label>
                                </div>
                              </div>
                              <!-- Ha természetes személy (F vagy N), akkor születés helye, születési idő, anyja neve, születési név, jogosítvány kelte, egyéni vállalkozó-e -->
                              <ng-container *ngIf="adatok.szerzodoAdatok.f_tipus == 'F' || adatok.szerzodoAdatok.f_tipus == 'N'">
                                <div class="col-lg-3 col-md-4 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Születési hely</label>
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_szuletesi_hely ? adatok.szerzodoAdatok.f_szuletesi_hely : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Születési dátum</label>
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_szuletesi_datum ? (adatok.szerzodoAdatok.f_szuletesi_datum | dateFormat : 'YYYY. MMMM DD.') : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Anyja neve</label>
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_anyja_neve ? adatok.szerzodoAdatok.f_anyja_neve : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Születéskori név</label>
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_szuleteskori_nev ? adatok.szerzodoAdatok.f_szuleteskori_nev : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="adatok.szerzodesAdatok.f_modozat_kod == '21101' || adatok.szerzodesAdatok.f_modozat_kod == '21111' || adatok.szerzodesAdatok.f_modozat_kod == '31101' || adatok.szerzodesAdatok.f_modozat_kod == '31102'">
                                  <div class="control-group">
                                    <label class="control-header-label">Jogosítvány kelte</label>
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_jogositvany_kelte ? (adatok.szerzodoAdatok.f_jogositvany_kelte | dateFormat : 'YYYY. MMMM DD.') : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Egyéni vállalkozó</label>
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_vallalkozo == 'I' ? 'Igen' : 'Nem'}}</label>
                                  </div>
                                </div>
                              </ng-container>
                              <!-- Ha jogi személy vagy vállalkozó és a biztosított tárgy típus nem mfo, akkor kell adószám és cégjegyzék szám-->
                              <ng-container *ngIf="(adatok.szerzodoAdatok.f_tipus == 'J' || adatok.szerzodoAdatok.f_vallalkozo == 'I') && biztositottTargyTipus != 'mfo'">
                                <div class="col-lg-3">
                                  <div class="control-group">
                                    <div class="control-header-wrapper">
                                      <label class="control-header-label">Adószám</label>
                                    </div>
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_adoszam ? adatok.szerzodoAdatok.f_adoszam : '-'}}</label>
                                  </div>
                                </div>
                                <!-- Csak jogi személy esetén van teaor szám is -->
                                <ng-container *ngIf="adatok.szerzodoAdatok.f_tipus == 'J'">
                                  <div class="col-lg-3">
                                    <div class="control-group">
                                      <div class="control-header-wrapper">
                                        <label class="control-header-label">Teaor szám</label>
                                      </div>
                                      <label class="control-data-label">{{adatok.szerzodoAdatok.f_teaor ? adatok.szerzodoAdatok.f_teaor : '-'}}</label>
                                    </div>
                                  </div>
                                </ng-container>
                                <div class="col-lg-3">
                                  <div class="control-group">
                                    <div class="control-header-wrapper">
                                      <label class="control-header-label">{{adatok.szerzodoAdatok.f_tipus == 'J' ? 'Cégjegyzék szám' : 'Nyilvántartási szám'}}</label>
                                    </div>
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_cegjegyzekszam ? adatok.szerzodoAdatok.f_cegjegyzekszam : '-'}}</label>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <!-- Cím adatok -->
                      <div class="col-12">
                        <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                          <mat-card-header>
                            <mat-card-title class="content-title">
                              Cím adatok
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <mat-list>
                              <mat-list-item>
                                <div class="row w-100 align-items-center mb-2">
                                  <div class="col-lg-4">
                                    <label class="control-header-label">Cím</label>
                                  </div>
                                  <div class="col-lg-7">
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_allando_lakcim ? adatok.szerzodoAdatok.f_allando_lakcim : '-'}}</label>
                                  </div>
                                  <div class="col-lg-1">
                                    <button *ngIf="valtozasBejelentheto" mat-icon-button class="edit-button" matTooltip="Állandó lakcím változásának bejelentése" matTooltipPosition="above" (click)="onValtozasBejelentesClick('allando_lakcim')">
                                      <mat-icon>edit</mat-icon>
                                    </button>
                                  </div>
                                </div>
                                <mat-divider></mat-divider>
                              </mat-list-item>
                              <mat-list-item>
                                <div class="row w-100 align-items-center">
                                  <div class="col-lg-4">
                                    <label class="control-header-label">Levelezési cím</label>
                                  </div>
                                  <div class="col-lg-7">
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_levelezesi_cim ? adatok.szerzodoAdatok.f_levelezesi_cim : '-'}}</label>
                                  </div>
                                  <div class="col-lg-1">
                                    <button *ngIf="valtozasBejelentheto" mat-icon-button class="edit-button" matTooltip="Levelezési cím változásának bejelentése" matTooltipPosition="above" (click)="onValtozasBejelentesClick('levelezesi_cim')">
                                      <mat-icon>edit</mat-icon>
                                    </button>
                                  </div>
                                </div>
                              </mat-list-item>
                            </mat-list>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <!-- Kapcsolattartási adatok -->
                      <div class="col-12">
                        <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                          <mat-card-header>
                            <mat-card-title class="content-title">
                              Kapcsolattartási adatok
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <mat-list>
                              <mat-list-item style="height:auto">
                                <div class="row w-100 align-items-center mb-2">
                                  <div class="col-lg-4">
                                    <label class="control-header-label">Mobil</label>
                                  </div>
                                  <div class="col-lg-7">
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_telefon_mobil ? adatok.szerzodoAdatok.f_telefon_mobil : '-'}}</label>
                                  </div>
                                  <div class="col-lg-1">
                                    <button *ngIf="valtozasBejelentheto" mat-icon-button class="edit-button" matTooltip="Kommunikációs adatok - telefonszám változásának bejelentése" matTooltipPosition="above" (click)="onValtozasBejelentesClick('kommunikacios_adatok_telefonszam')">
                                      <mat-icon>edit</mat-icon>
                                    </button>
                                  </div>
                                  <div class="col-lg-4">
                                    <label class="control-header-label">Otthoni telefonszám</label>
                                  </div>
                                  <div class="col-lg-7">
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_telefon_otthon ? adatok.szerzodoAdatok.f_telefon_otthon : '-'}}</label>
                                  </div>
                                  <div class="col-lg-4">
                                    <label class="control-header-label">Munkahelyi telefonszám</label>
                                  </div>
                                  <div class="col-lg-7">
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_telefon_otthon ? adatok.szerzodoAdatok.f_telefon_munkahely : '-'}}</label>
                                  </div>
                                </div>
                                <mat-divider></mat-divider>
                              </mat-list-item>
                              <mat-list-item>
                                <div class="row w-100 align-items-center">
                                  <div class="col-lg-4">
                                    <label class="control-header-label">E-mail cím</label>
                                  </div>
                                  <div class="col-lg-7">
                                    <label class="control-data-label">{{adatok.szerzodoAdatok.f_email_cim ? adatok.szerzodoAdatok.f_email_cim : '-'}}</label>
                                  </div>
                                  <div class="col-lg-1">
                                    <button *ngIf="valtozasBejelentheto" mat-icon-button class="edit-button" matTooltip="Kommunikációs adatok - email cím változásának bejelentése" matTooltipPosition="above" (click)="onValtozasBejelentesClick('kommunikacios_adatok_email')">
                                      <mat-icon>edit</mat-icon>
                                    </button>
                                  </div>
                                </div>
                              </mat-list-item>
                            </mat-list>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </ng-container>

                    <!-- Biztositott adatai -->
                    <ng-container *ngIf="!valtozasBejelentesTipus && kivalasztottMenu.f_id == 'biztositott_adatai'">
                      <!-- Személy adatok -->
                      <div class="col-12">
                        <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                          <mat-card-header>
                            <mat-card-title class="content-title">
                              <div class="row align-items-center">
                                <div class="col-lg-12">
                                  <label class="mb-0">Személyes adatok</label>
                                </div>
                              </div>
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <div class="row">
                              <!-- Név minden esetben van -->
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Biztositott neve</label>
                                  <label class="control-data-label">{{adatok.biztositottAdatok.f_ugyfelnev ? adatok.biztositottAdatok.f_ugyfelnev : '-'}}</label>
                                </div>
                              </div>
                              <!-- Ha természetes személy (F vagy N), akkor születés helye, születési idő, anyja neve, születési név, jogosítvány kelte, egyéni vállalkozó-e -->
                              <ng-container *ngIf="adatok.biztositottAdatok.f_tipus == 'F' || adatok.biztositottAdatok.f_tipus == 'N'">
                                <div class="col-lg-3 col-md-4 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Születési hely</label>
                                    <label class="control-data-label">{{adatok.biztositottAdatok.f_szuletesi_hely ? adatok.biztositottAdatok.f_szuletesi_hely : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Születési dátum</label>
                                    <label class="control-data-label">{{adatok.biztositottAdatok.f_szuletesi_datum ? (adatok.biztositottAdatok.f_szuletesi_datum | dateFormat : 'YYYY. MMMM DD.') : '-'}}</label>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </ng-container>

                    <!-- Szerződés adatok -->
                    <ng-container *ngIf="kivalasztottMenu.f_id == 'szerzodes_adatok'">
                      <!-- Alap adatok -->
                      <div class="col-12">
                        <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                          <mat-card-header>
                            <mat-card-title class="content-title">
                              Alap adatok
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <div class="row">
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Szerződés azonosító</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_szerzodes_azonosito ? adatok.szerzodesAdatok.f_szerzodes_azonosito : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Módozat</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_modozat_nev ? adatok.szerzodesAdatok.f_modozat_nev : '-'}}</label>
                                </div>
                              </div>
                              <!-- Almódozat csak CASCO esetén jelenik meg -->
                              <div *ngIf="adatok.szerzodesAdatok.f_modozat_rovid_nev.indexOf('CASCO') > -1" class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Almódozat</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_almodozat_nev ? adatok.szerzodesAdatok.f_almodozat_nev : '-'}}</label>
                                </div>
                              </div>
                              <!-- Biztosítás típusa csak MFO esetén jelenik meg -->
                              <div *ngIf="biztositottTargyTipus == 'mfo'" class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Biztosítás típusa</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_mfo_biztositas_tipusa ? adatok.szerzodesAdatok.f_mfo_biztositas_tipusa : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Állapot</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_allapot ? adatok.szerzodesAdatok.f_allapot : '-'}}</label>
                                  <label class="control-data-label" *ngIf="adatok.szerzodesAdatok.f_allapot == 'SZÜNETELTETETT DÍJFIZETÉSŰ SZERZŐDÉS'">{{(adatok.szerzodesAdatok.f_szunet_kezd | dateFormat : 'YYYY.MM.DD')}} - {{(adatok.szerzodesAdatok.f_szunet_veg | dateFormat : 'YYYY.MM.DD')}}</label>
                                </div>
                              </div>
                              <div *ngIf="adatok.szerzodesAdatok.f_modozat_rovid_nev.indexOf('KGFB') > -1 || adatok.szerzodesAdatok.f_modozat_rovid_nev == 'F/CASCO'" class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Flotta azonosító</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_flotta_azon ? adatok.szerzodesAdatok.f_flotta_azon : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Kockázat kezdete</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_kockazat_kezdete ? (adatok.szerzodesAdatok.f_kockazat_kezdete | dateFormat : 'YYYY.MM.DD') : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Kockázat vége</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_kockazat_vege ? (adatok.szerzodesAdatok.f_kockazat_vege | dateFormat : 'YYYY.MM.DD') : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Törlés dátuma</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_szerzodes_vege ? (adatok.szerzodesAdatok.f_szerzodes_vege  | dateFormat : 'YYYY.MM.DD') : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Lejárat</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_szerzodes_lejarat ? adatok.szerzodesAdatok.f_szerzodes_lejarat : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Következő évforduló</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_kovetkezo_evfordulo ? adatok.szerzodesAdatok.f_kovetkezo_evfordulo : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Elvi kockázatvég</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_elvi_kockvege ? (adatok.szerzodesAdatok.f_elvi_kockvege | dateFormat : 'YYYY.MM.DD') : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Előjegyzett felmondás típusa</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_elojegyzett_felmondas_tipus ? adatok.szerzodesAdatok.f_elojegyzett_felmondas_tipus : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Előjegyzett felmondás hatálya</label>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_elojegyzett_felmondas_hataly ? adatok.szerzodesAdatok.f_elojegyzett_felmondas_hataly : '-'}}</label>
                                </div>
                              </div>
                              <!-- Csak CASCO esetén van önrész -->
                              <ng-container *ngIf="adatok.szerzodesAdatok.f_modozat_rovid_nev.indexOf('CASCO') > -1">
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Önrész százalék</label>
                                    <label class="control-data-label">{{adatok.szerzodesAdatok.f_onresz_szazalek ? adatok.szerzodesAdatok.f_onresz_szazalek : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Önrész minimum</label>
                                    <label class="control-data-label">{{adatok.szerzodesAdatok.f_onresz_minimum ? adatok.szerzodesAdatok.f_onresz_minimum : '-'}}</label>
                                  </div>
                                </div>
                              </ng-container>
                              <!-- Csak KGFB vagy CASCO estén van bónusz -->
                              <ng-container *ngIf="biztositottTargyTipus == 'gepjarmu'">
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">B/M fokozat</label>
                                    <label class="control-data-label">{{adatok.szerzodesAdatok.f_bonusz ? adatok.szerzodesAdatok.f_bonusz : '-'}}</label>
                                  </div>
                                </div>
                              </ng-container>
                              <!-- Digikár csak MFO esetén jelenik meg -->
                              <ng-container *ngIf="biztositottTargyTipus == 'mfo'">
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Digikár</label>
                                    <label class="control-data-label">{{adatok.szerzodesAdatok.f_mfo_digikar == 'I' ? 'Igen' : 'Nem'}}</label>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="control-group">
                                    <label class="control-header-label">Kiegészítő biztosítások</label>
                                    <label class="control-data-label">{{adatok.kiegeszitoBiztositasokMfo.f_modnev ? adatok.kiegeszitoBiztositasokMfo.f_modnev : '-'}}</label>                                    
                                  </div>
                                </div>
                              </ng-container>
                              <!-- Csak Otthon esetén jelenik meg -->
                              <ng-container *ngIf="biztositottTargyTipus == 'lakas'">
                                <div class="col-12">
                                  <div class="control-group">
                                    <label class="control-header-label">Kiegészítő biztosítások</label>
                                    <label class="control-data-label">{{adatok.kiegeszitoBiztositasokOtthon.f_modnev ? adatok.kiegeszitoBiztositasokOtthon.f_modnev : '-'}}</label>
                                  </div>
                                </div>
                              </ng-container>


                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <!-- Díj adatok -->
                      <div class="col-12">
                        <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                          <mat-card-header>
                            <mat-card-title class="content-title">
                              Díj adatok
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <div class="row">
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Állománydíj</label>
                                  <label class="control-data-label">{{adatok.dijAdatok.f_allomany_dij_osszesen ? (adatok.dijAdatok.f_allomany_dij_osszesen + ' ' + adatok.dijAdatok.f_penznem) : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <div class="control-header-wrapper">
                                    <label class="control-header-label mr-1">Díjfizetési mód</label>
                                    <!--<button mat-icon-button class="edit-button" matTooltip="Díjfizetési mód változásának bejelentése" matTooltipPosition="above" (click)="onValtozasBejelentesClick('')">
                                      <mat-icon>edit</mat-icon>
                                    </button>-->
                                  </div>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_dijfizetes_mod ? adatok.szerzodesAdatok.f_dijfizetes_mod : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <div class="control-header-wrapper">
                                    <label class="control-header-label mr-1">Díjfizetési gyakoriság</label>
                                    <!--<button mat-icon-button class="edit-button" matTooltip="Díjfizetési gyakoriság változásának bejelentése" matTooltipPosition="above" (click)="onValtozasBejelentesClick('')">
                                      <mat-icon>edit</mat-icon>
                                    </button>-->
                                  </div>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_dijfizetes_gyakorisag ? adatok.szerzodesAdatok.f_dijfizetes_gyakorisag : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <div class="control-header-wrapper">
                                    <label class="control-header-label mr-1">Bankszámlaszám</label>
                                    <!--<button mat-icon-button class="edit-button" matTooltip="Bankszámlaszám változásának bejelentése" matTooltipPosition="above" (click)="onValtozasBejelentesClick('')">
                                      <mat-icon>edit</mat-icon>
                                    </button>-->
                                  </div>
                                  <label class="control-data-label">{{adatok.szerzodesAdatok.f_bankszamlaszam ? adatok.szerzodesAdatok.f_bankszamlaszam : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Baleseti adó elvárás</label>
                                  <label class="control-data-label">{{adatok.dijAdatok.f_adoelvaras ? adatok.dijAdatok.f_adoelvaras : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Fizetendő egyenleg / Túlfizetés</label>
                                  <label class="control-data-label">{{adatok.dijAdatok.f_egyenleg_string ?  adatok.dijAdatok.f_egyenleg_string : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Díjrendezettség dátuma</label>
                                  <label class="control-data-label">{{adatok.dijAdatok.f_dijrendezettseg ? adatok.dijAdatok.f_dijrendezettseg : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Index díj</label>
                                  <label class="control-data-label">{{adatok.dijAdatok.f_index_dij ? adatok.dijAdatok.f_index_dij : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Következő gyakoriság kezdete</label>
                                  <label class="control-data-label">{{adatok.dijAdatok.f_kovetkezo_gyakorisag_kezdete ? adatok.dijAdatok.f_kovetkezo_gyakorisag_kezdete : '-'}}</label>
                                </div>
                              </div>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </ng-container>

                    <!-- Előzmény adatok -->
                    <ng-container *ngIf="!valtozasBejelentesTipus && kivalasztottMenu.f_id == 'bonusz_elozmeny_adatok'">

                      <div class="col-12">
                        <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                          <mat-card-header>
                            <mat-card-title class="content-title">
                              <div class="row align-items-center">
                                <div class="col-lg-12">
                                  <label class="mb-0">Bónusz előzmény adatok</label>
                                  <button *ngIf="valtozasBejelentheto && adatok.szerzodesAdatok.f_allapot_kod != 62" mat-icon-button class="edit-button" matTooltip="Bónusz előzmény változásának bejelentése" matTooltipPosition="above" (click)="onValtozasBejelentesClick('bonusz_elozmeny_modositas')">
                                    <mat-icon>edit</mat-icon>
                                  </button>
                                </div>
                              </div>
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <div class="row">
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Biztosító</label>
                                  <label class="control-data-label">{{(adatok.elozmenyAdatok && adatok.elozmenyAdatok.f_biztosito) ? adatok.elozmenyAdatok.f_biztosito : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Kötvényszám</label>
                                  <label class="control-data-label">{{(adatok.elozmenyAdatok && adatok.elozmenyAdatok.f_kotvenyszam) ? adatok.elozmenyAdatok.f_kotvenyszam : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Rendszám</label>
                                  <label class="control-data-label">{{(adatok.elozmenyAdatok && adatok.elozmenyAdatok.f_rendszam) ? adatok.elozmenyAdatok.f_rendszam : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Megszünés oka</label>
                                  <label class="control-data-label">{{(adatok.elozmenyAdatok && adatok.elozmenyAdatok.f_megszunes_oka) ? adatok.elozmenyAdatok.f_megszunes_oka : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Szerződés kezdete</label>
                                  <label class="control-data-label">{{(adatok.elozmenyAdatok && adatok.elozmenyAdatok.f_szerzodes_kezdete) ? adatok.elozmenyAdatok.f_szerzodes_kezdete : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Szerződés vége</label>
                                  <label class="control-data-label">{{(adatok.elozmenyAdatok && adatok.elozmenyAdatok.f_szerzodes_vege) ? adatok.elozmenyAdatok.f_szerzodes_vege : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Záró bonusz</label>
                                  <label class="control-data-label">{{(adatok.elozmenyAdatok && adatok.elozmenyAdatok.f_zaro_bonusz) ? adatok.elozmenyAdatok.f_zaro_bonusz : '-'}}</label>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                  <label class="control-header-label">Típus</label>
                                  <label class="control-data-label">{{(adatok.elozmenyAdatok && adatok.elozmenyAdatok.f_tipus) ? adatok.elozmenyAdatok.f_tipus : '-'}}</label>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-2 mb-3">
                              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <button mat-raised-button type="button" color="accent" (click)="onValtozasBejelentesClick('bonusz_elozmeny_uj')">Új bónusz előzmény megadása</button>
                              </div>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </ng-container>

                    <!-- Biztosított tárgy adatai -->
                    <ng-container *ngIf="!valtozasBejelentesTipus && kivalasztottMenu.f_id == 'biztositott_targy_adatai'">
                      <!-- Gépjármű -->
                      <ng-container *ngIf="biztositottTargyTipus == 'gepjarmu'">
                        <div class="col-12">
                          <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                            <mat-card-header>
                              <mat-card-title class="content-title">
                                Alap adatok
                              </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <mat-list>
                                <mat-list-item>
                                  <div class="row w-100 align-items-center">
                                    <div class="col-lg-4">
                                      <label class="control-header-label">Rendszám</label>
                                    </div>
                                    <div class="col-lg-7">
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_rendszam ? adatok.biztositottTargyGfbCasco.f_rendszam : '-'}}</label>
                                    </div>
                                    <div class="col-lg-1">
                                      <button *ngIf="valtozasBejelentheto" mat-icon-button class="edit-button" matTooltip="Rendszám változásának bejelentése" matTooltipPosition="above" (click)="onValtozasBejelentesClick('rendszam')">
                                        <mat-icon>edit</mat-icon>
                                      </button>
                                    </div>
                                  </div>
                                  <mat-divider></mat-divider>
                                </mat-list-item>
                                <mat-list-item>
                                  <div class="row w-100 align-items-center">
                                    <div class="col-lg-4">
                                      <label class="control-header-label">Alvázszám</label>
                                    </div>
                                    <div class="col-lg-7">
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_alvazszam ? adatok.biztositottTargyGfbCasco.f_alvazszam : '-'}}</label>
                                    </div>
                                    <div class="col-lg-1">
                                      <button *ngIf="valtozasBejelentheto" mat-icon-button class="edit-button" matTooltip="Alvázszám módosítása" matTooltipPosition="above" (click)="onValtozasBejelentesClick('alvazszam')">
                                        <mat-icon>edit</mat-icon>
                                      </button>
                                    </div>
                                  </div>
                                  <mat-divider></mat-divider>
                                </mat-list-item>
                                <mat-list-item>
                                  <div class="row w-100 align-items-center">
                                    <div class="col-lg-4">
                                      <label class="control-header-label">Forgalmi engedély szám</label>
                                    </div>
                                    <div class="col-lg-7">
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_forgalmi_engedely_szam ? adatok.biztositottTargyGfbCasco.f_forgalmi_engedely_szam : '-'}}</label>
                                    </div>
                                    <div class="col-lg-1">
                                      <button *ngIf="valtozasBejelentheto" mat-icon-button class="edit-button" matTooltip="Forgalmi engedély szám módosítása" matTooltipPosition="above" (click)="onValtozasBejelentesClick('forgalmi_engedely_szam')">
                                        <mat-icon>edit</mat-icon>
                                      </button>
                                    </div>
                                  </div>
                                </mat-list-item>
                              </mat-list>
                            </mat-card-content>
                          </mat-card>
                        </div>

                        <div class="col-12">
                          <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                            <mat-card-header>
                              <mat-card-title class="content-title">
                                <div class="row align-items-center">
                                  <div class="col-lg-12">
                                    <label class="mb-0">Egyéb adatok</label>
                                    <button *ngIf="valtozasBejelentheto" mat-icon-button class="edit-button" matTooltip="Egyéb autóadat változásának bejelentése" matTooltipPosition="above" (click)="onValtozasBejelentesClick('egyeb_autoadat')">
                                      <mat-icon>edit</mat-icon>
                                    </button>
                                  </div>
                                </div>
                              </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Gyártmány</label>
                                    <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_gyartmany ? adatok.biztositottTargyGfbCasco.f_gyartmany : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Típus</label>
                                    <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_tipus ? adatok.biztositottTargyGfbCasco.f_tipus : '-'}}</label>
                                  </div>
                                </div>
                                <!-- Köbcenti, motorszám, kw csak KGFB esetén jelenik meg -->
                                <ng-container *ngIf="adatok.szerzodesAdatok.f_modozat_rovid_nev.substring(0, 4) == 'KGFB'">
                                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <div class="control-group">
                                      <label class="control-header-label">Cm3</label>
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_henger ? adatok.biztositottTargyGfbCasco.f_henger : '-'}}</label>
                                    </div>
                                  </div>
                                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <div class="control-group">
                                      <label class="control-header-label">Motorszám</label>
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_motorszam ? adatok.biztositottTargyGfbCasco.f_motorszam : '-'}}</label>
                                    </div>
                                  </div>
                                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <div class="control-group">
                                      <label class="control-header-label">Kw</label>
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_kw ? adatok.biztositottTargyGfbCasco.f_kw : '-'}}</label>
                                    </div>
                                  </div>
                                </ng-container>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Gyártási év</label>
                                    <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_gyartasi_ev ? adatok.biztositottTargyGfbCasco.f_gyartasi_ev : '-'}}</label>
                                  </div>
                                </div>
                                <!-- Forgalomba lépés és jármű értéke csak CASCO esetén jelenik meg -->
                                <ng-container *ngIf="adatok.szerzodesAdatok.f_modozat_rovid_nev.indexOf('CASCO') !== -1">
                                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <div class="control-group">
                                      <label class="control-header-label">Forgalomba lépés dátuma</label>
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_forgalomba_lepes_datuma ? (adatok.biztositottTargyGfbCasco.f_forgalomba_lepes_datuma | dateFormat : 'YYYY.MM.DD') : '-'}}</label>
                                    </div>
                                  </div>
                                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <div class="control-group">
                                      <label class="control-header-label">Jármű értéke</label>
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_jarmu_erteke ? adatok.biztositottTargyGfbCasco.f_jarmu_erteke : '-'}}</label>
                                    </div>
                                  </div>
                                </ng-container>
                                <!-- Saját tömeg csak KGFB esetén jelenik meg -->
                                <ng-container *ngIf="adatok.szerzodesAdatok.f_modozat_rovid_nev.substring(0, 4) == 'KGFB'">
                                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <div class="control-group">
                                      <label class="control-header-label">Saját tömeg</label>
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_sajat_tomeg ? adatok.biztositottTargyGfbCasco.f_sajat_tomeg : '-'}}</label>
                                    </div>
                                  </div>
                                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <div class="control-group">
                                      <label class="control-header-label">Megengedett tömeg</label>
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_megengedett_tomeg ? adatok.biztositottTargyGfbCasco.f_megengedett_tomeg : '-'}}</label>
                                    </div>
                                  </div>
                                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <div class="control-group">
                                      <label class="control-header-label">Üzemeltetés jellege</label>
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_uzemjelleg ? adatok.biztositottTargyGfbCasco.f_uzemjelleg : '-'}}</label>
                                    </div>
                                  </div>
                                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <div class="control-group">
                                      <label class="control-header-label">Motor üzemmódja</label>
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_uzemmod ? adatok.biztositottTargyGfbCasco.f_uzemmod : '-'}}</label>
                                    </div>
                                  </div>
                                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <div class="control-group">
                                      <label class="control-header-label">Utas szám</label>
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_utasszam ? adatok.biztositottTargyGfbCasco.f_utasszam : '-'}}</label>
                                    </div>
                                  </div>
                                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <div class="control-group">
                                      <label class="control-header-label">Jelleg név</label>
                                      <label class="control-data-label">{{adatok.biztositottTargyGfbCasco.f_jellegnev ? adatok.biztositottTargyGfbCasco.f_jellegnev : '-'}}</label>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>


                      </ng-container>
                      <!-- Otthon -->
                      <ng-container *ngIf="biztositottTargyTipus == 'lakas'">

                        <div class="col-12">
                          <mat-card class="form-content-2 mb-3 pt-2 pb-0 border-none">
                            <mat-card-header>
                              <mat-card-title class="content-title">
                                Épületetek
                              </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-lg-12 pb-3">
                                  <granit-table #biztositottTargyOtthonEpuletekTabla
                                                 [columns]="biztositottTargyOtthonEpuletekOszlopok"
                                                 [formGroup]="biztositottTargyOtthonEpuletekFormGroup"
                                                 [isPageable]="false">
                                  </granit-table>
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>

                        <div class="col-12">
                          <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                            <mat-card-header>
                              <mat-card-title class="content-title">
                                Ingóságok
                              </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Általános háztartási ingóságok</label>
                                    <label class="control-data-label">{{adatok.biztositottTargyOtthon.ingo ? adatok.biztositottTargyOtthon.ingo : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Kiemelt értékű háztartási ingóságok</label>
                                    <label class="control-data-label">{{adatok.biztositottTargyOtthon.kiemelt ? adatok.biztositottTargyOtthon.kiemelt : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Értékőrző ingóságok</label>
                                    <label class="control-data-label">{{adatok.biztositottTargyOtthon.ertekorzo ? adatok.biztositottTargyOtthon.ertekorzo : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Vállalkozás eszközei</label>
                                    <label class="control-data-label">{{adatok.biztositottTargyOtthon.vallalkozas ? adatok.biztositottTargyOtthon.vallalkozas : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Készpénz</label>
                                    <label class="control-data-label">{{adatok.biztositottTargyOtthon.keszpenz ? adatok.biztositottTargyOtthon.keszpenz : '-'}}</label>
                                  </div>
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>

                        <!--<div class="col-lg-4">
                          <div class="control-group">
                            <label class="control-header-label">Kockázat helye</label>
                            <label class="control-data-label">{{adatok.biztositottTargyOtthon.kockhely ? adatok.biztositottTargyOtthon.kockhely : '-'}}</label>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="control-group">
                            <label class="control-header-label">Biztosítási összeg ingatlan</label>
                            <label class="control-data-label">{{adatok.biztositottTargyOtthon.ingatlan ? adatok.biztositottTargyOtthon.ingatlan : '-'}}</label>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="control-group">
                            <label class="control-header-label">Biztosítási összeg ingóság</label>
                            <label class="control-data-label">{{adatok.biztositottTargyOtthon.ingo ? adatok.biztositottTargyOtthon.ingo : '-'}}</label>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="control-group">
                            <label class="control-header-label">Biztosítási összeg kiemelt</label>
                            <label class="control-data-label">{{adatok.biztositottTargyOtthon.kiemelt ? adatok.biztositottTargyOtthon.kiemelt : '-'}}</label>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="control-group">
                            <label class="control-header-label">Biztosítási összeg értékörző</label>
                            <label class="control-data-label">{{adatok.biztositottTargyOtthon.ertekorzo ? adatok.biztositottTargyOtthon.ertekorzo : '-'}}</label>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="control-group">
                            <label class="control-header-label">Biztosítási összeg készpénz</label>
                            <label class="control-data-label">{{adatok.biztositottTargyOtthon.keszpenz ? adatok.biztositottTargyOtthon.keszpenz : '-'}}</label>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="control-group">
                            <label class="control-header-label">Biztosítási összeg vállalkozás</label>
                            <label class="control-data-label">{{adatok.biztositottTargyOtthon.vallalkozas ? adatok.biztositottTargyOtthon.vallalkozas : '-'}}</label>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="control-group">
                            <label class="control-header-label">Nm2</label>
                            <label class="control-data-label">{{adatok.biztositottTargyOtthon.nm_ossz ? adatok.biztositottTargyOtthon.nm_ossz : '-'}}</label>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="control-group">
                            <label class="control-header-label">Kiegészítő biztosítási összeg</label>
                            <label class="control-data-label">{{adatok.biztositottTargyOtthon.kieg ? adatok.biztositottTargyOtthon.kieg : '-'}}</label>
                          </div>
                        </div>-->
                      </ng-container>

                      <!-- MFO -->
                      <ng-container *ngIf="biztositottTargyTipus == 'mfo'">
                        <div class="col-12">
                          <mat-card class="form-content-2 mb-3 pt-2 pb-0 border-none">
                            <mat-card-header>
                              <mat-card-title class="content-title">
                                Épületetek
                              </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-lg-12 pb-3">
                                  <granit-table #biztositottTargyMfoEpuletekTabla
                                                 [columns]="biztositottTargyMfoEpuletekOszlopok"
                                                 [formGroup]="biztositottTargyMfoEpuletekFormGroup"
                                                 [isPageable]="false">
                                  </granit-table>
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>

                        <div class="col-12">
                          <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                            <mat-card-header>
                              <mat-card-title class="content-title">
                                Általános háztartási ingóságok
                              </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-lg-12 pb-3">
                                  <granit-table #biztositottTargyMfoIngosagokTabla
                                                 [columns]="biztositottTargyMfoIngosagokOszlopok"
                                                 [formGroup]="biztositottTargyMfoIngosagokFormGroup"
                                                 [isPageable]="false">
                                  </granit-table>
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>

                        <div class="col-12">
                          <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                            <mat-card-header>
                              <mat-card-title class="content-title">
                                Értéktárgyak
                              </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-lg-12 pb-3">
                                  <granit-table #biztositottTargyMfoErtektargyakTabla
                                                 [columns]="biztositottTargyMfoErtektargyakOszlopok"
                                                 [formGroup]="biztositottTargyMfoErtektargyakFormGroup"
                                                 [isPageable]="false">
                                  </granit-table>
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>




                      </ng-container>


                      <!-- CMR -->
                      <ng-container *ngIf="biztositottTargyTipus == 'cmr'">
                        <div class="col-lg-3">
                          <div class="control-group">
                            <label class="control-header-label">Biztosítási összeg</label>
                            <label class="control-data-label">{{adatok.biztositottTargyCmr.F_CMR_BIZTOSSZEG ? (adatok.biztositottTargyCmr.F_CMR_BIZTOSSZEG + ' ' + adatok.biztositottTargyCmr.F_RNEV) : '-'}}</label>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="control-group">
                            <label class="control-header-label">Vontató rendszámok</label>
                            <label class="control-data-label">{{adatok.biztositottTargyCmr.F_RENDSZAM1 ? adatok.biztositottTargyCmr.F_RENDSZAM1 : '-'}}</label>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="control-group">
                            <label class="control-header-label">Pótkocsi rendszámok</label>
                            <label class="control-data-label">{{adatok.biztositottTargyCmr.F_RENDSZAM2 ? adatok.biztositottTargyCmr.F_RENDSZAM2 : '-'}}</label>
                          </div>
                        </div>
                      </ng-container>
                      <!-- KAF -->
                      <ng-container *ngIf="biztositottTargyTipus == 'kaf'">
                        <div class="col-lg-3">
                          <div class="control-group">
                            <label class="control-header-label">Biztosítási összeg</label>
                            <label class="control-data-label">{{adatok.biztositottTargyKaf.F_BIZTOSSZEG ? (adatok.biztositottTargyKaf.F_BIZTOSSZEG + ' ' + adatok.biztositottTargyKaf.F_RNEV) : '-'}}</label>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="control-group">
                            <label class="control-header-label">Vontató rendszámok</label>
                            <label class="control-data-label">{{adatok.biztositottTargyKaf.F_RENDSZAM1 ? adatok.biztositottTargyKaf.F_RENDSZAM1 : '-'}}</label>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="control-group">
                            <label class="control-header-label">Pótkocsi rendszámok</label>
                            <label class="control-data-label">{{adatok.biztositottTargyKaf.F_RENDSZAM2 ? adatok.biztositottTargyKaf.F_RENDSZAM2 : '-'}}</label>
                          </div>
                        </div>
                      </ng-container>
                      <!-- KSZF -->
                      <ng-container *ngIf="biztositottTargyTipus == 'kszf'">
                        <div class="col-lg-3">
                          <div class="control-group">
                            <label class="control-header-label">Biztosítási összeg</label>
                            <label class="control-data-label">{{adatok.biztositottTargyKszf.F_BIZTOSSZEG ? (adatok.biztositottTargyKszf.F_BIZTOSSZEG + ' ' + adatok.biztositottTargyKszf.F_RNEV) : '-'}}</label>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="control-group">
                            <label class="control-header-label">Vontató rendszámok</label>
                            <label class="control-data-label">{{adatok.biztositottTargyKszf.F_RENDSZAM1 ? adatok.biztositottTargyKszf.F_RENDSZAM1 : '-'}}</label>
                          </div>
                        </div>
                      </ng-container>
                      <!-- Utas -->
                      <ng-container *ngIf="biztositottTargyTipus == 'utas'">
                        <div class="col-lg-3">
                          <div class="control-group">
                            <label class="control-header-label">Biztosítottak</label>
                            <label class="control-data-label">{{adatok.biztositottTargyUtas.F_UGYFELNEV ? adatok.biztositottTargyUtas.F_UGYFELNEV : '-'}}</label>
                          </div>
                        </div>
                      </ng-container>
                      <!-- Gumi -->
                      <ng-container *ngIf="biztositottTargyTipus == 'gumi'">
                        <div class="col-lg-12">
                          <granit-table #biztositottTargyGumiTabla
                                         [columns]="biztositottTargyGumiOszlopok"
                                         [formGroup]="biztositottTargyGumiFormGroup">
                          </granit-table>
                        </div>
                      </ng-container>
                    </ng-container>

                    <!-- Kockázatviselési hely -->
                    <ng-container *ngIf="kivalasztottMenu.f_id == 'kockazatviselesi_hely'">

                      <div class="col-12">
                        <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                          <mat-card-header>
                            <mat-card-title class="content-title">
                              Kockázatviselési hely
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>

                            <!-- Otthon -->
                            <ng-container *ngIf="biztositottTargyTipus == 'lakas'">
                              <div class="row">
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Cím</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyOtthon.f_cim ? adatok.kockazatviselesiHelyOtthon.f_cim : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Épület típusa</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyOtthon.f_ingatlan_tipus ? adatok.kockazatviselesiHelyOtthon.f_ingatlan_tipus : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Építés éve</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyOtthon.f_epites_eve ? adatok.kockazatviselesiHelyOtthon.f_epites_eve : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Lakók száma</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyOtthon.f_lakok_szama ? adatok.kockazatviselesiHelyOtthon.f_lakok_szama : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Ingatlan hely</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyOtthon.f_ingatlan_hely ? adatok.kockazatviselesiHelyOtthon.f_ingatlan_hely : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Építés alatt</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyOtthon.f_epites_alatt == 'I' ? 'Igen' : 'Nem'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Tulajdonviszony</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyOtthon.f_tulajdon_viszony ? adatok.kockazatviselesiHelyOtthon.f_tulajdon_viszony : '-'}}</label>
                                  </div>
                                </div>
                              </div>
                            </ng-container>

                            <!-- MFO -->
                            <ng-container *ngIf="biztositottTargyTipus == 'mfo'">
                              <div class="row">
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Cím</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyMfo.f_cim ? adatok.kockazatviselesiHelyMfo.f_cim : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Épület típusa</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyMfo.f_ingatlan_tipus ? adatok.kockazatviselesiHelyMfo.f_ingatlan_tipus : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Építés éve</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyMfo.f_epites_eve ? adatok.kockazatviselesiHelyMfo.f_epites_eve : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Lakók száma</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyMfo.f_lakok_szama ? adatok.kockazatviselesiHelyMfo.f_lakok_szama : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Ingatlan hely</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyMfo.f_ingatlan_hely ? adatok.kockazatviselesiHelyMfo.f_ingatlan_hely : '-'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Építés alatt</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyMfo.f_epites_alatt == 'I' ? 'Igen' : 'Nem'}}</label>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                  <div class="control-group">
                                    <label class="control-header-label">Tulajdonviszony</label>
                                    <label class="control-data-label">{{adatok.kockazatviselesiHelyMfo.f_tulajdon_viszony ? adatok.kockazatviselesiHelyMfo.f_tulajdon_viszony : '-'}}</label>
                                  </div>
                                </div>
                              </div>
                            </ng-container>

                            <!-- Gumi -->
                            <ng-container *ngIf="biztositottTargyTipus == 'gumi'">
                              <div class="col-lg-12">
                                <granit-table #kockazatviselesHelyGumiTabla
                                               [columns]="kockazatviselesHelyGumiOszlopok"
                                               [formGroup]="kockazatviselesHelyGumiFormGroup">
                                </granit-table>
                              </div>
                            </ng-container>

                          </mat-card-content>
                        </mat-card>
                      </div>

                    </ng-container>

                    <!-- Kapcsolódó önrészek -->
                    <ng-container *ngIf="kivalasztottMenu.f_id == 'kapcsolodo_onreszek'">
                      <div class="col-lg-12">
                        <granit-table #onreszGumiTabla
                                       [columns]="onreszGumiOszlopok"
                                       [formGroup]="onreszGumiFormGroup">
                        </granit-table>
                      </div>
                    </ng-container>


                    <!-- Kapcsolódó záradékok -->
                    <ng-container *ngIf="kivalasztottMenu.f_id == 'kapcsolodo_zaradekok'">
                      <ng-container *ngIf="biztositottTargyTipus == 'gumi'">
                        <div class="col-lg-12">
                          <granit-table #zaradekGumiTabla
                                         [columns]="zaradekGumiOszlopok"
                                         [formGroup]="zaradekGumiFormGroup">
                          </granit-table>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="biztositottTargyTipus == 'mfo'">
                        <div class="col-lg-12">
                          <granit-table #zaradekMfoTabla
                                         [columns]="zaradekMfoOszlopok"
                                         [formGroup]="zaradekMfoFormGroup">
                          </granit-table>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="biztositottTargyTipus == 'lakas'">
                        <div class="col-lg-12">
                          <granit-table #zaradekOtthonTabla
                                         [columns]="zaradekOtthonOszlopok"
                                         [formGroup]="zaradekOtthonFormGroup">
                          </granit-table>
                        </div>
                      </ng-container>
                    </ng-container>

                    <!-- Kárkifizeztések -->
                    <ng-container *ngIf="kivalasztottMenu.f_id == 'karkifizetesek'">
                      <div class="col-lg-12">
                        <granit-table #karAdatokTabla
                                       [columns]="karAdatokOszlopok"
                                       [formGroup]="karAdatokFormGroup">
                        </granit-table>
                      </div>
                    </ng-container>

                    <!-- Engedményezési adatok -->
                    <ng-container *ngIf="!valtozasBejelentesTipus && kivalasztottMenu.f_id == 'engedmenyezesi_adatok'">
                      <div class="col-lg-12">
                        <granit-table #engedmenyezesiAdatokTabla
                                       [columns]="engedmenyezesiAdatokOszlopok"
                                       [formGroup]="engedmenyezesiAdatokFormGroup"
                                       [isEditable]="true"
                                       [editMode]="'form'"
                                       (rowEditClick)="onEngedmenyezesiAdatRowEditClick($event)">
                        </granit-table>
                      </div>
                    </ng-container>

                    <!-- Változás bejelentés -->
                    <ng-container *ngIf="valtozasBejelentesTipus">
                      <div class="col-lg-12">
                        <mat-card class="form-content">
                          <mat-card-header *ngIf="valtozasBejelentesTipus.f_nev">
                            <mat-card-title class="content-title">{{valtozasBejelentesTipus.f_nev}}</mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <form [formGroup]="valtozasBejelentesFormGroup" class="form-group mt-0">

                              <!-- Személy adatok módosítása -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'szemely_adatok'">
                                <div class="row">
                                  <!-- Név minden esetben van -->
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Szerződő neve</mat-label>
                                      <input matInput formControlName="f_ugyfelnev" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                  <!-- Ha természetes személy (F vagy N), akkor születés helye, születési idő, anyja neve, születési név, jogosítvány kelte, egyéni vállalkozó-e -->
                                  <ng-container *ngIf="adatok.szerzodoAdatok.f_tipus == 'F' || adatok.szerzodoAdatok.f_tipus == 'N'">
                                    <div class="col-lg-4">
                                      <mat-form-field appearance="fill">
                                        <mat-label>Születési hely</mat-label>
                                        <input matInput formControlName="f_szuletesi_hely" autocomplete="off">
                                      </mat-form-field>
                                    </div>
                                    <div class="col-lg-4">
                                      <mat-form-field appearance="fill">
                                        <mat-label>Születési dátum</mat-label>
                                        <input matInput formControlName="f_szuletesi_datum" [matDatepicker]="szuletesiDatumDatePicker">
                                        <mat-datepicker-toggle matSuffix [for]="szuletesiDatumDatePicker"></mat-datepicker-toggle>
                                        <mat-datepicker #szuletesiDatumDatePicker></mat-datepicker>
                                      </mat-form-field>
                                    </div>
                                    <div class="col-lg-4">
                                      <mat-form-field appearance="fill">
                                        <mat-label>Anyja neve</mat-label>
                                        <input matInput formControlName="f_anyja_neve" autocomplete="off">
                                      </mat-form-field>
                                    </div>
                                    <div class="col-lg-4">
                                      <mat-form-field appearance="fill">
                                        <mat-label>Születéskori név</mat-label>
                                        <input matInput formControlName="f_szuleteskori_nev" autocomplete="off">
                                      </mat-form-field>
                                    </div>
                                    <div class="col-lg-4">
                                      <mat-form-field appearance="fill">
                                        <mat-label>Jogosítvány kelte</mat-label>
                                        <input matInput formControlName="f_jogositvany_kelte" [matDatepicker]="jogositvanyKelteDatePicker">
                                        <mat-datepicker-toggle matSuffix [for]="jogositvanyKelteDatePicker"></mat-datepicker-toggle>
                                        <mat-datepicker #jogositvanyKelteDatePicker></mat-datepicker>
                                      </mat-form-field>
                                    </div>
                                    <div class="col-lg-4">
                                      <mat-form-field appearance="fill">
                                        <mat-label>Egyéni vállalkozó</mat-label>
                                        <mat-select formControlName="f_vallalkozo">
                                          <mat-option value="I">Igen</mat-option>
                                          <mat-option value="N">Nem</mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                  </ng-container>
                                  <!-- Ha jogi személy vagy vállalkozó és a biztosított tárgy típus nem mfo, akkor kell adószám és cégjegyzék szám-->
                                  <ng-container *ngIf="(adatok.szerzodoAdatok.f_tipus == 'J' || valtozasBejelentesFormGroup.controls['f_vallalkozo'].value == 'I') && biztositottTargyTipus != 'mfo'">
                                    <div class="col-lg-4">
                                      <mat-form-field appearance="fill">
                                        <mat-label>Adószám</mat-label>
                                        <input matInput formControlName="f_adoszam" autocomplete="off">
                                        <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_adoszam', 'required')">
                                          <mat-icon>error</mat-icon>
                                          Kérjük, adja meg az adószámát!
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                    <!-- Csak jogi személy esetén van teaor szám is -->
                                    <ng-container *ngIf="adatok.szerzodoAdatok.f_tipus == 'J'">
                                      <div class="col-lg-4">
                                        <mat-form-field appearance="fill">
                                          <mat-label>Teaor szám</mat-label>
                                          <input matInput formControlName="f_teaor" autocomplete="off">
                                          <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_teaor', 'required')">
                                            <mat-icon>error</mat-icon>
                                            Kérjük, adja meg a teaor számát!
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                    </ng-container>
                                    <div class="col-lg-4">
                                      <mat-form-field appearance="fill">
                                        <mat-label>{{adatok.szerzodoAdatok.f_tipus == 'J' ? 'Cégjegyzék szám' : 'Nyilvántartási szám'}}</mat-label>
                                        <input matInput formControlName="f_cegjegyzekszam" autocomplete="off">
                                        <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_cegjegyzekszam', 'required')">
                                          <mat-icon>error</mat-icon>
                                          Kérjük, adja meg a {{adatok.szerzodoAdatok.f_tipus == 'J' ? 'cégjegyzék számát' : 'nyilvántartási számát'}}!
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                  </ng-container>
                                </div>
                              </ng-container>

                              <!-- Állandó lakcím módosítása -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'allando_lakcim'">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Irányítószám</mat-label>
                                      <input matInput formControlName="f_iranyitoszam" mask="0000" required autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_iranyitoszam', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg az irányítószámot!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Település</mat-label>
                                      <mat-select formControlName="f_telepules" required>
                                        <mat-option *ngFor="let telepules of telepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_telepules', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a települést!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Utcanév</mat-label>
                                      <mat-select formControlName="f_utcanev" required>
                                        <mat-option *ngFor="let utcanev of utcanevLista" [value]="utcanev.f_utcanev">{{utcanev.f_utcanev}}</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_utcanev', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg az utcanevet!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Úttípus</mat-label>
                                      <mat-select formControlName="f_uttipus" required>
                                        <mat-option *ngFor="let uttipus of uttipusLista" [value]="uttipus.f_uttipus">{{uttipus.f_uttipus}}</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_uttipus', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg az úttípust!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Házszám</mat-label>
                                      <input matInput formControlName="f_hazszam" required autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_hazszam', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a házszámot!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Emelet</mat-label>
                                      <input matInput formControlName="f_emelet" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Ajtó</mat-label>
                                      <input matInput formControlName="f_ajto" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Levelelezési cím -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'levelezesi_cim'">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Irányítószám</mat-label>
                                      <input matInput formControlName="f_levelezesi_iranyitoszam" mask="0000" required autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_levelezesi_iranyitoszam', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg az irányítószámot!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Település</mat-label>
                                      <mat-select formControlName="f_levelezesi_telepules" required>
                                        <mat-option *ngFor="let telepules of telepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_levelezesi_telepules', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a települést!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Utcanév</mat-label>
                                      <mat-select formControlName="f_levelezesi_utcanev" required>
                                        <mat-option *ngFor="let utcanev of utcanevLista" [value]="utcanev.f_utcanev">{{utcanev.f_utcanev}}</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_levelezesi_utcanev', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg az utcanevet!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Úttípus</mat-label>
                                      <mat-select formControlName="f_levelezesi_uttipus" required>
                                        <mat-option *ngFor="let uttipus of uttipusLista" [value]="uttipus.f_uttipus">{{uttipus.f_uttipus}}</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_levelezesi_uttipus', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg az úttípust!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Házszám</mat-label>
                                      <input matInput formControlName="f_levelezesi_hazszam" required autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_levelezesi_hazszam', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a házszámot!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Emelet</mat-label>
                                      <input matInput formControlName="f_levelezesi_emelet" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Ajtó</mat-label>
                                      <input matInput formControlName="f_levelezesi_ajto" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Telefonszám módosítás -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'kommunikacios_adatok_telefonszam'">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Mobil</mat-label>
                                      <input matInput formControlName="f_telefon_mobil" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Otthoni telefonszám</mat-label>
                                      <input matInput formControlName="f_telefon_otthon" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Munkahelyi telefonszám</mat-label>
                                      <input matInput formControlName="f_telefon_munkahely" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Email módosítás -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'kommunikacios_adatok_email'">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>E-mail cím</mat-label>
                                      <input matInput formControlName="f_email_cim" autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_email_cim', 'email')">
                                        <mat-icon>error</mat-icon>
                                        A megadott e-mail cím nem megfelelő!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Bónusz előzmény új/módosítás -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'bonusz_elozmeny_uj' || valtozasBejelentesTipus.f_id == 'bonusz_elozmeny_modositas'">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Biztosító</mat-label>
                                      <mat-select formControlName="f_biztosito" required>
                                        <mat-option *ngFor="let biztosito of biztositokLista" [value]="biztosito.f_biztosito">{{biztosito.f_biztosito}}</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_biztosito', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, válassza ki a biztosítót!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Kötvényszám</mat-label>
                                      <input matInput formControlName="f_kotvenyszam" required autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_kotvenyszam', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a kötvényszámot!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Rendszám</mat-label>
                                      <input matInput formControlName="f_rendszam" required autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_rendszam', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a rendszámot!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Rendszám -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'rendszam'">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Rendszám</mat-label>
                                      <input matInput formControlName="f_rendszam" required autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_rendszam', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a rendszámot!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Alvázszám -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'alvazszam'">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Alvázszám</mat-label>
                                      <input matInput formControlName="f_alvazszam" required autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_alvazszam', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg az alvázszámot!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Forgalmi engedély szám -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'forgalmi_engedely_szam'">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Forgalmi engedély szám</mat-label>
                                      <input matInput formControlName="f_forgalmi_engedely_szam" required autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_forgalmi_engedely_szam', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a forgalmi engedély számot!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Egyéb autóadatok -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'egyeb_autoadat'">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Cm3</mat-label>
                                      <input matInput formControlName="f_henger" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Gyártmány</mat-label>
                                      <input matInput formControlName="f_gyartmany" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Típus</mat-label>
                                      <input matInput formControlName="f_tipus" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Motorszám</mat-label>
                                      <input matInput formControlName="f_motorszam" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Kw</mat-label>
                                      <input matInput formControlName="f_kw" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Gyártási év</mat-label>
                                      <input matInput formControlName="f_gyartasi_ev" [matDatepicker]="gyartasiEvDatePicker">
                                      <mat-datepicker-toggle matSuffix [for]="gyartasiEvDatePicker"></mat-datepicker-toggle>
                                      <mat-datepicker #gyartasiEvDatePicker></mat-datepicker>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Saját tömeg</mat-label>
                                      <input matInput formControlName="f_sajat_tomeg" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Megengedett tömeg</mat-label>
                                      <input matInput formControlName="f_megengedett_tomeg" autocomplete="off">

                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Üzemeltetés jellege</mat-label>
                                      <input matInput formControlName="f_uzemjelleg" autocomplete="off">

                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Motor üzemmódja</mat-label>
                                      <input matInput formControlName="f_uzemmod" autocomplete="off">

                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Utas szám</mat-label>
                                      <input matInput formControlName="f_utasszam" autocomplete="off">

                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Jelleg név</mat-label>
                                      <input matInput formControlName="f_jellegnev" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Engedményezési adatok -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'engedmenyezesi_adatok_modositas'">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Hitelintézet neve</mat-label>
                                      <input matInput formControlName="f_hitelintezet_neve" required autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_hitelintezet_neve', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a hitelintézet nevét!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Hitelszám</mat-label>
                                      <input matInput formControlName="f_hitel_szam" required autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_hitel_szam', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a hitel számot!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Hitelösszeg</mat-label>
                                      <input matInput formControlName="f_hitelosszeg" required autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_hitelosszeg', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a hitel összeget!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Hitel százalék</mat-label>
                                      <input matInput formControlName="f_hitelszaz" required autocomplete="off">
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_hitelszaz', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a hitel százalékot!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Hitel kezdete</mat-label>
                                      <input matInput formControlName="f_hitel_hatalytol" [matDatepicker]="hitelHatalytolDatePicker" required>
                                      <mat-datepicker-toggle matSuffix [for]="hitelHatalytolDatePicker"></mat-datepicker-toggle>
                                      <mat-datepicker #hitelHatalytolDatePicker></mat-datepicker>
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_hitel_hatalytol', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a hitel kezdetét!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Hitel vége</mat-label>
                                      <input matInput formControlName="f_hitel_hatalyig" [matDatepicker]="hitelHatalyigDatePicker" required>
                                      <mat-datepicker-toggle matSuffix [for]="hitelHatalyigDatePicker"></mat-datepicker-toggle>
                                      <mat-datepicker #hitelHatalyigDatePicker></mat-datepicker>
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_hitel_hatalyig', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, adja meg a hitel végét!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Szerződés megszüntetése -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'szerzodes_megszuntetese'">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Megszüntetés oka</mat-label>
                                      <mat-select formControlName="f_szerzodes_megszuntetes_oka" required>
                                        <mat-option value="Szerződés felmondása">Szerződés felmondása</mat-option>
                                        <mat-option value="Évfordulós felmondás">Évfordulós felmondás</mat-option>
                                        <mat-option value="Érdekmúlás miatti törlés">Érdekmúlás miatti törlés</mat-option>
                                        <mat-option *ngIf="biztositottTargyTipus == 'mfo'" value="Ügyfélfelmondás miatti törlés">Ügyfélfelmondás miatti törlés</mat-option>
                                        <mat-option value="Közös megegyezéssel">Közös megegyezéssel</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_szerzodes_megszuntetes_oka', 'required')">
                                        <mat-icon>error</mat-icon>
                                        Kérjük, válassza ki a megszüntetés okát!
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div *ngIf="valtozasBejelentesFormGroup.controls['f_szerzodes_megszuntetes_oka'].value == 'Érdekmúlás miatti törlés'" class="col-lg-8">
                                    <granit-file-upload #fileUpload ngDefaultControl
                                                         [formGroup]="valtozasBejelentesFormGroup"
                                                         [formControlName]="'f_csatolt_fajlok'"
                                                         [labelText]="'Adásvételi szerződés és/vagy birtokbaadási szerződés csatolása'"
                                                         [buttonText]="'Kiválasztás'"
                                                         [maxFileSize]="maxFileSize"
                                                         [acceptedFileExtensions]="acceptedFileExtensions"
                                                         [allowMultipleFiles]="true">
                                    </granit-file-upload>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Hibás adatok javítása -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'hibas_adatok_javitasa'">
                                <div class="row">
                                  <div class="col-lg-8">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Hibásan rögzített adatok javítása</mat-label>
                                      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="10" formControlName="f_hibas_adatok_javitasa" required autocomplete="off"></textarea>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Indexálás -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'indexalas'">
                                <div class="row">
                                  <div class="col-lg-8">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Index elutasítása</mat-label>
                                      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="10" formControlName="f_indexalas_elutasitasa" required autocomplete="off"></textarea>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Reaktiválási kérelem -->
                              <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'reaktivalasi_kerelem'">
                                <div class="row">
                                  <div class="col-lg-8">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Reaktiválás</mat-label>
                                      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="10" formControlName="f_reaktivalasi_kerelem" required autocomplete="off"></textarea>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-lg-8">
                                    <granit-file-upload #fileUpload ngDefaultControl
                                                         [formGroup]="valtozasBejelentesFormGroup"
                                                         [formControlName]="'f_csatolt_fajlok'"
                                                         [labelText]="'Dokumentum csatolása'"
                                                         [buttonText]="'Kiválasztás'"
                                                         [maxFileSize]="maxFileSize"
                                                         [acceptedFileExtensions]="acceptedFileExtensions"
                                                         [allowMultipleFiles]="true">
                                    </granit-file-upload>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Hatálydátum -->
                              <div class="row">
                                <div class="col-lg-4">
                                  <mat-form-field appearance="fill">
                                    <mat-label>Hatálydátum</mat-label>
                                    <input matInput formControlName="f_hatalydatum" [min]="hatalydatumMin" [matDatepicker]="hatalydatumDatePicker" required>
                                    <mat-datepicker-toggle matSuffix [for]="hatalydatumDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #hatalydatumDatePicker></mat-datepicker>
                                    <mat-error *ngIf="hibaService.hiba(valtozasBejelentesFormGroup, 'f_hatalydatum', 'required')">
                                      <mat-icon>error</mat-icon>
                                      Kérjük, adja meg a hatálydátumot!
                                    </mat-error>
                                  </mat-form-field>
                                </div>

                              </div>

                              <div class="row">
                                <div class="col-lg-4">
                                  <button mat-button type="button" class="mr-4" (click)="onValtozasBejelentesMegseClick()">Mégsem</button>
                                  <button mat-raised-button type="button" color="accent" [disabled]="!valtozasBejelentesFormGroup.valid" (click)="onValtozasBejelentesElkuldeseClick()">Változás elküldése</button>
                                </div>
                              </div>
                            </form>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </ng-container>
                  </div>
                </mat-card-content>
              </mat-card>
            </ng-container>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
