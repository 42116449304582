import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import * as moment from 'moment';
import 'moment/locale/hu'

@Component({
  selector: 'portal-lablec',
  templateUrl: './portal-lablec.component.html',
  styleUrls: ['./portal-lablec.component.scss']
})
export class PortalLablecComponent implements OnInit {

  constructor(
    private felhasznaloService: FelhasznaloService,
    private router: Router) { }

  aktualisFelhasznalo: Felhasznalo;
  ev = moment().year();


  ngOnInit(): void {
    this.felhasznaloService.aktualisFelhasznalo.subscribe(
      felhasznalo => {
        this.aktualisFelhasznalo = felhasznalo;
      }
    );
  }
  
}
