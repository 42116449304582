<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <portal-content-wrapper [title]="'Regisztráció'"
                                [subTitle]="'Kérjük adja meg a kért adatokat, és pár percen belül hozzáférhet a fiókjához.'"
                                [templateOutlet]="regisztracio">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #regisztracio>
  <div class="row">
    <div class="col-lg-12">
      <mat-card class="form-content">
        <mat-card-header>
          <mat-card-title class="content-title">{{oldalTartalomCim}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-card>
            <mat-card-content class="mb-0">
              <mat-horizontal-stepper linear #stepper disableRipple="true" [selectedIndex]="stepIndex">
                <mat-step completed="false" editable="false">
                  <ng-template matStepLabel>Szerződés adatok</ng-template>
                  <div *ngIf="regisztracioFormEnum == 0" class="row justify-content-end flex-row-reverse">
                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                      <div class="information-wrapper mb-4">
                        A regisztráció megkezdéséhez adja meg a kért, szerződéséhez kapcsolódó adatokat, hogy beazonosítható legyen az ügyfél profil!<br /><br />
                        Amennyiben több ügyfélazonosítóval vagy szerződéssel is rendelkezik, az ügyfélportálba való bejelentkezést követően lehetősége van további ügyfélazonosítókat vagy szerződéseket a profiljához kapcsolni.
                      </div>
                    </div>
                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                      <form [formGroup]="szerzodesAdatokFormGroup" class="form-group mt-0">
                        <div class="row">
                          <div class="col-lg-12">
                            <mat-form-field appearance="fill">
                              <mat-label>Szerződés azonosító</mat-label>
                              <input matInput formControlName="f_szerzodes_azonosito" required>
                              <mat-error *ngIf="hibaService.hiba(szerzodesAdatokFormGroup, 'f_szerzodes_azonosito', 'required')">
                                <mat-icon>error</mat-icon>
                                Kérjük, adja meg a szerződés azonosítót!
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12">
                            <mat-form-field appearance="fill">
                              <mat-label>Szerződő neme</mat-label>
                              <mat-select formControlName="f_szerzodo_neme" (selectionChange)="onSzerzodoNemeChange($event)" required>
                                <mat-option value="N">Nő</mat-option>
                                <mat-option value="F">Férfi</mat-option>
                                <mat-option value="J">Jogi</mat-option>
                              </mat-select>
                              <mat-error *ngIf="hibaService.hiba(szerzodesAdatokFormGroup, 'f_szerzodo_neme', 'required')">
                                <mat-icon>error</mat-icon>
                                Kérjük, válassza ki a szerződő nemét!
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div *ngIf="szerzodesAdatokFormGroup.controls['f_szerzodo_neme'].value != 'J'" class="row">
                          <div class="col-lg-12">
                            <mat-form-field appearance="fill">
                              <mat-label>Szerződő születési dátuma</mat-label>
                              <input matInput formControlName="f_szerzodo_szuletesi_datuma" [matDatepicker]="szerzodoSzuletesiDatumaPicker" required>
                              <mat-datepicker-toggle matSuffix [for]="szerzodoSzuletesiDatumaPicker"></mat-datepicker-toggle>
                              <mat-datepicker #szerzodoSzuletesiDatumaPicker></mat-datepicker>
                              <mat-error *ngIf="hibaService.hiba(szerzodesAdatokFormGroup, 'f_szerzodo_szuletesi_datuma', 'required')">
                                <mat-icon>error</mat-icon>
                                Kérjük, adja meg a szerződő születési dátumát!
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div *ngIf="szerzodesAdatokFormGroup.controls['f_szerzodo_neme'].value == 'J'" class="row">
                          <div class="col-lg-12">
                            <mat-form-field appearance="fill">
                              <mat-label>Szerződő adószáma</mat-label>
                              <input matInput formControlName="f_szerzodo_adoszama" required>
                              <mat-error *ngIf="hibaService.hiba(szerzodesAdatokFormGroup, 'f_szerzodo_neme', 'required')">
                                <mat-icon>error</mat-icon>
                                Kérjük, adja meg a szerződő adószámát!
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12">
                            <button mat-raised-button type="button" [disabled]="!szerzodesAdatokFormGroup.valid" color="accent" (click)="onSzerzodesAdatokClick(stepper)">Tovább a profil adatok megadásához</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </mat-step>
                <mat-step completed="false" editable="false">
                  <ng-template matStepLabel>Profil adatok</ng-template>
                  <div *ngIf="regisztracioFormEnum == 0" class="row justify-content-end flex-row-reverse">
                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                      <div class="information-wrapper mb-4">
                        <div>
                          A regisztráció befejezéséhez adja meg a profilhoz kapcsolódó adatokat.<br /><br />
                          A felhasználó névnek egyedinek kell lennie és minimum 6 karakterből kell állnia. A regisztráció után a felhasználó név módosítására nincs lehetőség.<br /><br />
                          A megadott e-mail címre egy megerősítő e-mailt fog kapni, melynek segítségével véglegesítheti a regisztrációját. A profiljába csak a regisztráció véglegesítése után fog tudni belépni.<br /><br />
                          A jelszónak minimum 12 karakterből kell állnia és tartalmaznia kell legalább egy nagybetűt, egy kis betűt és egy számot.
                          Kérjük kerülje az ékezetes karakterek használatát.
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                      <form [formGroup]="profilAdatokFormGroup" class="form-group mt-0">
                        <div class="row">
                          <div class="col-lg-12">
                            <mat-form-field appearance="fill">
                              <mat-label>Felhasználó név</mat-label>
                              <input id="username" matInput formControlName="f_felhasznalo_nev" required autocomplete="username">
                              <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_felhasznalo_nev', 'required')">
                                <mat-icon>error</mat-icon>
                                Kérjük, adjon meg egy felhasználó nevet!
                              </mat-error>
                              <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_felhasznalo_nev', 'minlength')">
                                <mat-icon>error</mat-icon>
                                A felhasználó névnek minimum 6 karakterből kell állnia.
                              </mat-error>
                              <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_felhasznalo_nev', 'felhasznaloNevFoglalt')">
                                <mat-icon>error</mat-icon>
                                A felhasználónév már foglalt! Kérjük, adjon meg másikat.
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12">
                            <mat-form-field appearance="fill">
                              <mat-label>E-mail cím</mat-label>
                              <input matInput formControlName="f_email" required>
                              <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_email', 'required')">
                                <mat-icon>error</mat-icon>
                                Kérjük, adja meg az e-mail címet!
                              </mat-error>
                              <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_email', 'email')">
                                <mat-icon>error</mat-icon>
                                A megadott e-mail cím nem megfelelő!
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12">
                            <mat-form-field appearance="fill">
                              <mat-label>Jelszó</mat-label>
                              <input id="new-password" matInput formControlName="f_jelszo" [type]="jelszoElrejtese ? 'password' : 'text'" required autocomplete="new-password">
                              <button type="button" mat-icon-button matSuffix (click)="jelszoElrejtese = !jelszoElrejtese">
                                <mat-icon>{{jelszoElrejtese ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </button>
                              <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_jelszo', 'required')">
                                <mat-icon>error</mat-icon>
                                Kérjük, adjon meg egy jelszót!
                              </mat-error>
                              <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_jelszo', 'hibasJelszo')">
                                <mat-icon>error</mat-icon>
                                A jelszónak minimum 12 karakterből kell állnia és tartalmaznia kell legalább egy nagybetűt, egy kis betűt és egy számot. Kérjük kerülje az ékezetes karakterek használatát.
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12">
                            <mat-form-field appearance="fill">
                              <mat-label>Jelszó ismét</mat-label>
                              <input id="new-password-ismet" matInput formControlName="f_jelszo_ismet" [type]="jelszoIsmetElrejtese ? 'password' : 'text'" required autocomplete="new-password">
                              <button type="button" mat-icon-button matSuffix (click)="jelszoIsmetElrejtese = !jelszoIsmetElrejtese">
                                <mat-icon>{{jelszoIsmetElrejtese ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </button>
                              <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_jelszo_ismet', 'required')">
                                <mat-icon>error</mat-icon>
                                Kérjük, adja meg az új jelszavát ismét!
                              </mat-error>
                              <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_jelszo_ismet', 'nemEgyezik')">
                                <mat-icon>error</mat-icon>
                                Kérjük, helyesen ismételje meg jelszavát!
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12">
                            <button mat-raised-button type="button" [disabled]="!profilAdatokFormGroup.valid" color="accent" (click)="onRegisztracioClick(stepper)">Regisztrálok az ügyfélportálra</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                </mat-step>
                <mat-step disableRipple="true">
                  <ng-template matStepLabel>Regisztráció véglegesítés</ng-template>
                  <form class="form-group">
                    <div class="row">
                      <div class="col-lg-12" style="font-weight:500;">
                        <ng-container [ngSwitch]="regisztracioFormEnum">
                          <ng-container *ngSwitchCase="1">
                            <p>
                              Köszönjük a regisztrációját! <br />
                              A regisztráció véglegesítéséhez szükséges információkat elküldtük az Ön által megadott e-mail címre.
                            </p>
                          </ng-container>
                          <ng-container *ngSwitchCase="2">
                            <p>
                              Üdvözöljük a Gránit Biztosító Zrt. Ügyfélportáljának felhasználói között! <br />
                              Reméljük alkalmazásunk segítségére lesz ügyeinek intézése során.<br /><br />
                            </p>
                            <button mat-raised-button color="accent" routerLink="/fooldal">Belépek a fiókomba</button>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </form>
                </mat-step>
              </mat-horizontal-stepper>
            </mat-card-content>
          </mat-card>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>

