<div class="container-fluid pb-3">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <portal-content-wrapper [title]="'Ügyfélportál'"
                                [subTitle]="'Egyszerűen intézheti biztosításival kapcsolatos ügyeit!'"
                                [templateOutlet]="bejelentkezes">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid section-gray section-shadow">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <!--portal-content-wrapper [title]="'Módozatok'"
                          [subTitle]="'Online biztosításkötés esetén válasszon az alábbi termékeink közül!'"
                          [templateOutlet]="modozatok"
                          [titleColorClass]="'color-primary'"
                          [headerBackgroundColorClass]="'section-gray'">
        </portal-content-wrapper-->
        <portal-content-wrapper [title]="'Módozatok'"
                          [subTitle]="'Online biztosításkötés esetén válasszon az alábbi termékeink közül!'"
                          [templateOutlet]="modozatok"
                          [headerBackgroundColorClass]="'section-gray'">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #bejelentkezes>
  <div class="row">
    <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-12 mb-xl-0 mb-lg-0 mb-md-0 mb-4">
      <portal-bejelentkezes [alkalmazasEnum]="0"></portal-bejelentkezes>
    </div>
    <div class="col-xxl-8 col-xl-7 col-lg-7 col-md-6 col-sm-12">
      <mat-card class="form-content">
        <mat-card-header>
          <mat-card-title class="content-title">Regisztráció</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-card>
            <mat-card-content>
              <div class="row">
                <div class="col-lg-12">
                  Gyors ügyintézés érdekében hozza létre saját fiókját a regisztrálok gombra kattintva!
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-12">
                  <button mat-raised-button routerLink="/regisztracio" color="accent">Regisztrálok</button>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-card-content>
      </mat-card>      
    </div>
  </div>
</ng-template>

<ng-template #modozatok>
  <div class="row no-gutters mb-4">
    <mat-card *ngFor="let modozat of modozatokLista" class="col-lg-2 modozat-wrapper" (click)="onTarifalasEsKotesClick(modozat)">
      <mat-card-header>
        <mat-card-title class="d-flex justify-content-center">
          <mat-icon>{{modozat.f_ikon}}</mat-icon>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="d-flex flex-column align-items-center">
        <div>
          <span class="font-weight-bold color-primary">{{modozat.f_nev}}</span>
        </div>
        <div class="color-primary">
          <span>biztosítás</span>
        </div>
        <div class="h-100 d-flex justify-content-around align-items-center">
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
