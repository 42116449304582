
<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <!--portal-content-wrapper [title]="title"
                                [subTitle]="subTitle"
                                [templateOutlet]="adatok"
                                [titleColorClass]="'color-primary'">
        </portal-content-wrapper-->
        <portal-content-wrapper [title]="title"
                                [subTitle]="subTitle"
                                [templateOutlet]="adatok">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>


<ng-template #adatok>

  <div [ngSwitch]="routerUrl">
    <div *ngSwitchCase="'szerzodes-adatok'">
      
  <portal-szerzodes-adatok *ngIf="azonosito && azonosito != ''" [alkalmazasEnum]="0" [azonositoInput]="azonosito"></portal-szerzodes-adatok>

      <!--
        <szerzodes-adatok></szerzodes-adatok>
        -->
    </div>
      <div *ngSwitchCase="'kapcsolattartas'">
        <kapcsolattartas></kapcsolattartas>
      </div>
      <div *ngSwitchCase="'profil-kezeles'">
        <profil></profil>
      </div>
      <div *ngSwitchCase="'kar-adatok'">
        <kar-adatok></kar-adatok>
      </div>
      <div *ngSwitchCase="'dokumentumok-adatok'">
        <dokumentumok-adatok></dokumentumok-adatok>
      </div>
    </div>
  </ng-template>
