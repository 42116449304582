<div class="row">
  <div class="col-lg-12">
    <div class="dokumentumok-wrapper">
      <ng-container *ngIf="toltoKepernyo">
        <div class="col-lg-10 ml-auto mr-auto bg-white pt-3 pr-3 pl-3">
          <ngx-skeleton-loader count="3" [theme]="{'border-radius': '0', height: '50px' }">
          </ngx-skeleton-loader>
        </div>
      </ng-container>
      <ng-container *ngIf="!toltoKepernyo">
        <ng-template [ngIf]="dokumentumokLista.length > 0" [ngIfElse]="nemTalalhatoDokumentum">
          <div *ngFor="let dokumentum of dokumentumokLista" class="dokumentum-wrapper">
            <div class="row">              
              <div class="col-5">
                <div style="font-weight:500;">
                  {{dokumentum.f_sbl_nev}}
                </div>
                <div *ngIf="!dokumentum.f_flotta_azon">
                  Szerződésszám: {{dokumentum.f_szerz_azon}}
                </div>
                <div *ngIf="dokumentum.f_flotta_azon">
                  Flottaszám: {{dokumentum.f_flotta_azon}}
                </div>
              </div>
              <div class="col-5">
                <div>
                  {{dokumentum.f_modkod.substring(0, 3) == '311' ? 'KGFB' : dokumentum.f_modkod.substring(0, 3) == '211' ? 'CASCO' : dokumentum.f_modkod.substring(0, 3) == '221' ? 'OTTHON' : dokumentum.f_modkod.substring(0, 3) == '230' ? 'UTAS' : dokumentum.f_modkod == 'PETRISK' ? 'KISÁLLAT' : 'EGYÉB'}}                  
                </div>
                <div>
                  Kiküldés dátuma: {{dokumentum.f_nyomtatva | dateFormat: 'YYYY. MM. DD.'}}
                </div>                
              </div>
              <div class="col-2">
                <div class="h-100 d-flex justify-content-end align-items-center">
                  <button mat-icon-button matTooltip="Letöltés" matTooltipPosition="above" color="primary" (click) ="onLetoltesClick(dokumentum.f_lev_azon)">
                    <mat-icon>file_download</mat-icon>
                  </button>
                </div>                
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template #nemTalalhatoDokumentum>
          <div class="nem-talalhato-dokumentum">
            <div>
              <mat-icon>unsubscribe</mat-icon>
            </div>
            <div>
              <span>3 hónapnál régebbi dokumentumok megtekintése</span>
            </div>
          </div>          
        </ng-template>
      </ng-container>
    </div>
    <div class="muveletek-wrapper">
      <button class="mr-4" mat-raised-button color="accent" (click)="onDokumentumIgenylesClick()">
        Dokumentum igénylés
      </button>
      <button mat-button (click)="onKikuldottDokumentumokClick()">
        <span>Összes kiküldött dokumentum</span>
      </button>
    </div>
  </div>
</div>
