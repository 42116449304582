<mat-toolbar [class.fejlec-teszt]="teszt" [class.fejlec]="!teszt">
  <div class="container-fluid">
    <div class="row justify-content-md-center">
      <div class="col-xxl-8 col-xl-10 col-lg-12">
        <div class="row">
          <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 background-color-primary">
            <a routerLink={{homeRouterLink}}>
              <span>
                <img class="logo" src="assets/images/logo.png" />
              </span>
            </a>
            <button *ngIf="fooldalGombLathatosag" mat-button color="accent" routerLink="/fooldal">
              <mat-icon style="margin-bottom: 3px;">chevron_left</mat-icon>
              <span>Főoldal</span>
            </button>
          </div>
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" [class.fejlec-jobb-teszt]="teszt" [class.fejlec-jobb]="!teszt">
            <div class="h-100 d-flex align-items-center justify-content-end">
              <div *ngIf="teszt">
                <span class="teszt_szoveg">TESZT</span>
              </div>
              <div *ngIf="aktualisFelhasznalo">
                <button mat-button [color]="teszt ? 'primary' : 'accent'" routerLink="/kapcsolattartas">
                  Kapcsolat
                </button>
                <button mat-button [color]="teszt ? 'primary' : 'accent'" [matMenuTriggerFor]="profilMenu">
                  <mat-icon>account_circle</mat-icon>
                  Profilom
                </button>
                <mat-menu #profilMenu="matMenu" xPosition="before">
                  <button mat-menu-item routerLink="/profil-kezeles">
                    <mat-icon>settings</mat-icon>
                    Profil kezelés
                  </button>
                  <button mat-menu-item (click)="onKijelentkezesClick()">
                    <mat-icon>logout</mat-icon>
                    Kijelentkezés
                  </button>
                </mat-menu>
                <countdown class="d-none" #countdownComponent [config]="{ leftTime: 900, format: 'mm:ss', notify: [300] }" (event)="handleEvent($event)"></countdown>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</mat-toolbar>
