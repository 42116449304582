import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilityService, HibaService, CommonValidator } from '@granit/common-library';
import { FelhasznaloService, PortalValidator } from '@granit/portal-library';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { SzerzodesService } from '../../services/_index';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'regisztracio',
  templateUrl: './regisztracio.component.html',
  styleUrls: ['./regisztracio.component.scss']
})
export class RegisztracioComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private felhasznaloService: FelhasznaloService,
    private szerzodesService: SzerzodesService,
    private utilityService: UtilityService,
    public hibaService: HibaService,
    private route: ActivatedRoute,
    private router: Router,    
    private recaptchaV3Service: ReCaptchaV3Service) {

    // token querystring kiolvasása
    this.route.queryParams.subscribe(params => {
      // Ha a token querystringnek van értéke
      if (params['token']) {
        this.token = (params['token'] as string);
      }
    });
  }

  token: string;
  regisztracioFormGroup: FormGroup;
  szerzodesAdatokFormGroup: FormGroup;
  profilAdatokFormGroup: FormGroup;
  regisztracioFormEnum = RegisztracioFormEnum.Regisztracio;
  oldalTartalomCim = 'Töltse ki a kért adatokat';
  jelszoElrejtese: boolean = true;
  jelszoIsmetElrejtese: boolean = true;

  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  stepIndex = 0;

  ngOnInit(): void {

    // FormGroupok létrehozása
    this.szerzodesAdatokFormGroupLetrehozasa();
    this.profilAdatokFormGroupLetrehozasa();

    // Ha regisztráció véglegesítés, akkor a véglegesítés form
    if (this.router.url.indexOf('regisztracio-veglegesitese') === 1 && this.token) {
      // Validáljuk a tokent
      this.utilityService.jwtTokenValidalas({ token: this.token }).subscribe(
        () => {
          // Ha sikeres a validálás, akkor véglegesítjük a regisztrációt
          // Model, amit átadunk a controllernek
          let model = {
            alkalmazasEnum: 0,
            token: this.token
          };
          this.felhasznaloService.regisztracioVeglegesites(model).subscribe(
            () => {
              this.regisztracioFormEnum = RegisztracioFormEnum.VeglegesitesSikeres;
              this.oldalTartalomCim = 'Sikeres véglegesítés'
              this.stepper._steps.forEach(step => step.completed = true);
              this.stepper.selectedIndex = 2;
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a regisztráció véglegesítése közben",
                confirmButtonText: 'Rendben'
              });
            }
          );
        },
        errorResult => {
          const dialogRef = this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba az oldal megnyitásakor',
            message: 'A rendelkezésre álló időkeret lejárt, kérjük próbálkozzon újra!',
            confirmButtonText: 'Vissza a főoldalra'
          });
          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              this.router.navigate(['/bejelentkezes']);
            }
          });
        }
      );     
    }
  }

  // Szerződés adatok formgroup létrehozása
  szerzodesAdatokFormGroupLetrehozasa() {
    this.szerzodesAdatokFormGroup = this.formBuilder.group({
      f_szerzodes_azonosito: new FormControl(null, [Validators.required]),
      f_szerzodo_neme: new FormControl(null, [Validators.required]),
      f_szerzodo_szuletesi_datuma: new FormControl(null),
      f_szerzodo_adoszama: new FormControl(null)
    });
  }

  // Profil adatok formgroup létrehozása
  profilAdatokFormGroupLetrehozasa() {
    this.profilAdatokFormGroup = this.formBuilder.group({
      f_ugyfelazonosito: new FormControl(null),
      f_felhasznalo_nev: new FormControl(null, { updateOn: 'blur', validators: [Validators.required, Validators.minLength(6), PortalValidator.felhasznaloNevFoglalt(0, this.felhasznaloService)]}),
      f_email: new FormControl(null, [Validators.required, Validators.email]),
      f_jelszo: new FormControl(null, { updateOn: 'blur', validators: [Validators.required, PortalValidator.jelszoEllenorzes()] }),
      f_jelszo_ismet: new FormControl(null, [Validators.required])
    },
      {
        validators: [CommonValidator.osszehasonlitas('f_jelszo', 'f_jelszo_ismet')]
      }
    );
  }

  // Szerződő neme select change esemény
  onSzerzodoNemeChange(event) {
    if (event.value == 'N' || event.value == 'F') {
      this.szerzodesAdatokFormGroup.controls['f_szerzodo_szuletesi_datuma'].setValidators(Validators.required);
      this.szerzodesAdatokFormGroup.controls['f_szerzodo_adoszama'].setValidators(null);
      this.szerzodesAdatokFormGroup.controls['f_szerzodo_adoszama'].patchValue(null);
    }
    else if (event.value == 'J') {
      this.szerzodesAdatokFormGroup.controls['f_szerzodo_adoszama'].setValidators(Validators.required);
      this.szerzodesAdatokFormGroup.controls['f_szerzodo_szuletesi_datuma'].setValidators(null);
      this.szerzodesAdatokFormGroup.controls['f_szerzodo_szuletesi_datuma'].patchValue(null);
    }
  }

  // Szerződés adatok tovább gomb esemény
  onSzerzodesAdatokClick(stepper: MatStepper) {
    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('ugyfelportal_regisztracio_onSzerzodesAdatokClick').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {
          // Ügyfélazonosító lekérése szerződés adatok alapján
          this.szerzodesService.ugyfelazonositoSzerzodesrol(this.szerzodesAdatokFormGroup.value).subscribe(
            data => {
              if (data > -1) {
                stepper.selected.completed = true;
                this.profilAdatokFormGroup.controls['f_ugyfelazonosito'].patchValue(data);
                stepper.next();
              }
              else {
                this.utilityService.dialogMegnyitasa({
                  info: true,
                  title: 'Szerződés adatok',
                  message: 'A megadott szerződés adatok alapján nem található élő szerződés!',
                  confirmButtonText: 'Rendben'
                });
                this.szerzodesAdatokFormGroupLetrehozasa();
              }
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a lekérdezés közben",
                confirmButtonText: 'Rendben'
              });
            }
          );
        });
      }
    );
  }

  // Regisztráció gomb esemény
  onRegisztracioClick(stepper: MatStepper) {
    this.felhasznaloService.regisztracio(this.profilAdatokFormGroup.value).subscribe(
      () => {
        this.regisztracioFormEnum = RegisztracioFormEnum.RegisztracioSikeresVeglegesitesSzukseges;
        stepper.selected.completed = true;
        stepper.next();
        this.oldalTartalomCim = 'Már majdnem kész..'
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a regisztráció közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }
}

enum RegisztracioFormEnum {
  Regisztracio,
  RegisztracioSikeresVeglegesitesSzukseges,
  VeglegesitesSikeres,
  VeglegesitesSikertelen
}
