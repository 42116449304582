import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Route, Router } from '@angular/router';
import { HibaService, UtilityService } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import { Modozat } from '../../../models/_index';
import { ModozatService, SzerzodesService } from '../../../services/_index';

@Component({
  selector: 'szerzodesek',
  templateUrl: './szerzodesek.component.html',
  styleUrls: ['./szerzodesek.component.scss']
})
export class SzerzodesekComponent implements OnInit {

  constructor(
    private szerzodesService: SzerzodesService,
    private modozatService: ModozatService,
    private hibaService: HibaService,
    private felhasznaloService: FelhasznaloService,
    private utilityService: UtilityService,    
    private router: Router) { }

  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;
  modozatokLista: Modozat[] = this.modozatService.modozatokLekerese();
  kivalasztottModozat: Modozat = null;
  szerzodesekLista: any[] = [];
  modozatSzerzodesekLista: any[] = [];
  toltoKepernyo: boolean = true;
  toroltSzerzodesekMutatasa: boolean = false;
  webAppsUrl: string = this.utilityService.webAppsUrlValue;

  kGFBLink: string = "";
  cascoLink: string = "";
  otthonLink: string = "";
  mFOLink: string = "";
  utasLink: string = "";
  egyebLink: string = "";

  ngOnInit(): void {
    this.linkek();
    this.szerzodesService.szerzodesAlapadatok().subscribe(
      data => {
        this.szerzodesekLista = data;
        this.modozatokListaBeallitasa();
        this.szerzodesekSzureseModozatra();
        this.toltoKepernyo = false;        
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a lekérdezés közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  // Módozatok lista beállítása a lekért szerződések alapján
  modozatokListaBeallitasa() {
    for (var i = 0; i < this.modozatokLista.length; i++) {
      let modozat = this.modozatokLista[i];
      modozat.f_szerzodesek_szama = this.szerzodesekLista.filter(sz => sz.f_modozat == modozat.f_id && (sz.f_allapot_tipus as string).toLowerCase() != 'törölt').length;
      if (this.kivalasztottModozat == null && modozat.f_szerzodesek_szama > 0) {
        this.kivalasztottModozat = modozat;
      }
    }
  }

  // Módozatra kattintás esemény
  onModozatClick(modozat: any) {
    this.kivalasztottModozat = modozat;
    this.szerzodesekSzureseModozatra();
  }

  onToroltSzerzodeskMutatasChange(event: MatCheckboxChange) {
    this.toroltSzerzodesekMutatasa = event.checked;
    this.szerzodesekSzureseModozatra();
  }

  // Szerződések szűrése a kiválaszott módozat alapján
  szerzodesekSzureseModozatra() {
    // Kiválaszott módozat alapján lista
    let lista = this.szerzodesekLista.filter(sz => sz.f_modozat == this.kivalasztottModozat.f_id);
    // Kiszűrjük az élő szerződéseket
    let eloSzerzodesek = lista.filter(sz => (sz.f_allapot_tipus as string).toLowerCase() != 'törölt');
    // Kiszűrjük a törölteket is, ha kellenek
    if (this.toroltSzerzodesekMutatasa) {
      // Összefűzzük a 2 listát
      let toroltSzerzodesek = lista.filter(sz => (sz.f_allapot_tipus as string).toLowerCase() == 'törölt');
      this.modozatSzerzodesekLista = eloSzerzodesek.concat(toroltSzerzodesek);
    }
    else {
      // Csal simán az élőket vesszük
      this.modozatSzerzodesekLista = eloSzerzodesek;
    }
  }

  async linkek() {

    Promise.all(
      [this.utilityService.appsettingsKeyErtekLekerese({ key: 'KGFBLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'CascoLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'OtthonLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'MFOLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'UtasLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'EgyebLink' }),
      ]).then(
        data => {
          this.kGFBLink = data[0];
          this.cascoLink = data[1];
          this.otthonLink = data[2];
          this.mFOLink = data[3];
          this.egyebLink = data[5];

          this.utilityService.getSignedUrl(data[4] + "?alkusz=0980").subscribe(
            data => {
              this.utasLink = data;
            }

          )
        })
  }

  // Tarifálás és szerződéskötés gomb esemény
  onTarifalasEsKotesClick() {
    let link = '';
    switch (this.kivalasztottModozat.f_id) {
      case 'KGFB':
        link = this.kGFBLink;
        break;
      case 'OTTHON':
        link = this.otthonLink;
        break;
      case 'CASCO':
        link = this.cascoLink;
        break;
      case 'MFO':
        link = this.mFOLink;
        break;
      case 'UTAS':
        link = this.utasLink;
        break;
      case 'EGYÉB':
        link = this.egyebLink;
        break;
      default:
    }    
    window.open(link);
  }

  // Szerződés adatok gomb esemény
  onSzerzodasAdatokClick(azonosito: any) {
    localStorage.setItem('azonosito', azonosito);
    this.router.navigateByUrl('/szerzodes-adatok');
  }

  // Kárbejelentés gomb esemény
  onKarbejelentesClick(azonosito: any) {

    this.szerzodesService.karbejelentoInditasaSzerzodesrol({ szerzodesAzonosito: azonosito }).subscribe(
      data => {
        if (data && data != 0) {

          this.utilityService.getSignedUrl(this.webAppsUrl + '/karbejelento?karbejazon=' + data).subscribe(
            adat => {
              window.open(adat, '_blank');
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a kárbejelentő indítása közben",
                confirmButtonText: 'Rendben'
              });
            });
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a kárbejelentő indítása közben",
          confirmButtonText: 'Rendben'
        });
      }
    );    
  }

  // Befizetés gomb esemény
  onBefizetesClick(azonosito: any) {
    this.utilityService.getSignedUrl(this.webAppsUrl + '/befizetes?SZ=' + azonosito).subscribe(
      adat => {
        window.open(adat, '_blank');
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a befizetés indítása közben",
          confirmButtonText: 'Rendben'
        });
      });
    
  }

  // Dokumentumigénylés gomb esemény
  onDokumentumIgenylesClick(azonosito: any) {
    localStorage.setItem('azonosito', azonosito);
    localStorage.setItem('menu', 'igény');
    this.router.navigateByUrl('/dokumentumok-adatok');
  }
}
