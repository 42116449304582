import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UtilityService, HibaService, GranitTableComponent } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import { DokumentumService, KarService } from '../../../services/_index';
import { saveAs } from 'file-saver';


@Component({
  selector: 'kar-adatok',
  templateUrl: './kar-adatok.component.html',
  styleUrls: ['./kar-adatok.component.scss']
})
export class KarAdatokComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private felhasznaloService: FelhasznaloService,
    private karService: KarService,
    private dokumentumService: DokumentumService,
    private hibaService: HibaService,    
    private utilityService: UtilityService) { }

  toltoKepernyo: boolean = true;
  kivalasztottMenu: string = "";
  azonosito: string;
  adatok: any;
  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;

  kikuldottLevelekOszlopok: any[] = [
    { columnDef: 'F_sbl_nev', header: 'Levél típusa' },
    { columnDef: 'F_nyomtatva', header: 'Küldés dátuma', dateFormat: 'YYYY.MM.DD. HH.mm', adattipus: 'date' },
    { columnDef: 'F_lev_azon', header: 'azonosito', visible: false }
  ];
  kikuldottLevelekFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });

  kifizetesekOszlopok: any[] = [
    { columnDef: 'f_fizetes_modja', header: 'Fizetés módja' },
    { columnDef: 'f_vegdatum', header: 'Jóváírás dátuma' },
    { columnDef: 'f_osszeg', header: 'Összeg (Ft)', adattipus: 'number', numberFormat: 'thousand' }
  ];
  kifizetesekFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });

  onreszOszlopok: any[] = [
    { columnDef: 'f_tipus', header: 'Tipus' },
    { columnDef: 'f_onresz', header: 'Érték' }
  ];
  onreszFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });

  ngOnInit(): void {
    this.azonosito = localStorage.getItem('azonosito')
    this.karAdatokLekerese();
  }

  // Szerződés adatok lekérése
  karAdatokLekerese() {
    let model = {
      karszam: this.azonosito
    };
    this.karService.karAdatok(model).subscribe(
      data => {
        this.adatok = data;
        this.tablazatokFeltoltese();
        this.toltoKepernyo = false;
        this.onMenuClick('Káradatok');
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a lekérdezés közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  // Táblázatok feltöltése
  tablazatokFeltoltese() {
    // Biztosított tárgy adatai - Gumi táblázatKiküldött levelek
    if (this.adatok.karLevelek && this.adatok.karLevelek.length > 0) {
      const fgs = this.adatok.karLevelek.map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.kikuldottLevelekFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
    // Kárkifizetések
    if (this.adatok.karKifizetesek && this.adatok.karKifizetesek.length > 0) {
      const fgs = this.adatok.karKifizetesek.map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.kifizetesekFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
    // Önrész
    if (this.adatok.onresz && this.adatok.onresz.length > 0) {
      const fgs = this.adatok.onresz.map(item => {
        return this.utilityService.formGroupObjektumbol(item);
      });
      this.onreszFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
    }
  }

  // Menüpont gomb esemény
  onMenuClick(menu: any) {
    this.kivalasztottMenu = menu;
  }

  // Dokumentum letöltés gomb esemény
  onRowOperationClick(event: any) {
    this.dokumentumService.levelLetoltes({ levelAzonosito: event.value.F_lev_azon.toString() }).subscribe(
      data => {
        const filename = data.headers.get('Content-Disposition').split(/[;'=]/).pop();
        saveAs(data.body, decodeURIComponent(filename));
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a letöltés közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }
}
