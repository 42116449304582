
<div class="row">
  <div class="col-lg-12">
    <mat-card class="p-0">
      <mat-card-content>
        <div class="row no-gutters">
          <div class="col-lg-4 menu-wrapper">
            <ng-container *ngIf="toltoKepernyo">
              <div class="col-lg-10 ml-auto mr-auto bg-white pt-3 pr-3 pl-3">
                <ngx-skeleton-loader count="3" [theme]="{'border-radius': '0', height: '50px' }">
                </ngx-skeleton-loader>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <mat-nav-list>
                <mat-list-item [class.kivalasztott-menu]="kivalasztottMenu && kivalasztottMenu == 'Üzenet küldés'">
                  <button mat-button (click)="onMenuClick('Üzenet küldés')">
                    <span>Üzenet küldés</span>
                  </button>
                  <mat-divider></mat-divider>
                  <div class="balra-nyil">
                  </div>
                </mat-list-item>
                <mat-list-item>
                  <button mat-button (click)="onMenuClick('Időpont foglalás ügyfélszolgálatra')">
                    <span>Időpont foglalás ügyfélszolgálatra</span>
                  </button>
                  <mat-divider></mat-divider>
                  <div class="balra-nyil">
                  </div>
                </mat-list-item>
              </mat-nav-list>
              <div class="elerhetoseg">
                <div class="col-lg-12 elerhetoseg_cim mb-2">
                  Központi Ügyfélszolgálat
                </div>
                <div class="row">
                  <div class="col-lg-3 elerhetoseg_header">
                    Cím:
                  </div>
                  <div class="col-lg-9">
                    1211 Budapest, Szállító utca 4.
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3 elerhetoseg_header">
                    Tel.:
                  </div>
                  <div class="col-lg-9">
                    (+36-1) 666-62-00
                  </div>
                </div>                
                <div class="row">
                  <div class="col-lg-3 elerhetoseg_header">
                    &nbsp;
                  </div>
                  <div class="col-lg-9">
                    (+36-1) 666-64-00
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-lg-3 elerhetoseg_header">
                    E-mail:
                  </div>
                  <div class="col-lg-9 elerhetoseg_email">
                    ugyfelszolgalat@granitbiztosito.hu
                  </div>
                </div>
                <div class="col-lg-12 elerhetoseg_cim mb-2">
                  Nyitva tartás
                </div>
                <div class="row">
                  <div class="col-lg-4 elerhetoseg_header">
                    Hétfő:
                  </div>
                  <div class="col-lg-8">
                    7:00-19:00
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 elerhetoseg_header">
                    Kedd:
                  </div>
                  <div class="col-lg-8">
                    8:00-16:00
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 elerhetoseg_header">
                    Szerda:
                  </div>
                  <div class="col-lg-8">
                    8:00-16:00
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 elerhetoseg_header">
                    Csütörtök:
                  </div>
                  <div class="col-lg-8">
                    8:00-16:00
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-lg-4 elerhetoseg_header">
                    Péntek:
                  </div>
                  <div class="col-lg-8">
                    8:00-15:00
                  </div>
                </div>
                <div class="col-lg-12 elerhetoseg_cim mb-2">
                  Pénztár nyitva tartása
                </div>
                <div class="row">
                  <div class="col-lg-4 elerhetoseg_header">
                    Hétfő - Csütörtök:
                  </div>
                  <div class="col-lg-8">
                    8:00-16:00
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-lg-4 elerhetoseg_header">
                    Péntek:
                  </div>
                  <div class="col-lg-8">
                    8:00-14:00
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-lg-8">
            <ng-container *ngIf="toltoKepernyo">
              <div class="col-lg-10 ml-auto mr-auto bg-white pt-3 pr-3 pl-3">
                <ngx-skeleton-loader count="3" [theme]="{'border-radius': '0', height: '50px' }">
                </ngx-skeleton-loader>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <mat-card class="form-content pt-2 pr-4 pb-2 pl-4 h-100">
                <mat-card-header>
                  <mat-card-title *ngIf="kivalasztottMenu" class="content-title pb-3">{{kivalasztottMenu}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>

                  <!-- Üzenet küldés -->
                  <ng-container *ngIf="kivalasztottMenu && kivalasztottMenu == 'Üzenet küldés'">
                    <form [formGroup]="uzenetKuldesFormGroup" class="form-group">
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <mat-form-field appearance="fill">
                            <mat-label>Szerződések</mat-label>
                            <mat-select formControlName="kivalasztottSzerzodes">
                              <mat-option value="0">Kérem válasszon..</mat-option>
                              <mat-option value="-1">Nem szerződéshez tartozó</mat-option>
                              <mat-option *ngFor="let szerzodeSzam of szerzodesekLista" [value]="szerzodeSzam.f_szerz_azon">{{szerzodeSzam.f_szerz_azon}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <mat-form-field appearance="fill">
                            <mat-label>Üzenet</mat-label>
                            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="10" formControlName="uzenet" autocomplete="off"></textarea>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <button mat-raised-button color="accent" [disabled]="uzenetKuldesFormGroup.status == 'INVALID'" (click)="onUzenetKuldes()">
                            Üzenet küldése
                          </button>
                        </div>
                      </div>
                    </form>
                  </ng-container>

                </mat-card-content>
              </mat-card>
            </ng-container>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
