import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from '@granit/common-library';
import { FelhasznaloService } from '@granit/portal-library';

@Component({
  selector: 'muvelet-veglegesites',
  templateUrl: './muvelet-veglegesites.component.html',
  styleUrls: ['./muvelet-veglegesites.component.scss']
})
export class MuveletVeglegesitesComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private felhasznaloService: FelhasznaloService,
    private utilityService: UtilityService) {

    // Kiszedjuk a route nevét
    this.routerUrl = this.router.url.split('?')[0].substring(1);

    // Kiszedjük a QueryStringből a tokent
    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
      }
    });
  }

  routerUrl: string = '';
  token: string = '';

  ngOnInit(): void {

    // Ha nincs token querystring, akkor hiba és vissza navigáljuk a bejelentkezés oldalra
    if (!this.token) {
      const dialogRef = this.utilityService.dialogMegnyitasa({
        error: true,
        title: 'Hiba az oldal megnyitásakor',
        message: 'Az oldal hiányzó paraméterrel lett megnyitva.',
        confirmButtonText: 'Vissza a főoldalra'
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.router.navigate(['/bejelentkezes']);
        }
      });
    }
    // Ha van token querystring, ellenőrozzük, hogy valid-e
    this.utilityService.jwtTokenValidalas({ token: this.token }).subscribe(
      () => {
        // Ha e-mail módosítás
        if (this.routerUrl == 'email-modositas') {
          this.felhasznaloService.emailModositas({ token: this.token }).subscribe(
            data => {
              // Ha sikeres a mentés
              if (data == 0) {
                this.utilityService.dialogMegnyitasa({
                  success: true,
                  title: 'Sikeres mentés',
                  message: 'Az e-mail cím módosítása sikeresen megtörtént.',
                  confirmButtonText: 'Rendben'
                });
                this.felhasznaloService.kijelentkezes();
              }
            }
          );
        }
        // Ha regisztráció törlése
        else if (this.routerUrl == 'regisztracio-torlese') {
          this.felhasznaloService.felhasznaloTorles({ token: this.token }).subscribe(
            data => {
              // Ha sikeres a törlés
              if (data == 0) {
                this.utilityService.dialogMegnyitasa({
                  success: true,
                  title: 'Sikeres törlés',
                  message: 'A regisztráció törlése sikeresen megtörtént.',
                  confirmButtonText: 'Rendben'
                });
                this.felhasznaloService.kijelentkezes();
              }
            }
          );
        }
      },
      errorResult => {
        const dialogRef = this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba az oldal megnyitásakor',
          message: 'A rendelkezésre álló időkeret lejárt, kérjük próbálkozzon újra!',
          confirmButtonText: 'Vissza a főoldalra'
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.router.navigate(['/bejelentkezes']);
          }
        });
      }
    );
  }
}
