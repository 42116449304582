import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FooldalComponent } from './components/fooldal/fooldal.component';
import { RegisztracioComponent } from './components/regisztracio/regisztracio.component';
import { BejelentkezesComponent } from './components/bejelentkezes/bejelentkezes.component';
import { HitelesitesGuard } from '@granit/portal-library';
import { AloldalComponent } from './components/aloldal/aloldal.component';
import { MuveletVeglegesitesComponent } from './components/muvelet-veglegesites/muvelet-veglegesites.component';
import { ElfelejtettJelszoComponent } from './components/elfelejtett-jelszo/elfelejtett-jelszo.component';
import { RegisztracioMegerositesComponent } from './components/regisztracio-megerosites/regisztracio-megerosites.component';

const routes: Routes = [
  { path: '', redirectTo: 'fooldal', pathMatch: 'full' },
  { path: 'fooldal', component: FooldalComponent, canActivate: [HitelesitesGuard] },
  { path: 'bejelentkezes', component: BejelentkezesComponent },
  { path: 'regisztracio', component: RegisztracioComponent },
  { path: 'regisztracio-veglegesitese', component: RegisztracioComponent },
  { path: 'szerzodes-adatok', component: AloldalComponent, canActivate: [HitelesitesGuard] },
  { path: 'kapcsolattartas', component: AloldalComponent, canActivate: [HitelesitesGuard] },
  { path: 'profil-kezeles', component: AloldalComponent, canActivate: [HitelesitesGuard] },
  { path: 'kar-adatok', component: AloldalComponent, canActivate: [HitelesitesGuard] },
  { path: 'dokumentumok-adatok', component: AloldalComponent, canActivate: [HitelesitesGuard] },
  { path: 'email-modositas', component: MuveletVeglegesitesComponent },
  { path: 'regisztracio-torlese', component: MuveletVeglegesitesComponent },
  { path: 'elfelejtett-jelszo', component: ElfelejtettJelszoComponent },
  { path: 'regisztracio-megerosites', component: RegisztracioMegerositesComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
