import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fooldal',
  templateUrl: './fooldal.component.html',
  styleUrls: ['./fooldal.component.scss']
})
export class FooldalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   
  }  
}
