import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { HibaService, UtilityService, GranitTableComponent } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import { DokumentumService, SzerzodesService } from '../../../services/_index';
import * as moment from 'moment';
import 'moment/locale/hu'
import { saveAs } from 'file-saver';

@Component({
  selector: 'dokumentumok-adatok',
  templateUrl: './dokumentumok-adatok.component.html',
  styleUrls: ['./dokumentumok-adatok.component.scss']
})
export class DokumentumokAdatokComponent implements OnInit {

  dokumentumIgenylesFormGroup: FormGroup;
  kikuldottDokumentumokSzuroForm: FormGroup;
  toltoKepernyo: boolean = false;
  szerzodesekLista: any[] = [];
  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;
  azonosito: string;
  szuresBe: boolean = false;

  /* tooltip beállítások*/
  myOptions = {
    'placement': 'top',
    'max-width': 600,
    'tooltip-class': 'my-tooltip'
  }

  /*dokumentum igényléshez változók*/
  dijrend: any;
  zoldKartya: boolean;
  dijIgazolas: boolean;
  kotveny: boolean;
  dijbekero: boolean;
  karTori: boolean;
  eredmenyLista: any[];
  /*dokumentum igényléshez változók*/

  /*kiküldött dokumentumok legördülő listái*/
  szerzodesLista: any[];
  flottaLista: any[];
  ugyfelLista: any[];
  dokumentumTipusLista: any[];
  /*kiküldött dokumentumok legördülő listái*/

  /*kiválasztott tab index*/
  dokumentumokTabIndex: number = 0;

  kikuldottDokumentumokOszlopok: any[] = [
    { columnDef: 'f_lev_azon', header: 'Levél' },
    { columnDef: 'f_sbl_nev', header: 'Dokumentum típus' },
    { columnDef: 'f_datum', header: 'Küldés dátuma', dateFormat: 'YYYY.MM.DD. HH.mm', adattipus: 'date' },
    { columnDef: 'f_szerz_azon', header: 'Szerződés' },
    { columnDef: 'f_flotta_azon', header: 'Flotta' }/*,
    { columnDef: 'f_ugyazon', header: 'Ügyfél' }*/
  ];
  kikuldottDokumentumokFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([])
  });
  @ViewChild('kikuldottDokumentumokTabla', { static: false }) kikuldottDokumentumokTabla: GranitTableComponent;

  constructor(private formBuilder: FormBuilder,
    public hibaService: HibaService,
    private felhasznaloService: FelhasznaloService,
    private szerzodesService: SzerzodesService,
    private dokumentumService: DokumentumService,
    private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.azonosito = localStorage.getItem('azonosito');
    this.azonosito = this.azonosito == null ? "0" : this.azonosito;

    this.toltoKepernyo = true;

    this.dokumentumIgenylesFormGroupInit();
    this.kikuldottDokumentumokSzuroFormInit();

    this.toltoKepernyo = false;

    /*bejövő paraméter alapján tab választás*/
    if (localStorage.getItem('menu') == 'igény') {
      this.dokumentumokTabIndex = 0;
    }
    if (localStorage.getItem('menu') == 'kiküldött') {
      this.dokumentumokTabIndex = 1;
    }
  }

  ngOnDestroy(): void {
    localStorage.removeItem('azonosito');
    localStorage.removeItem('menu');
  }

  /*dokumentum igénylés form inicializálása, felhasználóhoz tartozó szerződések*/
  dokumentumIgenylesFormGroupInit() {
    this.dokumentumIgenylesFormGroup = this.formBuilder.group({
      kivalasztottSzerzodes: new FormControl("0"),
      zoldkartya: new FormControl(false),
      dijigazolas: new FormControl(false),
      kotveny: new FormControl(false),
      dijbekero: new FormControl(false),
      kartori: new FormControl(false)
    }, {
      validators: [AdatValidator.dokumentumHiany()]
    });

    this.szerzodesekLista = [];

    /*Felhasználóhoz tartozó szerződések lekérdezése*/
    this.szerzodesService.szerzodesAlapadatok().subscribe(
      data => {
        this.szerzodesekLista = data;

        if (this.azonosito != "0") {
          this.dokumentumIgenylesFormGroup.controls["kivalasztottSzerzodes"].setValue(Number(this.azonosito));
          this.onSzerzodesValasztas();
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a lekérdezése közben",
          confirmButtonText: 'Rendben'
        });
      }
    );

  }

  /*kiküldött dokumentumok szűrő form inicializálása*/
  kikuldottDokumentumokSzuroFormInit() {
    this.kikuldottDokumentumokSzuroForm = this.formBuilder.group({
      keresesTipus: new FormControl("0"),
      keresesSzerzodesszam: new FormControl("0"),
      keresesFlottaAzonosito: new FormControl("0"),
      keresesUgyfelAzonosito: new FormControl("0"),
      keresesKezdoDatum: new FormControl(null),
      keresesVegDatum: new FormControl(null)
    });

    /*adatok lekérdezése szűrők alkalmazásával*/
    this.onSzuresClick();
  }


  /* dokumentum igénylés tabon szerződés választásnál milyen dokumentumot igényelhet*/
  onSzerzodesValasztas() {    

    /*dokumentum igénylés menüpontban szerződés választásnál feltételek kezelése a letölthető dokumentum tipuskhoz*/
    this.dokumentumIgenylesFormGroup.controls["zoldkartya"].setValue(false);
    this.dokumentumIgenylesFormGroup.controls["dijigazolas"].setValue(false);
    this.dokumentumIgenylesFormGroup.controls["kotveny"].setValue(false);
    this.dokumentumIgenylesFormGroup.controls["dijbekero"].setValue(false);
    this.dokumentumIgenylesFormGroup.controls["kartori"].setValue(false);

    let model = {
      f_szerzazon: this.dokumentumIgenylesFormGroup.controls["kivalasztottSzerzodes"].value
    }

    this.dokumentumService.kiadhatoDokumentumok(model).subscribe(
      data => {
        if (data.eredmenyKod == "0") {

          this.zoldKartya = data.zoldkartya;
          this.kotveny = data.kotveny;
          this.dijbekero = data.dijbekero;
          this.dijIgazolas = data.dijigazolas;
          this.karTori = data.kartorteneti;
        }
        else {
          this.eredmenyLista.push(data.EredmenySzoveg);
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a lekérdezése közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }      
  
  /* kiválasztott dokumentum igénylés beküldése*/
  onDokumentumIgenyles() {
    /*kiválasztott dokumentum igénylés(ek) indítása*/

    let model = {
      dokumentumIgenyles: this.dokumentumIgenylesFormGroup.value
    };

    this.dokumentumService.wsDokumentumIgeny(model).subscribe(
      data => {
        this.eredmenyLista = [];
        for (var i = 0; i < data.length; i++) {
          let eredmeny = "- " + data[i].dokumentum + (data[i].eredmeny_kod == 0 ? " igénylését fogadtuk" : " igénylését nem tudtuk fogadni") + (data[i].eredmeny_kod == 0 ? "" : " Hibakód: " + data[i].eredmeny_kod + " -  Hibaszöveg: " + data[i].eredmeny_szoveg);
          this.eredmenyLista.push(eredmeny);
          this.dokumentumIgenylesFormGroup.controls["zoldkartya"].setValue(false);
          this.dokumentumIgenylesFormGroup.controls["dijigazolas"].setValue(false);
          this.dokumentumIgenylesFormGroup.controls["kotveny"].setValue(false);
          this.dokumentumIgenylesFormGroup.controls["dijbekero"].setValue(false);
          this.dokumentumIgenylesFormGroup.controls["kartori"].setValue(false);
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a dokumentum igénylése közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  // Dokumentum letöltés gomb esemény
  onRowOperationClick(event: any) {

    this.dokumentumService.levelLetoltes({ levelAzonosito: event.value.f_lev_azon.toString() }).subscribe(
      data => {
        const filename = data.headers.get('Content-Disposition').split(/[;'=]/).pop();
        saveAs(data.body, decodeURIComponent(filename));
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a dokumentum letöltése közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  /*adatok lekérdezése szűrők alkalmazásával*/
  onSzuresClick() {

    let model = {
      aktualisFelhasznalo: this.aktualisFelhasznalo,
      kikuldottDokumentumokSzuro: this.kikuldottDokumentumokSzuroForm.value
    };

    /*Felhasználóhoz tartozó dokumentumok lekérdezése*/
    this.dokumentumService.dokumentumAdatok(model).subscribe(
      data => {
        const fgs = data.levelek.map(item => {
          return this.utilityService.formGroupObjektumbol(item);
        });
        this.kikuldottDokumentumokFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
        this.kikuldottDokumentumokTabla.setDataSource();

        /*Szűrő legördülő listák feltöltése*/
        if (data.levelek.length > 0) {
          this.dokumentumTipusLista = Array.from(new Set(data.levelek.map(x => x.f_sbl_nev))).sort();
          this.szerzodesLista = Array.from(new Set(data.levelek.filter(y => y.f_szerz_azon != null && y.f_szerz_azon > 0).map(x => x.f_szerz_azon))).sort();
          this.flottaLista = Array.from(new Set(data.levelek.filter(y => y.f_flotta_azon != null).map(x => x.f_flotta_azon))).sort();
          /*this.ugyfelLista = Array.from(new Set(data.map(x => x.f_ugyazon))).sort();*/
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a lekérdezés közben",
          confirmButtonText: 'Rendben'
        });
      }
    );

  }

  /*szűrés form mutatása/elrejtése*/
  onSzuresFormValt() {
    this.szuresBe = !this.szuresBe;
  }

  /*szűrők feltételek alaphelyzetbe állítása*/
  onSzuresAlap() {
    this.kikuldottDokumentumokSzuroFormInit();
    this.onSzuresClick();
  }

  /*szűrésnél tól dátum változásnál ha az ig kisebb akkor törlés*/
  onDatumValtozas(formGroup: FormGroup) {

    if (formGroup.controls["keresesKezdoDatum"].value != null && formGroup.controls["keresesVegDatum"].value != null) {
      if (formGroup.controls["keresesKezdoDatum"].value > formGroup.controls["keresesVegDatum"].value) {
        formGroup.controls["keresesVegDatum"].reset();
      }
    }

  }

}

export class AdatValidator extends Validators {

  /*ha nincs ki9választva egy dokumentum se igény beküldésnél, akkor hiba*/
  static dokumentumHiany() {
    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const ctr_szerzodes = formGroup.controls["kivalasztottSzerzodes"];
      const ctr_zoldkartya = formGroup.controls["zoldkartya"];
      const ctr_dijigazolas = formGroup.controls["dijigazolas"];
      const ctr_kotveny = formGroup.controls["kotveny"];
      const ctr_dijbekero = formGroup.controls["dijbekero"];
      const ctr_kartori = formGroup.controls["kartori"];

      if (ctr_szerzodes.value == "0") {
        hasError = true;
      }
      else {
        if (!ctr_zoldkartya.value && !ctr_dijigazolas.value && !ctr_kotveny.value && !ctr_dijbekero.value && !ctr_kartori.value) {
          hasError = true;
        }
      }
      if (hasError) {
        ctr_szerzodes.setErrors({ dokumentumHiany: true });
      }
      else {
        ctr_szerzodes.setErrors(null);
      }
    }
    return validator;
  }

}
