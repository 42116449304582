<div class="row">
  <div class="col-lg-12">
    <div class="karugyek-wrapper">
      <ng-container *ngIf="toltoKepernyo">
        <div class="col-lg-10 ml-auto mr-auto bg-white pt-3 pr-3 pl-3">
          <ngx-skeleton-loader count="3" [theme]="{'border-radius': '0', height: '50px' }">
          </ngx-skeleton-loader>
        </div>
      </ng-container>
      <ng-container *ngIf="!toltoKepernyo">
        <ng-template [ngIf]="karugyekSzurtLista.length > 0" [ngIfElse]="nemTalalhatoKarugy">
          <div *ngFor="let karugy of karugyekSzurtLista" class="karugy-wrapper">
            <div class="row pb-2">
              <div class="col-9">
                <div>
                  <span style="font-weight:500;">{{karugy.f_karszam}}</span>
                  kárszámú
                  <span style="font-weight:500;">{{karugy.f_tszam}}.</span>
                  tételszámú kárbejelentés
                </div>
                <div>
                  <span>Szerződésszám: {{karugy.f_szerz_azon}}</span>
                </div>
              </div>
              <div class="col-3">
                <button mat-button color="primary" (click)="onKarAdatlapClick(karugy.f_karszam + '/' + karugy.f_tszam)">Kár adatlap</button>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <div style="height:125px;">
                  <circle-progress [percent]="karugy.f_szazalek_osszesitve"></circle-progress>
                </div>
              </div>
              <div class="col-8">
                <div class="pt-1">
                  <span>Káresemény dátuma: {{karugy.f_karido | dateFormat: 'YYYY. MM. DD.'}}</span>
                </div>
                <div class="pt-1">
                  <span>Kárbejelentés dátuma: {{karugy.f_karbeido | dateFormat: 'YYYY. MM. DD.'}}</span>
                </div>
                <div class="pt-1">
                  <span>Módozat: {{karugy.f_modozat}}</span>
                </div>
                <div class="pt-1">
                  <span>Kiadva: {{karugy.f_kiadva_nev}}</span>
                </div>
                <div class="pt-1 pb-1">
                  <span>Státusz: {{karugy.f_kar_statusz_megnevezes}}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template #nemTalalhatoKarugy>
          <div class="nem-talalhato-karugy">
            <div>
              <mat-icon>info</mat-icon>
            </div>
            <div>
              <span>Önnek nincs folyamatban lévő kárügye.</span>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div class="muveletek-wrapper">
      <button mat-raised-button color="accent" class="mr-4" (click)="onUjKarbejelentesClick()">Kárbejelentés</button>
      <mat-checkbox (change)="onLezartKarugyekkMutatasChange($event)">Mutassa a lezárt kárügyeimet is</mat-checkbox>
    </div>
  </div>
</div>
