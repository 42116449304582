<!--<button mat-raised-button color="accent" (click)="onTesztNemHitelesitettClick()">Nem hitelesített API hívás</button>
<button mat-raised-button color="accent" (click)="onTesztHitelesitettClick()">Hitelesített API hívás</button>-->
<div class="container-fluid section-gray">
  <div class="section">
    <div class="row">
      <div class="col-lg-6 mb-xl-0 mb-lg-0 mb-4">
        <!--portal-content-wrapper [title]="'Dokumentumaim'"
                                [subTitle]="'Itt látja az Önnek küldött utolsó 3 hónap dokumentumait (csekk, díjfizetésifelszólítós stb.), amennyiben a keresett dokumetumot nem találja, kérjük kattintson a Dokumentum igénylés gombra!'"
                                [templateOutlet]="dokumentumok"
                                [titleColorClass]="'color-primary'"
                                [headerContainerPadding]="true">
        </portal-content-wrapper-->
        <portal-content-wrapper [title]="'Dokumentumaim'"
                                [subTitle]="'Itt látja az Önnek küldött utolsó 3 hónap dokumentumait (csekk, díjfizetésifelszólítós stb.), amennyiben a keresett dokumetumot nem találja, kérjük kattintson a Dokumentum igénylés gombra!'"
                                [templateOutlet]="dokumentumok"
                                [headerContainerPadding]="true">
        </portal-content-wrapper>
      </div>
      <div class="col-lg-6">
        <!--portal-content-wrapper [title]="'Kárügyeim'"
                                [subTitle]="'Megtekintheti a kárügyintézés állapotát. Régebbi károk adatait is megismerheti, ehhez kérjük jelölje ki a Mutassa régi kárügyeimet is! Amennyiben káreseményt kíván bejelenteni, kattitson a Kárbejelentés gombra és töltse ki a kárbejelentőt!'"
                                [templateOutlet]="karstatusz"
                                [titleColorClass]="'color-primary'"
                                [headerContainerPadding]="true">
        </portal-content-wrapper-->
        <portal-content-wrapper [title]="'Kárügyeim'"
                                [subTitle]="'Megtekintheti a kárügyintézés állapotát. Régebbi károk adatait is megismerheti, ehhez kérjük jelölje ki a Mutassa régi kárügyeimet is! Amennyiben káreseményt kíván bejelenteni, kattitson a Kárbejelentés gombra és töltse ki a kárbejelentőt!'"
                                [templateOutlet]="karstatusz"
                                [headerContainerPadding]="true">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid section-shadow">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <!--portal-content-wrapper [title]="'Szerződéseim'"
                                [subTitle]="'Az alábbi ikonokra kattintva megtekintheti biztosításait, illetve egyszerűen és gyorsan új kalkulációt végezhet a megfelelő termékkategóriában. Szerződése adatait módosíthatja, dokumentumokat igényelhet, egyszerűen intézheti a díjfizetést, kárbejelentést.'"
                                [templateOutlet]="szerzodesek"
                                [titleColorClass]="'color-primary'">
        </portal-content-wrapper-->
        <portal-content-wrapper [title]="'Szerződéseim'"
                                [subTitle]="'Az alábbi ikonokra kattintva megtekintheti biztosításait, illetve egyszerűen és gyorsan új kalkulációt végezhet a megfelelő termékkategóriában. Szerződése adatait módosíthatja, dokumentumokat igényelhet, egyszerűen intézheti a díjfizetést, kárbejelentést.'"
                                [templateOutlet]="szerzodesek">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>


<ng-template #dokumentumok>
  <dokumentumok></dokumentumok>
</ng-template>

<ng-template #karstatusz>
  <karstatuszok></karstatuszok>  
</ng-template>

<ng-template #szerzodesek>
  <szerzodesek></szerzodesek>
</ng-template>
