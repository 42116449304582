<mat-card class="p-0">
  <mat-card-content>
    <div class="row no-gutters">
      <!-- Menü oszlop -->
      <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-6 menu-wrapper">
        <!-- Töltő képernyő -->
        <ng-container *ngIf="toltoKepernyo">
          <div class="col-lg-10 ml-auto mr-auto bg-white pt-3 pr-3 pl-3">
            <ngx-skeleton-loader count="3" [theme]="{'border-radius': '0', height: '50px' }">
            </ngx-skeleton-loader>
          </div>
        </ng-container>
        <ng-container *ngIf="!toltoKepernyo">
          <mat-nav-list>
            <mat-list-item *ngFor="let menu of menuLista; let last = last" [class.kivalasztott-menu]="menu.f_id == kivalasztottMenu.f_id">
              <button mat-button (click)="onMenuClick(menu)">
                <span>{{menu.f_cim}}</span>
              </button>
              <div class="balra-nyil">
              </div>
              <mat-divider *ngIf="!last"></mat-divider>
            </mat-list-item>
          </mat-nav-list>
        </ng-container>
      </div>
      <!-- Tartalom oszlop -->
      <div class="col-xl-9 col-lg-9 col-md-8 col-sm-7 col-6">
        <!-- Töltő képernyő -->
        <ng-container *ngIf="toltoKepernyo">
          <div class="col-lg-10 ml-auto mr-auto bg-white pt-3 pr-3 pl-3">
            <ngx-skeleton-loader count="3" [theme]="{'border-radius': '0', height: '50px' }">
            </ngx-skeleton-loader>
          </div>
        </ng-container>
        <ng-container *ngIf="!toltoKepernyo">
          <mat-card class="form-content pt-3 pr-4 pb-3 pl-4 h-100">
            <mat-card-content>
              <div class="row">

                <!-- Profil adatok -->
                <ng-container *ngIf="!valtozasBejelentesTipus && kivalasztottMenu.f_id == 'profil_adatok'">
                  <!-- Profil alap adatok -->
                  <div class="col-12">
                    <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                      <mat-card-header>
                        <mat-card-title class="content-title">
                          <div class="row align-items-center">
                            <div class="col-lg-12">
                              <label class="mb-0">Profil alap adatok</label>
                            </div>
                          </div>
                        </mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <mat-list>
                          <mat-list-item>
                            <div class="row w-100 align-items-center mb-2">
                              <div class="col-lg-3">
                                <label class="control-header-label">Felhasználó név</label>
                              </div>
                              <div class="col-lg-9">
                                <label class="control-data-label">
                                  <span style="margin-right: 5px;">Az Ön felhasználó neve</span>
                                  <span style="font-weight:500;">{{aktualisFelhasznalo.f_felhasznalo_nev}}</span>
                                </label>
                              </div>
                            </div>
                            <mat-divider></mat-divider>
                          </mat-list-item>
                          <mat-list-item>
                            <div class="row w-100 align-items-center">
                              <div class="col-lg-3">
                                <label class="control-header-label">E-mail cím</label>
                              </div>
                              <div class="col-lg-8">
                                <label class="control-data-label">
                                  <span style="margin-right: 5px;">Az Ön jelenlegi e-mail címe</span>
                                  <span style="font-weight:500;">{{aktualisFelhasznalo.f_email}}</span>
                                </label>
                              </div>
                              <div class="col-lg-1">
                                <button mat-icon-button class="edit-button" matTooltip="E-mail cím módosítása" matTooltipPosition="above" (click)="onValtozasBejelentesClick('email_modositas')">
                                  <mat-icon>edit</mat-icon>
                                </button>
                              </div>
                            </div>
                          </mat-list-item>
                        </mat-list>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <!-- Profil törlése -->
                  <div class="col-12">
                    <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                      <mat-card-header>
                        <mat-card-title class="content-title">
                          <div class="row align-items-center">
                            <div class="col-lg-12">
                              <label class="mb-0">Regisztráció törlése</label>
                            </div>
                          </div>
                        </mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <div class="row mb-3">
                          <div class="col-12">
                            <div class="mb-3 information-wrapper">
                              Regisztrációjának megszűntetésével a profilja törlésre kerül, így a továbbiakban csak újbóli regisztrációval tudja igénybe venni az ügyfél portálunk biztosította lehetőségeket.
                            </div>
                          </div>
                          <div class="col-12">
                            <button mat-raised-button color="accent" (click)="onRegisztracioTorleseClick()">Regisztráció törlése</button>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </ng-container>

                <!-- Ügyfélazonosítók kezelése -->
                <ng-container *ngIf="kivalasztottMenu.f_id == 'ugyfelazonositok_kezelese'">
                  <!-- Profilhoz rendelt ügyfélazonosítók -->
                  <div class="col-12">
                    <mat-card class="form-content-2 mb-3 pt-2 pb-0">
                      <mat-card-header>
                        <mat-card-title class="content-title">
                          <div class="row align-items-center">
                            <div class="col-lg-12">
                              <label class="mb-0">Profilhoz kapcsolt ügyfélazonosítók</label>
                            </div>
                          </div>
                        </mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <div class="row mb-3">
                          <div class="col-12">
                            <mat-chip-list>
                              <mat-chip *ngFor="let item of ugyfelAzonositok" [removable]="ugyfelAzonositok.length > 1" (removed)="onUgyfelazonositoEltavolitasClick(item)">
                                {{item.f_ugyfelazonosito}}
                                <mat-icon *ngIf="ugyfelAzonositok.length > 1" matChipRemove matTooltipPosition="above">cancel</mat-icon>
                              </mat-chip>
                            </mat-chip-list>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <!-- Új ügyfélazonosító hozzáadása -->
                  <div class="col-12">
                    <mat-card class="form-content-2">
                      <mat-card-header>
                        <mat-card-title class="content-title">Új ügyfélazonosító hozzáadása</mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <div class="row justify-content-end flex-row-reverse">
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="information-wrapper mb-4">
                              Regisztrációjához több ügyfélazonosítót is hozzáadhat és törölheti is őket.
                              Probléma esetén keresse <a routerLink="/kapcsolattartas">ügyfélszolgálatunkat</a>.
                            </div>
                          </div>
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <form [formGroup]="szerzodesAdatokFormGroup" class="form-group mt-0">
                              <mat-form-field appearance="fill">
                                <mat-label>Azonosító típus</mat-label>
                                <mat-select formControlName="f_azonosito_tipus">
                                  <mat-option value="Ü">Ügyfélazonosító</mat-option>
                                  <mat-option value="SZ">Szerződés azonosító</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field appearance="fill">
                                <mat-label>{{szerzodesAdatokFormGroup.controls['f_azonosito_tipus'].value == 'Ü' ? 'Ügyfélazonosító' : 'Szerződés azonosító'}}</mat-label>
                                <input matInput formControlName="f_azonosito" required>
                                <mat-error *ngIf="hibaService.hiba(szerzodesAdatokFormGroup, 'f_azonosito', 'required')">
                                  <mat-icon>error</mat-icon>
                                  Kérjük, adja meg {{szerzodesAdatokFormGroup.controls['f_azonosito_tipus'].value == 'Ü' ? 'az ügyfélazonosítót' : 'a szerződés azonosítót'}}!
                                </mat-error>
                              </mat-form-field>
                              <mat-form-field appearance="fill">
                                <mat-label>Szerződő neme</mat-label>
                                <mat-select formControlName="f_szerzodo_neme" (selectionChange)="onSzerzodoNemeChange($event)" required>
                                  <mat-option value="N">Nő</mat-option>
                                  <mat-option value="F">Férfi</mat-option>
                                  <mat-option value="J">Jogi</mat-option>
                                </mat-select>
                                <mat-error *ngIf="hibaService.hiba(szerzodesAdatokFormGroup, 'f_szerzodo_neme', 'required')">
                                  <mat-icon>error</mat-icon>
                                  Kérjük, válassza ki a szerződő nemét!
                                </mat-error>
                              </mat-form-field>
                              <mat-form-field *ngIf="szerzodesAdatokFormGroup.controls['f_szerzodo_neme'].value != 'J'" appearance="fill">
                                <mat-label>Szerződő születési dátuma</mat-label>
                                <input matInput formControlName="f_szerzodo_szuletesi_datuma" [matDatepicker]="szerzodoSzuletesiDatumaPicker" required>
                                <mat-datepicker-toggle matSuffix [for]="szerzodoSzuletesiDatumaPicker"></mat-datepicker-toggle>
                                <mat-datepicker #szerzodoSzuletesiDatumaPicker></mat-datepicker>
                                <mat-error *ngIf="hibaService.hiba(szerzodesAdatokFormGroup, 'f_szerzodo_szuletesi_datuma', 'required')">
                                  <mat-icon>error</mat-icon>
                                  Kérjük, adja meg a szerződő születési dátumát!
                                </mat-error>
                              </mat-form-field>
                              <mat-form-field *ngIf="szerzodesAdatokFormGroup.controls['f_szerzodo_neme'].value == 'J'" appearance="fill">
                                <mat-label>Szerződő adószáma</mat-label>
                                <input matInput formControlName="f_szerzodo_adoszama" required>
                                <mat-error *ngIf="hibaService.hiba(szerzodesAdatokFormGroup, 'f_szerzodo_neme', 'required')">
                                  <mat-icon>error</mat-icon>
                                  Kérjük, adja meg a szerződő adószámát!
                                </mat-error>
                              </mat-form-field>
                              <div class="row">
                                <div class="col-lg-12">
                                  <button mat-raised-button [disabled]="!szerzodesAdatokFormGroup.valid" color="accent" (click)="onUgyfelazonositoHozzaadasClick()">Igény elküldése</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </ng-container>

                <!-- Jelszó módosítás -->
                <ng-container *ngIf="kivalasztottMenu.f_id == 'jelszo_modositas'">
                  <div class="col-12">
                    <mat-card class="form-content-2">
                      <mat-card-header>
                        <mat-card-title class="content-title">Jelszó módosítása</mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <div class="row justify-content-end flex-row-reverse">
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="information-wrapper mb-4">
                              Itt változtathatja meg a belépéshez szükséges jelszavát. Töltse ki a mezőket, majd kattintson a 'Módosítom a jelszavamat' gombra.<br /><br />
                              A jelszónak minimum 12 karakterből kell állnia és tartalmaznia kell legalább egy nagybetűt, egy kis betűt és egy számot.<br />
                              Kérjük kerülje az ékezetes karakterek használatát.<br /><br />
                              A következő belépéskor már az új jelszavát adja meg.
                            </div>
                          </div>
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <form [formGroup]="jelszoModositasFormGroup" class="form-group mt-0">
                              <mat-form-field appearance="fill">
                                <mat-label>Jelenlegi jelszó</mat-label>
                                <input matInput formControlName="f_jelszo_jelenlegi" [type]="jelszoJelenlegiElrejtese ? 'password' : 'text'" required autocomplete="new-password">
                                <button type="button" mat-icon-button matSuffix (click)="jelszoJelenlegiElrejtese = !jelszoJelenlegiElrejtese">
                                  <mat-icon>{{jelszoJelenlegiElrejtese ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-error *ngIf="hibaService.hiba(jelszoModositasFormGroup, 'f_jelszo_jelenlegi', 'required')">
                                  <mat-icon>error</mat-icon>
                                  Kérjük, adja meg a régi jelszavát!
                                </mat-error>
                              </mat-form-field>
                              <mat-form-field appearance="fill">
                                <mat-label>Új jelszó</mat-label>
                                <input matInput formControlName="f_jelszo_uj" [type]="jelszoUjElrejtese ? 'password' : 'text'" required autocomplete="new-password">
                                <button type="button" mat-icon-button matSuffix (click)="jelszoUjElrejtese = !jelszoUjElrejtese">
                                  <mat-icon>{{jelszoUjElrejtese ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-error *ngIf="hibaService.hiba(jelszoModositasFormGroup, 'f_jelszo_uj', 'required')">
                                  <mat-icon>error</mat-icon>
                                  Kérjük, adja meg az új jelszavát!
                                </mat-error>
                                <mat-error *ngIf="hibaService.hiba(jelszoModositasFormGroup, 'f_jelszo_uj', 'hibasJelszo')">
                                  <mat-icon>error</mat-icon>
                                  A jelszónak minimum 12 karakterből kell állnia és tartalmaznia kell legalább egy nagybetűt, egy kis betűt és egy számot. Kérjük kerülje az ékezetes karakterek használatát.
                                </mat-error>
                              </mat-form-field>
                              <mat-form-field appearance="fill">
                                <mat-label>Új jelszó ismét</mat-label>
                                <input matInput formControlName="f_jelszo_uj_ismet" [type]="jelszoUjIsmetElrejtese ? 'password' : 'text'" required autocomplete="new-password">
                                <button type="button" mat-icon-button matSuffix (click)="jelszoUjIsmetElrejtese = !jelszoUjIsmetElrejtese">
                                  <mat-icon>{{jelszoUjIsmetElrejtese ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-error *ngIf="hibaService.hiba(jelszoModositasFormGroup, 'f_jelszo_uj_ismet', 'required')">
                                  <mat-icon>error</mat-icon>
                                  Kérjük, adja meg az új jelszavát ismét!
                                </mat-error>
                                <mat-error *ngIf="hibaService.hiba(jelszoModositasFormGroup, 'f_jelszo_uj_ismet', 'jelszoElteres')">
                                  <mat-icon>error</mat-icon>
                                  Kérjük, helyesen ismételje meg jelszavát!
                                </mat-error>
                              </mat-form-field>
                              <div class="row">
                                <div class="col-lg-12">
                                  <button mat-raised-button type="button" [disabled]="!jelszoModositasFormGroup.valid" color="accent" (click)="onJelszoModositasClick()">Módosítom a jelszavamat</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </ng-container>

                <ng-container *ngIf="valtozasBejelentesTipus">
                  <div class="col-lg-12">
                    <mat-card class="form-content">
                      <mat-card-header *ngIf="valtozasBejelentesTipus.f_nev">
                        <mat-card-title class="content-title">{{valtozasBejelentesTipus.f_nev}}</mat-card-title>
                      </mat-card-header>
                      <mat-card-content>

                        <!-- E-mail cím -->
                        <ng-container *ngIf="valtozasBejelentesTipus.f_id == 'email_modositas'">

                          <div class="row justify-content-end flex-row-reverse">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                              <div class="information-wrapper">
                                Az itt megváltoztatott e-mail címére fogjuk az elektronikus kommunikációs üzeneteinket, megerősítésekhez szükséges linkeket küldeni, feltéve, ha az új e-mail címére küldött aktiváló linkre kattintással megerősíti.
                              </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                              <form [formGroup]="emailModositasFormGroup" class="form-group mt-0">
                                <div class="row">
                                  <div class="col-lg-12">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Új e-mail cím</mat-label>
                                      <input matInput formControlName="f_email_uj" autocomplete="off">
                                    </mat-form-field>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-12">
                                    <button mat-button type="button" class="mr-4" (click)="onValtozasBejelentesMegseClick()">Mégsem</button>
                                    <button mat-raised-button type="button" color="accent" [disabled]="!emailModositasFormGroup.valid" (click)="onEmailModositasClick()">Módosítás</button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </ng-container>

                      </mat-card-content>
                    </mat-card>
                  </div>
                </ng-container>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </div>
    </div>
  </mat-card-content>
</mat-card>
