import { Component, OnInit, isDevMode } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService, HibaService } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import { KapcsolattartasService } from '../../../services/kapcsolattartas.service';
import { TooltipOptions } from 'ng2-tooltip-directive';
import { SzerzodesService } from '../../../services/_index';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'kapcsolattartas',
  templateUrl: './kapcsolattartas.component.html',
  styleUrls: ['./kapcsolattartas.component.scss']
})
export class KapcsolatTartasComponent implements OnInit {


  uzenetKuldesFormGroup: FormGroup;
  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;
  toltoKepernyo: boolean = false;
  kivalasztottMenu: string = "";
  szerzodesekLista: any[];

  /* tooltip beállítások*/
  myOptions = {
    'placement': 'top',
    'max-width': 600,
    'tooltip-class': 'my-tooltip'
  }

  teszt: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private kapcsolattartasService: KapcsolattartasService,    
    public hibaService: HibaService,
    private felhasznaloService: FelhasznaloService,
    private szerzodesService: SzerzodesService,
    private utilityService: UtilityService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit(): void {
    this.environmentName();
    this.uzenetKuldesFormGroupInit();
    this.onMenuClick("Üzenet küldés");
  }


  environmentName() {
    this.utilityService.environmentName().subscribe(
      data => {
        this.teszt = data != "Production";
      }
    );
  }

  uzenetKuldesFormGroupInit() {
    this.uzenetKuldesFormGroup = this.formBuilder.group({
      kivalasztottSzerzodes: new FormControl("0"),
      uzenet: new FormControl(""),
    }, {
      validators: [AdatValidator.uzenetHiany()]
    })
  }

  /*Menüpontra kattintás esemény*/
  onMenuClick(menu: string) {

    if (menu == "Időpont foglalás ügyfélszolgálatra") {

      let link = "";
      if (this.teszt) {
        link = "https://webapps.granitbiztosito.hu:12443/idopont-uszi";
        //link = "https://localhost:44349/idopont-uszi";
      }
      else {
        link = "https://webapps.granitbiztosito.hu/idopont-uszi";
      }

      this.utilityService.getSignedUrl(link + '?h=1&uid=' + this.aktualisFelhasznalo.f_felhasznalo_id).subscribe(
        adat => {
          window.open(adat, '_blank');
        },
        errorResult => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a menüpont esemény közben",
            confirmButtonText: 'Rendben'
          });
        });
      //window.open(link, "_blank");
    }

    if (menu == "Üzenet küldés") {

      this.uzenetKuldesFormGroupInit();
      this.toltoKepernyo = true;
      this.kivalasztottMenu = menu;
      this.szerzodesekLista = [];

      /*Felhasználóhoz tartozó szerződések lekérdezése*/
      this.szerzodesService.szerzodesAlapadatok().subscribe(
        data => {
          this.szerzodesekLista = data;
          this.toltoKepernyo = false;
        },
        errorResult => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a lekérdezés közben",
            confirmButtonText: 'Rendben'
          });
        }
      );
    }
  }

  onUzenetKuldes() {

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('ugyfelportal_kapcsolattartas_onUzenetKuldes').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          /*üzenet küldése emailben*/
          let model = {
            uzenetKuldes: this.uzenetKuldesFormGroup.value
          };

          this.kapcsolattartasService.uzenetKuldes(model).subscribe(
            () => {
              this.utilityService.dialogMegnyitasa({
                success: true,
                title: 'Siker',
                message: "Sikeres üzenet küldés",
                confirmButtonText: 'Rendben'
              });
              this.uzenetKuldesFormGroupInit();
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba üzenet küldés közben",
                confirmButtonText: 'Rendben'
              });
            }
          );
        })
      });
  }

}

export class AdatValidator extends Validators {

  static uzenetHiany() {
    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const ctr_szerzodes = formGroup.controls["kivalasztottSzerzodes"];
      const ctr_uzenet = formGroup.controls["uzenet"];

      if (ctr_szerzodes.value == "0") {
        hasError = true;
      }
      else {
        if (ctr_uzenet.value == null || ctr_uzenet.value == "") {
          hasError = true;
        }
      }
      if (hasError) {
        ctr_szerzodes.setErrors({ uzenetHiany: true });
      }
      else {
        ctr_szerzodes.setErrors(null);
      }
    }
    return validator;
  }

}
