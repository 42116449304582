<mat-tab-group dynamicHeight [(selectedIndex)]="dokumentumokTabIndex">
  <mat-tab label="Dokumentum igénylés">
    <form [formGroup]="dokumentumIgenylesFormGroup" class="form">
      <div class="col-lg-12 mt-5 mb-3 pr-5 info">
        <span>
          Az alábbi, egyszerűsített űrlapon lehetősége van szerződéseivel
          kapcsolatosan különböző bizonylatok, dokumentumok igénylésére.
        </span>
      </div>
      <div class="col-lg-12 mb-3 pr-5 info">
        <span>
          Az „Igény küldése” gomb megnyomása után a kiválasztott bizonylat automatikusan elkészül
          és a következő munkanapon postázásra kerül a biztosítási kötvényen szereplő címre.
          Ha Ön nem zöldkártyát választott, és a szerződésén zöld kedvezményt vett igénybe,
          akkor az igényelt dokumentum elektronikus úton kerül postázásra, az Ön által megadott
          kommunikációs e-mail címre.
        </span>
      </div>
      <div class="col-lg-12 mb-3 pr-5 info">
        <span>
          A honlapon igényelhető dokumentumok mennyisége szerződésenként és típusonként korlátozott
          az alábbi táblázat szerint:
        </span>
      </div>
      <div class="col-lg-12 mb-5 ml-1 pr-5 info">
        <table width="540px" cellpadding="0" cellspacing="0" style="margin-left: 60px;">
          <tr>
            <td class="font-bold">
              Dokumentum típusa
            </td>
            <td align="right" class="font-bold">
              Igényelhető mennyiség
            </td>
          </tr>
          <tr>
            <td colspan="2">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td>
              Csekk, díjbekérő levél
            </td>
            <td align="right">
              30 naponta 1db
            </td>
          </tr>
          <tr>
            <td>
              Díjigazolás (csak KGFB szerződés esetén)
            </td>
            <td align="right">
              7 naponta 1db
            </td>
          </tr>
          <tr>
            <td>
              Kötvény
            </td>
            <td align="right">
              30 naponta 1db
            </td>
          </tr>
          <tr>
            <td>
              Zöldkártya (csak KGFB szerződés esetén)
            </td>
            <td align="right">
              7 naponta 1db
            </td>
          </tr>
          <tr>
            <td>
              Kártörténeti igazolás (csak törölt KGFB szerződés esetén)
            </td>
            <td align="right">
              7 naponta 1db
            </td>
          </tr>
        </table>
      </div>
      <div class="col-lg-12 mb-3 ml-1 pr-5 info">
        <span>
          Amennyiben dokumentum igénylésével problémája akadna, kérjük jelezze felénk az <a class="font-bold" style="cursor: pointer" routerLink="/kapcsolattartas">Üzenet küldés</a> oldalon vagy hívja telefonos ügyfélszolgálatunkat a nyitva tartásnak megfelelő időpontban, vagy jelezze társaságunk felé e-mailben.
        </span>
      </div>
      <div class="col-lg-12 mb-3">
        <div class="row align-items-end">
          <div class="col-lg-4">
            <div class="control-group">
              <label class="control-header-label">Szerződések</label>
              <mat-select class="form-control" (selectionChange)="onSzerzodesValasztas()" formControlName="kivalasztottSzerzodes">
                <mat-option value="0">Kérem válasszon..</mat-option>
                <mat-option *ngFor="let szerzodeSzam of szerzodesekLista" [value]="szerzodeSzam.f_szerz_azon">{{szerzodeSzam.f_szerz_azon}}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="control-group" *ngIf="dokumentumIgenylesFormGroup.get('kivalasztottSzerzodes').value != '0'">
              <label class="control-header-label">Díjrendezettségi dátuma</label>
              <label class="control-data-label">{{dijrend | dateFormat: 'YYYY. MM. DD.'}}</label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="dokumentumIgenylesFormGroup.get('kivalasztottSzerzodes').value != '0'">
        <div class="col-lg-12 ml-1">
          <div class="form-group">
            <label class="control-label">Igényelhető dokumentumok</label>
          </div>
        </div>
        <!-- Zöldkártya chechbox -->
        <div class="col-lg-12 ml-1">
          <button mat-icon-button [options]="myOptions"
                  tooltip="<h2>Zöldkártya tájékoztató</h2>
                                           <p>Ez a dokumentum ezen a csatornán csak abban az esetben igényelhető, ha a szerződés díjrendezettsége a mai nap + 10 napot meghaladja. Kötelező gépjármű-felelősségbiztosítási szerződésének érvényességét Magyarországon az „Igazolás”, külföldön a NEMZETKÖZI GÉPJÁRMŰBIZTOSÍTÁSI KÁRTYA („Zöldkártya”) tanúsítja.</p>
                                           <p>Amennyiben az Európai Gazdasági Térség tagállamaiba utazik, úgy elegendő, ha rendelkezik új uniós rendszámtáblával, amennyiben nem, ragassza fel járműve hátsó részére a „H” országjelet.</p>
                                           <p><strong>Kötelező kiváltani:</strong> Albániába, Belorussziába, Iránba, Izraelbe, Marokkóba, Moldáviába, Törökországba, Tunéziába, Ukrajnába történő utazáskor.</p>
                                           <p><strong>Ajánlott kiváltani:</strong> Bosznia-Hercegovinába, Bulgáriába, Görögországba, Macedóniába, Máltára, Nagy-Britannia és Észak-Írországba, Olaszországba, Romániába, Szerbia és Montenegróba történő utazáskor.</p>">
                            <mat-icon>info</mat-icon>
                          </button>
          <mat-checkbox [disabled]="!zoldKartya" formControlName="zoldkartya">Zöldkártya</mat-checkbox>
        </div>
        <!-- Díjigazolás chechbox -->
        <div class="col-lg-12 ml-1">
          <button mat-icon-button [options]="myOptions"
                  tooltip="<h2>Díjigazolás tájékoztató</h2><p>E dokumentum segítségével igazolható harmadik fél számára a biztosító kockázatban állása, a szerződés díjrendezettsége. Csak abban az esetben igényelhető, ha a szerződés díjrendezettsége nagyobb a mai napnál.</p>">
            <mat-icon>info</mat-icon>
          </button>
          <mat-checkbox [disabled]="!dijIgazolas" formControlName="dijigazolas">Díjigazolás</mat-checkbox>
        </div>
        <!-- Kötvény chechbox -->
        <div class="col-lg-12 ml-1">
          <button mat-icon-button [options]="myOptions"
                  tooltip="<h2>Kötvény tájékoztató</h2><p>A biztosítási szerződés létrejöttét igazoló okmány.</p>">
            <mat-icon>info</mat-icon>
          </button>
          <mat-checkbox [disabled]="!kotveny" formControlName="kotveny">Kötvény</mat-checkbox>
        </div>
        <!-- Díjbekérő chechbox -->
        <div class="col-lg-12 ml-1">
          <button mat-icon-button [options]="myOptions"
                  tooltip="<h2>Díjbekérő tájékoztató</h2><p>A biztosítási szerződés fizetési ütemének megfelelő díjrészlet összegét, a díj időszakát és a befizetés esedékességét tartalmazó dokumentum.</p>">
            <mat-icon>info</mat-icon>
          </button>
          <mat-checkbox [disabled]="!dijbekero" formControlName="dijbekero">Csekk, Díjbekérő</mat-checkbox>
        </div>
        <!-- Kártörténeti chechbox -->
        <div class="col-lg-12 ml-1 mb-3">
          <button mat-icon-button [options]="myOptions">
            <mat-icon>info</mat-icon>
          </button>
          <mat-checkbox [disabled]="!karTori" formControlName="kartori">Kártörténeti igazolás</mat-checkbox>
        </div>
        <div class="col-lg-12 ml-1 mb-3">
          <button mat-raised-button color="accent" [disabled]="dokumentumIgenylesFormGroup.status == 'INVALID'" (click)="onDokumentumIgenyles()">
            Igény küldése
          </button>
        </div>
        <div *ngIf="eredmenyLista && eredmenyLista.length > 0">
          <div class="col-lg-12">
            <mat-card>
              <mat-card-content>
                <mat-list>
                  <label class="control-label">Eredmény: </label>
                  <mat-list-item *ngFor="let eredmeny of eredmenyLista" class="eredmenyList">
                    {{eredmeny}}
                  </mat-list-item>
                </mat-list>

              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </form>
  </mat-tab>
  <mat-tab label="Kiküldött dokumentumok">
    <div class="row">
      <div class="col-lg-12">

        <mat-card class="mat-elevation-z3">
          <mat-card-content>
            <div class="row">
              <div class="col-lg-12">
                <form [formGroup]="kikuldottDokumentumokSzuroForm" class="form">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <button mat-icon-button *ngIf="szuresBe" (click)="onSzuresFormValt()">
                          <mat-icon>close</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="!szuresBe" (click)="onSzuresFormValt()">
                          <mat-icon>tune</mat-icon>
                        </button>
                        <button class="control-group-label ml-2" mat-button (click)="onSzuresFormValt()">Szűrési feltételek</button>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="szuresBe">
                    <div class="row">
                      <div class="col-lg-3">
                        <mat-form-field appearance="fill">
                          <mat-label>Dokumentum típus</mat-label>
                          <mat-select formControlName="keresesTipus">
                            <mat-option value="0">Mind</mat-option>
                            <mat-option *ngFor="let dokumentumTipus of dokumentumTipusLista" [value]="dokumentumTipus">{{dokumentumTipus}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-3">
                        <mat-form-field appearance="fill">
                          <mat-label>Szerződés</mat-label>
                          <mat-select formControlName="keresesSzerzodesszam">
                            <mat-option value="0">Mind</mat-option>
                            <mat-option *ngFor="let szerzodes of szerzodesLista" [value]="szerzodes">{{szerzodes}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-3">
                        <mat-form-field appearance="fill">
                          <mat-label>Flotta</mat-label>
                          <mat-select formControlName="keresesFlottaAzonosito">
                            <mat-option value="0">Mind</mat-option>
                            <mat-option *ngFor="let flotta of flottaLista" [value]="flotta">{{flotta}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <!--
                      <div class="col-lg-3">
                        <div class="form-group">
                          <mat-label class="control-label">Ügyfél</mat-label>
                          <mat-select class="form-control" formControlName="keresesUgyfelAzonosito">
                            <mat-option value="0">Mind</mat-option>
                            <mat-option *ngFor="let ugyfel of ugyfelLista" [value]="ugyfel">{{ugyfel}}</mat-option>
                          </mat-select>
                        </div>
                      </div>
                        -->
                    </div>

                    <div class="row">
                      <div class="col-lg-3">
                        <mat-form-field appearance="fill">
                          <mat-label>Küldés dátumtól</mat-label>
                          <input matInput formControlName="keresesKezdoDatum" [matDatepicker]="kezdoDatePicker" class="form-control" (dateChange)="onDatumValtozas(kikuldottDokumentumokSzuroForm)">
                          <mat-datepicker-toggle matSuffix [for]="kezdoDatePicker"></mat-datepicker-toggle>
                          <mat-datepicker #kezdoDatePicker></mat-datepicker>
                        </mat-form-field>
                      </div>

                      <div class="col-lg-3">
                        <mat-form-field appearance="fill">
                          <mat-label class="control-label">Küldés dátumig</mat-label>
                          <input matInput formControlName="keresesVegDatum" [matDatepicker]="vegDatePicker" class="form-control" [min]="kikuldottDokumentumokSzuroForm.get('keresesKezdoDatum').value">
                          <mat-datepicker-toggle matSuffix [for]="vegDatePicker"></mat-datepicker-toggle>
                          <mat-datepicker #vegDatePicker></mat-datepicker>
                        </mat-form-field>
                      </div>

                    </div>

                    <div class="row">
                      <div class="col-lg-1">
                        <button mat-raised-button color="accent" (click)="onSzuresClick()">Szűrés</button>
                      </div>
                      <div class="col-lg-2">
                        <button mat-button (click)="onSzuresAlap()">
                          <span>Szűrés alaphelyzetbe</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="row mt-2 mb-5">
          <div class="col-lg-12">
            <form [formGroup]="kikuldottDokumentumokFormGroup" class="form">
              <granit-table #kikuldottDokumentumokTabla
                             [columns]="kikuldottDokumentumokOszlopok"
                             [formGroup]="kikuldottDokumentumokFormGroup"
                             [isEditable]="true"
                             [matSortActive]="'f_datum'"
                             [matSortDirection]="'desc'"
                             [addNewMode]="''"
                             [operationButtonText]="'Letöltés'"
                             [operationButtonIcon]="'file_download'"
                             (rowOperationClick)="onRowOperationClick($event)">
              </granit-table>
            </form>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

