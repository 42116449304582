import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Modozat } from '../models/modozat.model';

@Injectable({
  providedIn: 'root'
})
export class ModozatService {
  constructor(private httpClient: HttpClient) { }
  private url: string = '/api/modozat';

  // Módozatok lekérése
  modozatokLekerese(): Modozat[] {
    let modozatok: Modozat[] = [
      { f_id: 'KGFB', f_nev: 'KGFB', f_ikon: 'directions_car', f_szerzodesek_szama: 0 },
      { f_id: 'OTTHON', f_nev: 'Otthon', f_ikon: 'house', f_szerzodesek_szama: 0 },
      { f_id: 'CASCO', f_nev: 'CASCO', f_ikon: 'directions_car', f_szerzodesek_szama: 0 },
      { f_id: 'MFO', f_nev: 'MFO', f_ikon: 'house', f_szerzodesek_szama: 0 },
      { f_id: 'UTAS', f_nev: 'Utas', f_ikon: 'flight_takeoff', f_szerzodesek_szama: 0 },
      { f_id: 'EGYÉB', f_nev: 'Egyéb', f_ikon: 'more_horiz', f_szerzodesek_szama: 0 }
    ]
    return modozatok;
  }
}
