import { Component, OnInit } from '@angular/core';
import { UtilityService } from '@granit/common-library';
import { Modozat } from '../../models/modozat.model';
import { ModozatService } from '../../services/modozat.service';

@Component({
  selector: 'bejelentkezes',
  templateUrl: './bejelentkezes.component.html',
  styleUrls: ['./bejelentkezes.component.scss']
})
export class BejelentkezesComponent implements OnInit {

  constructor(
    private modozatService: ModozatService,
    private utilityService: UtilityService  ) { }

  modozatokLista: Modozat[] = this.modozatService.modozatokLekerese();

  kGFBLink: string = "";
  cascoLink: string = "";
  otthonLink: string = "";
  mFOLink: string = "";
  utasLink: string = "";
  egyebLink: string = "";

  ngOnInit(): void {
    this.linkek();
  }

  async linkek() {

    Promise.all(
      [this.utilityService.appsettingsKeyErtekLekerese({ key: 'KGFBLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'CascoLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'OtthonLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'MFOLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'UtasLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'EgyebLink' }),
      ]).then(
        data => {
          this.kGFBLink = data[0];
          this.cascoLink = data[1];
          this.otthonLink = data[2];
          this.mFOLink = data[3];
          this.egyebLink = data[5];

          this.utilityService.getSignedUrl(data[4] + "?alkusz=0980").subscribe(
            data => {
              this.utasLink = data;
            }

          )
        })
  }

  // Tarifálás és szerződéskötés gomb esemény
  onTarifalasEsKotesClick(modozat: Modozat) {
    let link = '';
    switch (modozat.f_id) {
      case 'KGFB':
        link = this.kGFBLink;
        break;
      case 'OTTHON':
        link = this.otthonLink;
        break;
      case 'CASCO':
        link = this.cascoLink;
        break;
      case 'MFO':
        link = this.mFOLink;
        break;
      case 'UTAS':
        link = this.utasLink;
        break;
      case 'EGYÉB':
        link = this.egyebLink;
        break;
      default:
    }
    window.open(link);
  }

}
