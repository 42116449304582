import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HibaService, UtilityService } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import { DokumentumService } from '../../../services/_index';
import { saveAs } from 'file-saver';

@Component({
  selector: 'dokumentumok',
  templateUrl: './dokumentumok.component.html',
  styleUrls: ['./dokumentumok.component.scss']
})
export class DokumentumokComponent implements OnInit {

  constructor(
    private felhasznaloService: FelhasznaloService,
    private dokumentumService: DokumentumService,
    private hibaService: HibaService,    
    private router: Router,
    private utilityService: UtilityService  ) { }

  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;
  dokumentumokLista: any[] = [];
  toltoKepernyo: boolean = true;

  ngOnInit(): void {
    this.dokumentumService.dokumentumAlapadatok().subscribe(
      data => {
        this.dokumentumokLista = data.levelek;
        this.toltoKepernyo = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a lekérdezés közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  // Dokumentumigénylés letöltés gomb esemény
  onLetoltesClick(lev_azon: any) {
    this.dokumentumService.levelLetoltes({ levelAzonosito: lev_azon.toString() }).subscribe(
      data => {
        const filename = data.headers.get('Content-Disposition').split(/[;'=]/).pop();
        saveAs(data.body, decodeURIComponent(filename));
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a dokumentum letöltése közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  // Dokumentumigénylés gomb esemény
  onDokumentumIgenylesClick() {
    localStorage.setItem('azonosito', '0');
    localStorage.setItem('menu', 'igény');
    this.router.navigateByUrl('/dokumentumok-adatok');
  }

  // Dokumentumigénylés gomb esemény
  onKikuldottDokumentumokClick() {
    localStorage.setItem('azonosito', '0');
    localStorage.setItem('menu', 'kiküldött');
    this.router.navigateByUrl('/dokumentumok-adatok');
  }

}
